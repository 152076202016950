import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ConfirmOrder from "../components/ConfirmOrder";

const Confirm = (props: any) => {
  return (
    <div className="page-wrapper">
      <Header history={props.history} />
      <ConfirmOrder title={""} price={""} />
      <Footer />
    </div>
  );
};

export default Confirm;
