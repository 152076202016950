import Api from "../../ApiManager/index";
import * as geolib from "geolib";
import {
  LOGOUT_CUSTOMER,
  SIGN_UP,
  STORES_LIST,
  MENU_COMBO_LIST,
  MENU_GROUPS_LIST,
  MENU_ITEMS,
  ORDER_LIST,
  GET_CART,
  SAVE_CART,
  STORE_NAME,
  GET_TAX,
  STORE_ID,
  STORE_TYPE,
  ORDER_ID,
  COMBO_DETAIL,
  COMBO_DETAIL_ID,
  ITEM_DETAIL,
  ADDRESS_GUEST,
  ORDER_TYPE,
  SAVE_HOUSEDETAIL,
  SAVE_STREETDETAIL,
  SAVE_ADDRESSTYPE,
  SELECT_STORE_ID,
  ADD_GUEST,
  DELIVERY_FEE,
  SELECT_STORE,
  PAYMENT,
  CUSTOMER_ID,
  FRANCHISE_APPLICATION,
  LOGIN_SIGNUP,
  SIGNUP_k2g,
  TOP_LIST,
  HERO_ITEMS,
  LOG_Cart,
  FORGOT,
  ADDRESS_LIST,
  FAV_MENU_ITEM,
  OFFERS_LIST,
  COUPON_DATA,
  ORDER_PERIOD,
  CHECKOUT_TAB_NAME,
  RESTUARANTS,
  ORDER_DATA,
  GET_PROFILE,
  TOP_SELLING_ITEMS,
  HANDLE_PROFILE_INPUT,
  ORDER_DETAIL,
  SAVE_ADDRESS_USER,
  SAVE_STORE_DETAILS,
  PAYMENT_METHOD,
  SUCCESS_FLAG,
  ORDER,
  URL_MATCH_METHOD,
  UPDATE_LSM,
  LSM_ITEMS,
  GET_TAX_CASH,
  GET_TAX_CARD,
  SAVE_TAX,
  SAVE_DISCOUNT,
  NETWORK_STATUS,
  NEW_CUSTOMER_PHONE_URL,
  TOP_DEALS_HOME,
  SWITCH_TAB,
  VALIDATE_OTP,
  SPECIFIC_STORE_ITEMS,
  MENU_GROUPS_LIST_HOME,
  HANDLE_SPECIAL_INPUT,
  MENU_STORE_ID,
  GET_STORE,
  CITIES_WEB,
  TRADE_ZONES_WEB,
  TRADE_AREAS_WEB,
  SET_CITY_NAME,
  SET_TRADE_ZONE_NAME,
  SET_TRADE_AREA_NAME,
  ADD_NEW_ADDRESS_WITHOUT_MAP,
  CART_TOGGLE,
  PICKUP_STORES_LIST,
  FEATURED_PRODUCTS,
  FILTERED_ITEMS,
  STORE_TIME_CHECK,
  PICKUP_OPEN,
  ORDER_DATE_TIME,
  TABLE_NUMBER,
  SAVE_TAX_RATE,
  SAVE_AGREEMENT_CHECK,
  SAVE_COOKIEMODAL_CHECK,
} from "./types";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import { groupCSS } from "react-select/src/components/Group";
import Swal from "sweetalert2";
import moment from "moment";
toast.configure();

export const getProfile = () => {
  return async function (dispatch: any) {
    let token: any = await sessionStorage.getItem("token");
    let id: any = await sessionStorage.getItem("id");
    Api.get(`/customer/profile/${id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_PROFILE,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
        } else {
          alert(err.message);
        }
      });
  };
};

export const handleProfileInput = (event: {
  target: { name: any; value: any };
}) => {
  return {
    type: HANDLE_PROFILE_INPUT,
    input: event.target,
  };
};
export const handleSpecialInstruction = (event: {
  target: { name: any; value: any };
}) => {
  return {
    type: HANDLE_SPECIAL_INPUT,
    input: event.target,
  };
};

export const editProfile = (id: any, data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    Api.put(`/customer/edit_profile/${id}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          sessionStorage.setItem("token", response.data.token);
          toast.error(response.data.successResponse, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              error = "Phone number already exist";
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.warning(error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.warning(err.message, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };
};

export const changePassword = (newPass: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.getItem("token");
    let userData: any;
    userData = token ? jwtDecode(token) : null;
    let id: any =
      userData && userData.customer && userData.customer.customer_id;
    Api.put(
      `/customer/change_password`,
      {
        customer_id: id,
        login_password: newPass,
      },
      {
        headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
      }
    )
      .then((response) => {
        if (response.data) {
          toast.error("Password changed successfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch(logoutCustomer());
        }
      })
      .catch((err) => {
        if (err.response) {
        } else {
          alert(err.message);
        }
      });
  };
};
export const customerSignIn = (data: any, history?: any) => {
  return function (dispatch: any) {
    let obj = {};
    if (data.email) {
      var objEmail = {
        email: data.email,
        password: data.password,
      };
      obj = objEmail;
    } else if (data.phone_number) {
      var objPhone = {
        phone_number: data.phone_number,
        password: data.password,
      };
      obj = objPhone;
    }
    Api.post("/customer/auth", obj)
      .then(async (response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          await sessionStorage.setItem("token", token);

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });
          Toast.fire({
            icon: "success",
            title: "Login Successful",
          });
          // await sessionStorage.setItem(
          //   "phone",
          //   userData.phone_number
          // );
          // await sessionStorage.setItem("gender", userData.gender);
          // await sessionStorage.setItem(
          //   "customer_birthday",
          //   userData.customer_birthday
          // );
          // await sessionStorage.setItem("profiledata", JSON.stringify(userData));
          // history.goBack()
          setTimeout(() => {
            let cart: any = sessionStorage.getItem("cart");
            let Cart: any = cart;
            let dataCart = JSON.parse(Cart);

            const checkCart =
              dataCart?.length > 0 && window.location.pathname === "/checkout"
                ? "/payment"
                : `/`;
            window.location.href = checkCart;
          }, 1100);
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          Toast.fire({
            icon: "error",
            title: error,
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          Toast.fire({
            icon: "error",
            title: "Connection Lost",
          });
        }
      });
  };
};
export const customerSignInForSignup = (data: any, history?: any) => {
  return function (dispatch: any) {
    let obj = {};
    if (data.email) {
      var objEmail = {
        email: data.email,
        password: data.password,
      };
      obj = objEmail;
    } else if (data.phone_number) {
      var objPhone = {
        phone_number: data.phone_number,
        password: data.password,
      };
      obj = objPhone;
    }
    Api.post("/customer/auth", obj)
      .then(async (response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          await sessionStorage.setItem("token", token);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });
          Toast.fire({
            icon: "success",
            title: "Login Successful",
          });
          setTimeout(() => {
            let cart: any = sessionStorage.getItem("cart");
            let Cart: any = cart;
            let dataCart = JSON.parse(Cart);

            const checkCart = dataCart?.length > 0 ? "/payment" : `/`;
            window.location.href = checkCart;
          }, 1100);
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          Toast.fire({
            icon: "error",
            title: error,
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          Toast.fire({
            icon: "error",
            title: "Connection Lost",
          });
        }
      });
  };
};
// after login addres get of user

export const saveAddressList = (addresses: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ADDRESS_LIST,
      payload: addresses,
    });
  };
};
export const saveCookieModalCheck = (isOpen: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SAVE_COOKIEMODAL_CHECK,
      payload: isOpen,
    });
  };
};

export const addressesListk = () => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    if (token) {
      var decoded: any = jwt_decode(token);
      Api.get(`/customer/address/${decoded.customer.customer_id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: ADDRESS_LIST,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: ADDRESS_LIST,
              payload: [],
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

//Logout session out

export const logoutCustomer = () => {
  return async (dispatch: any) => {
    await sessionStorage.removeItem("token");
    await sessionStorage.removeItem("profile_pic");
    await sessionStorage.removeItem("profile_pic_social");
    await sessionStorage.removeItem("fileUri");
    await sessionStorage.removeItem("gender");
    await sessionStorage.removeItem("id");
    await sessionStorage.removeItem("profiledata");
    await sessionStorage.removeItem("email");
    await sessionStorage.removeItem("name");
    await sessionStorage.removeItem("customer_birthday");
    await sessionStorage.removeItem("phone");
    await sessionStorage.removeItem("Managertoken");
    await sessionStorage.removeItem("Managername");

    //   const _isSignedIn = async () => {
    //     const isSignedIn = await GoogleSignin.isSignedIn();
    //     if (isSignedIn) {
    //       GoogleSignin.revokeAccess();
    //       //revoke Access method is used before the Signout method because SignIn Required for revokeAccess Method
    //       GoogleSignin.signOut();
    //     }
    //   };
    //   _isSignedIn();
    //   LoginManager.logOut();
    //   Toast.show({
    //     text: 'You have been logged out successfully',
    //     duration: 5000,
    //   });
    dispatch({
      type: LOGOUT_CUSTOMER,
      isLoggedIn: false,
    });
    window.location.href = `/menu${window.location.search}`;
  };
};

//signUp

export const signupCustomer = (data: any) => {
  return function (dispatch: any) {
    try {
      Api.post(`/customer/signup`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            dispatch(setTabName("payment"));
            setTimeout(() => {
              dispatch({
                type: SIGN_UP,
                isOpen: true,
                SuccessStatus: "successregister",
                // loginCustomer(responseData.email_address, data.login_password),
              });
            }, 1000);
            dispatch(customerId(responseData.customer_id));
            sessionStorage.setItem("guestphone", data.phone_number);
            toast.success(
              "Your login credentials has been sent to your email address."
            );

            //
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              if (err.response.data.code === "ER_DUP_ENTRY") {
                dispatch({
                  type: SIGN_UP,
                  emailExit: true,
                });
                // alert("Email Already Exist");
                // error = "Email Already Exist";
                toast.error("Phone Number Already Exist");
              } else {
                toast.error(err.response.data.message);
              }
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            //
          } else {
            //
          }
        });
    } catch (e) {
      //
    }
  };
};

// k2g signup

export const signupk = (data: any) => {
  return function (dispatch: any) {
    try {
      Api.post(`/customer/signup`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            setTimeout(() => {
              dispatch({
                type: SIGNUP_k2g,
                isOpen: true,
                successStatusRegister: "successregister",
                login_password: data.login_password,
              });
            }, 1000);

            dispatch(customerId(responseData.customer_id));
            // setTimeout(() => {
            //
            // }, 1100);
            // dispatch(
            //   loginSignup(
            //     response.data.successResponse.email_address,
            //     data.login_password
            //   )
            // );
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              if (err.response.data.code === "ER_DUP_ENTRY") {
                dispatch({
                  type: SIGN_UP,
                  emailExit: true,
                });
                // alert("Email Already Exist");
                // error = "Email Already Exist";
              } else {
                error = err.response.data.message;
              }
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }

            if (err.response.status === 409) {
              toast.warning("Your phone number already exist");
            }
          } else {
          }
        });
    } catch (e) {
      //
    }
  };
};

// save after register
export const loginSignup = (email: any, password: any) => {
  let data = {
    email: email,
    password: password,
  };
  return function (dispatch: any) {
    dispatch(customerSignInForSignup(data));
  };
};
// save customer id
export const customerId = (customerId: any) => {
  return function (dispatch: any) {
    dispatch({
      type: CUSTOMER_ID,
      CustomerId: customerId,
    });
  };
};

// Save customer tax value
export const SaveTaxValue = (taxAmount: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_TAX,
      taxAmount: taxAmount,
    });
  };
};

export const SaveStoreCheck = (value: any) => {
  return function (dispatch: any) {
    dispatch({
      type: STORE_TIME_CHECK,
      payload: value,
    });
  };
};

// Save customer totalDiscount
export const SaveDiscountValue = (discountValue: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_DISCOUNT,
      discountValue: discountValue,
    });
  };
};
// Save Rating Recviews

export const saveReview = (orderId: any, data: any, navigation: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");

    Api.put(`/customer/save_review/${orderId}`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          navigation.goBack();
          // setImmediate(() => {
          //   Actions.refresh({ key: Math.random() }); //It is used to refesh the page after pop the screen
          // });
          toast.success({
            text: "Thanks for your feedback",
            buttonText: "OK",
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.info({
            text: error,
            buttonText: "OK",
            duration: 5000,
            type: "danger",
          });
        } else {
          toast.info({
            text: err.message,
            buttonText: "OK",
          });
        }
      });
  };
};

//guest add for k
export const addGuest = (data: any) => {
  let history = data.history;
  delete data.history;
  return function (dispatch: any) {
    Api.post(`/customer/addGuest`, data)
      .then(async (response) => {
        if (response.data.success) {
          if (response.data.accessToken) {
            await sessionStorage.setItem("token", response.data.accessToken);
            history.push("/payment");
          } else {
            dispatch(setTabName("payment"));
            dispatch({
              type: ADD_GUEST,
              payload: response.data.successResponse,
              SuccessStatus: "successguest",
            });

            await sessionStorage.setItem(
              "guestname",
              response.data.successResponse.login_name
            );
            await sessionStorage.setItem(
              "guestemail",
              response.data.successResponse.email_address
            );

            await sessionStorage.setItem("guestphone", data.phone_number);
            history.push("/payment");
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              if (err.response.data.message.indexOf("phone_number") > -1) {
                error = "Phone Number Already Exist";
              } else {
                error = "Email Already Exist";
              }
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
        }
      });
  };
};

export const addUserWithToken = (data: any) => {
  let history = data.history;
  delete data.history;
  return function (dispatch: any) {
    Api.post(`/customer/addGuest`, data)
      .then(async (response) => {
        if (response.data.success) {
          if (response.data.accessToken) {
            await sessionStorage.setItem("token", response.data.accessToken);
            toast.info("User created successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            window.location.href = "/";
          } else {
            // dispatch(setTabName("payment"));
            dispatch({
              type: ADD_GUEST,
              payload: response.data.successResponse,
              SuccessStatus: "successguest",
            });

            history.push("/");
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            if (err.response.data.code === "ER_DUP_ENTRY") {
              if (err.response.data.message.indexOf("phone_number") > -1) {
                error = "Phone Number Already Exist";
              } else {
                error = "Email Already Exist";
              }
            } else {
              error = err.response.data.message;
            }
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
        }
      });
  };
};
// pickup store

export const findPickupStores = (searchString: any) => {
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;
          let activeStores: any = [];
          Responedata.map((store: any) => {
            if (store.is_active == 1 && store.cloudKitchen == "Disabled") {
              activeStores.push(store);
            }
          });
          const filteredStores = activeStores.filter((store: any) => {
            return (
              store.address
                .toLowerCase()
                .includes(searchString.toLowerCase()) ||
              store.city.toLowerCase().includes(searchString.toLowerCase()) ||
              store.store_name
                .toLowerCase()
                .includes(searchString.toLowerCase())
            );
          });
          if (filteredStores.length == 0) {
            dispatch({
              type: PICKUP_STORES_LIST,
              payload: [],
            });
          } else {
            dispatch({
              type: PICKUP_STORES_LIST,
              payload: filteredStores,
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: PICKUP_STORES_LIST,
            payload: [],
          });
          // Actions.selectstore();
        } else {
          alert("Connection Lost!!");
        }
      });
  };
};

/// delivery store

//LocalStore with kml Coordinates Json
export const findLocalStores = (lat: any, lng: any) => {
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: STORES_LIST,
            payload: data,
            deliveryfee: 0,
            storeStatus: 0,
            pos_fee: 0,
          });
        } else {
          alert("Connection Lost!!");
        }
      });
  };
};

export const findStoreforLSM = (lat: any, lng: any) => {
  return async (dispatch: any) => {
    Api.get("menu/stores")
      .then((response) => {
        if (response.data.success) {
          let data: any = [];
          let Responedata = response.data.successResponse;

          for (let i = 0; i < Responedata.length; i++) {
            if (Responedata[i].trade_zone_id.zone_json) {
              if (
                geolib.isPointInPolygon(
                  { latitude: lat, longitude: lng },
                  JSON.parse(Responedata[i].trade_zone_id.zone_json)
                )
              ) {
                data.push(Responedata[i]);
              }
            }
          }

          if (data.length > 0) {
            if (data[0].is_active === 1) {
              dispatch(menuGroupsList(data[0].store_id));
              dispatch(menuGroupsListHome(data[0].store_id));
              dispatch(TopList(data[0].store_id));
              // dispatch(TopItemsList(data[0].store_id));
              dispatch(TopDealsHome(data[0].store_id));
              dispatch(featuredProducts(data[0].store_id));
              // dispatch({
              //   type: STORES_LIST,
              //   payload: data,
              //   selectStoreId: data.length > 0 && data[0].store_id,
              //   deliveryfee: 0,
              //   storeStatus: 0,
              // });
              dispatch({
                type: MENU_STORE_ID,
                payload: data.length > 0 && data[0].store_id,
              });
            } else {
              dispatch(menuGroupsList(null));
              dispatch(menuGroupsListHome(null));
              dispatch(TopList(null));
              // dispatch(TopItemsList(null));
              dispatch(TopDealsHome(null));
              dispatch(featuredProducts(null));
              dispatch({
                type: MENU_STORE_ID,
                payaload: null,
              });
            }
          } else {
            // toast.warn("You are out of coverage from trade zone!")
            // dispatch(GetLSMitembyStoreId(null))
            dispatch(menuGroupsList(null));
            dispatch(menuGroupsListHome(null));
            dispatch(TopList(null));
            // dispatch(TopItemsList(null));
            dispatch(TopDealsHome(null));
            dispatch(featuredProducts(null));
            dispatch({
              type: MENU_STORE_ID,
              payaload: null,
            });
            dispatch({
              type: LSM_ITEMS,
              payload: [],
              emptyLsmItems: true,
              groupName: "LSM",
            });
          }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: STORES_LIST,
            payload: data,
            deliveryfee: 0,
            storeStatus: 0,
          });
        } else {
          alert("Connection Lost!!");
        }
      });
  };
};

export const GetLSMitembyStoreId = (storeId: any) => {
  let obj = {
    mode: sessionStorage.getItem("orderType") == "Qr-Pickup" ? "qr" : "online",
  };
  return async (dispatch: any) => {
    Api.post(`menu/getItemsForLocalStore/${storeId}`, obj)
      .then((response) => {
        if (response.data.success) {
          let Responedata = response.data.menuData;
          dispatch({
            type: LSM_ITEMS,
            payload: Responedata,
            emptyLsmItems: true,
            groupName: "LSM",
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: LSM_ITEMS,
            payload: [],
            emptyLsmItems: true,
            groupName: "LSM",
          });
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          alert("Connection Lost!!!");
        }
      });
  };
};

export const GetSpecificitembyStoreId = (storeId: any) => {
  let obj = {
    mode: sessionStorage.getItem("orderType") == "Qr-Pickup" ? "qr" : "online",
    store_id: 6,
  };
  return async (dispatch: any) => {
    Api.post(`menu/getItemsForSpecificStore`, obj)
      .then((response) => {
        if (response.data.success) {
          let Responedata = response.data.menuData;
          dispatch({
            type: SPECIFIC_STORE_ITEMS,
            payload: Responedata,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: SPECIFIC_STORE_ITEMS,
            payload: [],
          });
          let error;
          let data: any = [];
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          alert("Connection Lost!!!");
        }
      });
  };
};

//localStores with Circle Radius
// export const findLocalStores = (lat: any, lng: any) => {
//   return async (dispatch: any) => {
//     Api.get("menu/stores")
//       .then((response) => {
//         if (response.data.success) {
//           let Responedata = response.data.successResponse;
//           //find distance of two coordinates
//           const calcCrow = (
//             locationLat: any,
//             locationLong: any,
//             storeLat: any,
//             storeLong: any
//           ) => {
//             var R = 6371; // km
//             var differenceLat = toRad(storeLat - locationLat);
//             var differenceLon = toRad(storeLong - locationLong);
//             var locationLatRadian = toRad(locationLat);
//             var storeLatRadian = toRad(storeLat);
//             var a =
//               Math.sin(differenceLat / 2) * Math.sin(differenceLat / 2) +
//               Math.sin(differenceLon / 2) *
//               Math.sin(differenceLon / 2) *
//               Math.cos(locationLatRadian) *
//               Math.cos(storeLatRadian);
//             var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//             var distanceInMeter = R * c * 1000;
//             return distanceInMeter;
//           };
//           // Converts numeric degrees to radians
//           const toRad = (Value: any) => {
//             return (Value * Math.PI) / 180;
//           };
//           let data: any = [];
//           let store_list: any = [];
//           for (let i = 0; i < Responedata.length; i++) {
//             store_list.push(
//               calcCrow(lat, lng, Responedata[i].lat, Responedata[i].lng)
//             );
//             if (
//               calcCrow(lat, lng, Responedata[i].lat, Responedata[i].lng) <=
//               Responedata[i].trade_zone_coverage
//             ) {
//               data.push(Responedata[i]);
//             }
//           }
//           // if (data.length == 0) {
//           //   let nearest = Math.min.apply(Math, store_list);
//           //   for (let i = 0; i < Responedata.length; i++) {
//           //     if (
//           //       calcCrow(lat, lng, Responedata[i].lat, Responedata[i].lng) ==
//           //       nearest
//           //     ) {
//           //       data.push(Responedata[i]);
//           //     }
//           //   }
//           //   dispatch({
//           //     type: STORES_LIST,
//           //     payload: data,
//           //     deliveryfee: response.data.successResponse[0].delivery_fee,
//           //     storeStatus: 1,
//           //   });
//           //   // window.location.href = "/";
//           // } else {
//           dispatch({
//             type: STORES_LIST,
//             payload: data,
//             selectStoreId: data.length > 0 && data[0].store_id,
//             deliveryfee: data.length > 0 && data[0].delivery_fee,
//             storeStatus: 0,
//           });
//           // window.location.href = "/";
//           // }
//         }
//       })
//       .catch((err) => {
//         if (err.response) {
//           let error;
//           let data: any = [];
//           if (typeof err.response.data.message === "string") {
//             error = err.response.data.message;
//           } else if (typeof err.response.data.message === "object") {
//             error = err.response.data.message[0].replace(/_/g, " ");
//           }
//           window.location.href = "/";
//           dispatch({
//             type: STORES_LIST,
//             payload: data,
//             deliveryfee: 0,
//             storeStatus: 0,
//           });
//         } else {
//           alert("Connection Lost");
//         }
//       });
//   };
// };
//saveSelectStoreId
export const saveSelectStoreId = (store: any) => {
  return function (dispatch: any) {
    dispatch(saveStore(store));
    // sessionStorage.setItem("storeSelected", JSON.stringify(store))
    dispatch({
      type: SELECT_STORE_ID,
      selectStoreId: 6,
      // pos_fee: store.state_id.pos_service_fee ? store.state_id.pos_service_fee : 0
    });
    // dispatch(saveTaxDetails(store.state_id.state_name))
  };
};
export const saveStore = (store: any) => {
  return function (dispatch: any) {
    sessionStorage.setItem("storeSelected", JSON.stringify(store));
    dispatch({
      type: SELECT_STORE,
      storeSelected: store,
    });
  };
};
export const saveTaxDetails = (state_name: any) => {
  return function (dispatch: any) {
    let obj = {
      state_name: state_name,
    };
    // Api.post(`menu/tax`, obj)
    //   .then((response) => {
    //     if (response.data.success) {

    //       let responseData = response.data.successResponse
    //       responseData.map((taxVariations: any) => {
    //         if (taxVariations.payment_method == 'cash') {
    //           dispatch({
    //             type: GET_TAX_CASH,
    //             payload: taxVariations,
    //             deliveryfee: taxVariations.delivery_fee
    //           });
    //         }
    //         else if (taxVariations.payment_method == 'card') {
    //           dispatch({
    //             type: GET_TAX_CARD,
    //             payload: taxVariations,
    //             deliveryfee: taxVariations.delivery_fee,
    //           });

    //         }
    //       })
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response) {
    //       let error;
    //       //
    //     } else {
    //       //
    //     }
    //   });
  };
};

export const saveDeliveryFee = (delivery_fee: any) => {
  return function (dispatch: any) {
    dispatch({
      type: DELIVERY_FEE,
      deliveryfee: delivery_fee,
    });
  };
};
//get comboslist from selectstores
export const menuCombosList = () => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let data: any = {
      mode:
        sessionStorage.getItem("orderType") == "Qr-Pickup" ? "qr" : "online",
    };
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer_id;
    }
    data.order_channel = "web";
    Api.post("menu/combos", data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_COMBO_LIST,
            payload: response.data.successResponse,
          });
          //
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: MENU_COMBO_LIST,
            payload: [],
          });
        } else {
          //
        }
      });
  };
};
// social login for k2g
export const loginCustomerWithSocial = (data: any, history: any) => {
  return async (dispatch: any) => {
    Api.post("/customer/signinwithsocial", data)
      .then(async (response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          await sessionStorage.setItem("token", token);
          // history.push("/profile");
          // window.location.href = "/"
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });
          Toast.fire({
            icon: "success",
            title: "Login Successful",
          });
          setTimeout(() => {
            let cart: any = sessionStorage.getItem("cart");
            let Cart: any = cart;
            let dataCart = JSON.parse(Cart);

            const checkCart = dataCart?.length > 0 ? "/payment" : `/`;
            window.location.href = checkCart;
          }, 1100);
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          Toast.fire({
            icon: "error",
            title: error,
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          Toast.fire({
            icon: "error",
            title: "Connection Lost",
          });
        }
      });
  };
};

///top deals list

export const TopList = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let data: any = {
      mode: "online",
      // mode: sessionStorage.getItem('orderType') == "Qr-Pickup" ? 'qr' : 'online'
    };
    if (store_id) {
      data.store_id = store_id;
    } else {
      data.store_id = null;
    }
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer_id;
    }
    data.order_channel = "web";
    Api.post("menu/topDeals", data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TOP_LIST,
            payload: response.data.successResponse,
          });
          //
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: MENU_COMBO_LIST,
            payload: [],
          });
        } else {
          //
        }
      });
  };
};

//TOP DEALS HOME SCREEN
export const TopDealsHome = (store_id: any, mode?: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let data: any = {
      mode: "online",
      //mode: sessionStorage.getItem('orderType') == "Qr-Pickup" ? 'qr' : 'online'
    };

    if (store_id) {
      data.store_id = store_id;
    } else {
      data.store_id = null;
    }
    // if ((mode && mode == "Qr-Pickup")) {
    //   data.mode = "qr"
    // }
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer_id;
    }
    data.order_channel = "web";
    // Api.post("menu/top4Deals", data)
    //   .then((response) => {
    //     if (response.data.success) {
    //       dispatch({
    //         type: TOP_DEALS_HOME,
    //         payload: response.data.successResponse,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response) {
    //       let error;
    //       if (typeof err.response.data.message === "string") {
    //         error = err.response.data.message;
    //       } else if (typeof err.response.data.message === "object") {
    //         error = err.response.data.message[0].replace(/_/g, " ");
    //       }
    //       dispatch({
    //         type: MENU_COMBO_LIST,
    //         payload: [],
    //       });
    //     } else {
    //       //
    //     }
    //   });
  };
};

export const TopItemsList = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let data: any = {
      mode:
        sessionStorage.getItem("orderType") == "Qr-Pickup" ? "qr" : "online",
    };
    if (store_id) {
      data.store_id = store_id;
    } else {
      data.store_id = null;
    }
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer_id;
    }
    data.order_channel = "web";
    // Api.post("menu/topItems", data)
    //   .then((response) => {

    //     if (response.data.success) {
    //       dispatch({
    //         type: TOP_SELLING_ITEMS,
    //         payload: response.data.successResponse,
    //       });
    //       //
    //     }
    //   })
    //   .catch((err) => {
    //     if (err.response) {
    //       let error;
    //       if (typeof err.response.data.message === "string") {
    //         error = err.response.data.message;
    //       } else if (typeof err.response.data.message === "object") {
    //         error = err.response.data.message[0].replace(/_/g, " ");
    //       }
    //       dispatch({
    //         type: MENU_COMBO_LIST,
    //         payload: [],
    //       });
    //     } else {
    //       //
    //     }
    //   });
  };
};

//groupslist show in header menu

export const menuGroupsList = (store_id: any, mode?: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: MENU_GROUPS_LIST,
      payload: [],
      groupsData: [],
      loaderFlag: true,
    });
    let token: any = await sessionStorage.getItem("token");
    let data: any = {
      mode:
        sessionStorage.getItem("orderType") == "Qr-Pickup" ? "qr" : "online",
    };
    // if ((mode && mode == "Qr-Pickup")) {
    //   data.mode = "qr"
    // }
    if (store_id) {
      data.store_id = store_id;
    } else {
      data.store_id = null;
    }
    data.store_id = 6;
    if (token) {
      var decoded: any = jwt_decode(token);
      // data.customer_id = decoded.customer_id;
      Object.assign(data, { customer_id: decoded.customer.customer_id });
    }
    Api.post(`menu/totalCategories`, data)
      .then((response) => {
        if (response.data.success) {
          let newGroupsData: any = []; // filterd groupsData where data length > 0
          let newGroups: any = [];
          let tempGroup: any = []; // group ids where data length = 0
          response.data.groupsData.forEach((group: any) => {
            if (group.data.length != 0) {
              newGroupsData.push(group);
            } else if (group.data.length == 0) {
              tempGroup.push(group.group_id);
            }
          });

          response.data.groups.forEach((data: any) => {
            if (!tempGroup.includes(data.group_id)) {
              newGroups.push(data);
            }
          });

          // tempGroup.forEach((temp: any) => {
          //   if (temp.data.length !=0){
          //     tempGroup.push(newGroups)
          //   }
          //   else if (temp.data.length==0){
          //     tempGroup.push(tempGroup)
          //   }

          // });

          dispatch({
            type: MENU_GROUPS_LIST,
            payload: newGroups,
            groupsData: newGroupsData,
            loaderFlag: false,
          });
          // cartItems.forEach((cart: any) => {
          //   if (cart.item) {
          //     savyourObj = {
          //       category_name: cart.item.item_group_id.group_name,
          //       product_amount: cart.totalItemPrice,
          //       product_quantity: cart.quantity,
          //       product_id: cart.item.menu_item_id,
          //       product_name: cart.item.item_name
          //     }
          //   }
          //   else if (cart.combo) {
          //     savyourObj = {
          //       category_name: cart.combo.group_id.group_name,
          //       product_amount: cart.totalItemPrice,
          //       product_quantity: cart.quantity,
          //       product_id: cart.combo.combo_id,
          //       product_name: cart.combo.combo_name
          //     }
          //   }
          //   savyourProducts.push(savyourObj);
          // });
          // // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
          dispatch({
            type: MENU_GROUPS_LIST,
            payload: [],
            groupsData: [],
            loaderFlag: false,
          });
        } else {
          //
        }
      });
  };
};

//menu item for home
export const menuGroupsListHome = (store_id: any, mode?: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let data: any = {
      // mode: sessionStorage.getItem('orderType') == "Qr-Pickup" ? 'qr' : 'online'
      mode: "online",
    };
    if (store_id) {
      data.store_id = store_id;
    } else {
      data.store_id = null;
    }
    // if ((mode && mode == "Qr-Pickup")) {
    //   data.mode = "qr"
    // }

    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    Api.post(`menu/homeCategories`, data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_GROUPS_LIST_HOME,
            payload: response.data.successResponse,
          });
          // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          // dispatch({
          //   type: MENU_ITEMS,
          //   payload: [],
          // });
          dispatch({
            type: MENU_GROUPS_LIST,
            payload: [],
          });
        } else {
          //
        }
      });
  };
};

//groupslist show menu k
export const menuItemsListByGroupsID = (data: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let user: any = {
      mode:
        sessionStorage.getItem("orderType") == "Qr-Pickup" ? "qr" : "online",
    };
    if (token) {
      var decoded: any = jwt_decode(token);
      user.customer_id = decoded.customer_id;
    }

    user.order_channel = "web";
    Api.post(`menu/items/${data.group_id}`, user)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_ITEMS,
            payload: response.data.successResponse,
            groupName: data.group_name,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          //
          dispatch({
            type: MENU_ITEMS,
            payload: [],
          });
        } else {
          //
        }
      });
  };
};

//Order list of customer

export const ordersList = () => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    if (token) {
      let decoded: any;
      decoded = token ? jwt_decode(token) : null;
      Api.get(
        `/customer/orders_for_web/${
          decoded.customer && decoded.customer.customer_id
        }`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: ORDER_LIST,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: ORDER_LIST,
              payload: [],
            });
          } else {
            //
          }
        });
    }
  };
};

//Get Cart

export const getCart = (history?: any) => {
  return async (dispatch: any) => {
    let cart: any = await sessionStorage.getItem("cart");
    if (cart === null || cart.length <= 0) {
      cart = [];
    }
    dispatch({
      type: GET_CART,
      payload: cart,
    });
  };
};

export const getAreaDetails = () => {
  return async (dispatch: any) => {
    let area_details: any = await sessionStorage.getItem("area_details");
    if (area_details) {
      dispatch({
        type: "AREA_DETAILS",
        payload: area_details,
      });
    }
  };
};

export const setDefaultAddress = (address_id: any) => {
  return async (dispatch: any) => {
    let token: any = sessionStorage.getItem("token");
    if (token) {
      let customerData: any = jwtDecode(token);
      Api.put(
        `/customer/set_default_address`,
        {
          customer_id: customerData.customer.customer_id,
          address_id: address_id,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
        .then((response: any) => {
          dispatch(addressesListk());
          if (response.data.success) {
            toast.error("Address set to default", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        })
        .catch((err: any) => {
          toast.error("Some error occured", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };
};

// save cart

export const saveCart = (cart: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    //convert cart to string
    let strCart = JSON.stringify(cart);
    //save cart
    sessionStorage.setItem("cart", strCart);
    getCart();
    dispatch({
      type: SAVE_CART,
      success: true,
    });
  };
};

// k2g franchise

export const franchinseApplication = (data: any) => {
  return function (dispatch: any) {
    try {
      Api.post(`customer/franchise_application`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;

            dispatch({
              type: FRANCHISE_APPLICATION,
              isFilled: true,
            });
            toast.info("Your application submitted successfully", {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            window.location.href = `/menu${window.location.search}`;
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              if (err.response.data.code === "ER_DUP_ENTRY") {
                dispatch({
                  type: FRANCHISE_APPLICATION,
                  isFilled: false,
                });
                // alert("Email Already Exist");
                // error = "Email Already Exist";
              } else {
                error = err.response.data.message;
              }
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
          } else {
            //
          }
        });
    } catch (e) {
      //
    }
  };
};

//trackOrder

export const trackOrder = (data: any) => {
  return function (dispatch: any) {
    try {
      Api.post(`customer/trackOrder`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;
            dispatch({
              type: ORDER_DATA,
              storeData: responseData,
            });
            // toast.error("Email send successfully", {
            //   position: toast.POSITION.TOP_CENTER,
            //   hideProgressBar: true,
            //   autoClose: 3000,
            // });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: ORDER_DATA,
              storeData: {},
            });
            toast.warning(
              "Sorry, the order could not be found. Please contact us if you are having difficulty finding your order details.",
              {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
                autoClose: 3000,
              }
            );
          } else {
            //
          }
        });
    } catch (e) {
      //
    }
  };
};
//k2g restuarantLocator
export const getStores = (data: any) => {
  return function (dispatch: any) {
    try {
      Api.get(`customer/stores`)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;

            dispatch({
              type: RESTUARANTS,
              stores: responseData,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            toast.warning(error, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            //
          }
        });
    } catch (e) {
      //
    }
  };
};

//save delivery fee of store
export const saveStoreName = (storeID: any) => {
  return function (dispatch: any) {
    dispatch({
      type: STORE_NAME,
      StoreID: storeID,
    });
  };
};

// get store id
export const saveStoreID = (store_id: any) => {
  return function (dispatch: any) {
    dispatch({
      type: STORE_ID,
      Store_ID: store_id,
    });
  };
};

/// type save pickup, delivery save
export const saveType = (type: any) => {
  return function (dispatch: any) {
    dispatch({
      type: STORE_TYPE,
      Types: type,
    });
  };
};

/// type save pickup, dinein save
export const saveOrderType = (type: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER_TYPE,
      orderType: type,
    });
    sessionStorage.setItem("orderType", type);
  };
};
export const saveModeModal = (pickupOpen: any) => {
  return function (dispatch: any) {
    dispatch({
      type: PICKUP_OPEN,
      pickupOpen: pickupOpen,
    });
  };
};

// type save pickup, payment method
export const savePaymentMethod = (payment_method: any) => {
  return function (dispatch: any) {
    dispatch({
      type: PAYMENT_METHOD,
      paymentMethod: payment_method,
    });
  };
};
export const saveurlMethod = (lookupurl: any) => {
  return function (dispatch: any) {
    dispatch({
      type: URL_MATCH_METHOD,
      url: lookupurl,
    });
  };
};

// type save pickup, payment method
export const saveOrderid = (order_id: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER,
      order_id: order_id,
    });
  };
};
/// type save home, office, other
export const saveAddressTypeUser = (type: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_ADDRESS_USER,
      saveAddressUser: type,
    });
  };
};
export const saveOrderPeriod = (period: any) => {
  return function (dispatch: any) {
    dispatch({
      type: ORDER_PERIOD,
      orderPeriod: period,
    });
  };
};
export const saveOrderDateTime = (date: any, time: any, preOrLater: any) => {
  return function (dispatch: any) {
    if (preOrLater === "orderLater") {
      sessionStorage.setItem("orderNow", preOrLater);
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
      const [hours, minutes] = time.split(":").map(Number);
      const futureDateTime = moment({ year, month, day, hours, minutes });
      if (futureDateTime.isValid()) {
        sessionStorage.setItem(
          "futureDate",
          futureDateTime.format("YYYY-MM-DD HH:mm")
        );
      } else {
        console.log("Invalid date and time format");
      }

      dispatch({
        type: ORDER_DATE_TIME,
        orderDate: date,
        orderTime: time,
        orderNow: preOrLater,
      });
    } else {
      sessionStorage.setItem("orderNow", preOrLater);
      dispatch({
        type: ORDER_DATE_TIME,
        orderNow: preOrLater,
      });
    }
  };
};
export const saveHouseDetail = (house: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_HOUSEDETAIL,
      houseDetail: house,
    });
  };
};
export const saveAddressType = (addressType: any) => {
  return function (dispatch: any) {
    dispatch({
      type: SAVE_ADDRESSTYPE,
      addressType: addressType,
    });
  };
};
//get tax value by store state
export const getTaxValue = (data_tax: any) => {
  return async (dispatch: any) => {
    Api.get(`menu/tax_value/${data_tax}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_TAX,
            payload: response.data.successResponse.tax_percent,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          //
        } else {
          //
        }
      });
  };
};

// saveorder or get orderid from API
export const saveOrder = (data: any, store: any, tax: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    // let guest = true;
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    return await Api.post(`/customer/save_order`, data)
      .then(async (response) => {
        if (response.data.success) {
          sessionStorage.setItem("order_id", response.data.order_id);
          return response.data.order_id;
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
        }
      });
  };
};

export const stampAndAwards = (data: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    // let guest = true;
    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer.customer_id;
    }
    return await Api.post(`/customer/stampAndAwards`, data)
      .then(async (response) => {
        if (response.data.success) {
          sessionStorage.removeItem("order");
          return response.data.order_id;
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
        }
      });
  };
};

export const paymentProcess = (data: any) => {
  return async (dispatch: any) => {
    Api.post(`/customer/save_payment`, data)
      .then(async (response) => {
        if (response.data.success) {
          sessionStorage.removeItem("cart");
          dispatch({
            type: PAYMENT,
            PaymentSuccess: "success",
            successFlag: true,
          });

          // window.location.href = "/";
          // Toast.show({
          //   text: 'Thank You! Your order is successfull submitted',
          //   buttonText: 'OK',
          //   duration: 5000,
          //   type: 'success',
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
        }
      });
  };
};

export const saveGuestAddress = (address_save_session: any) => {
  return async function (dispatch: any) {
    dispatch({
      type: ADDRESS_GUEST,
      Address: address_save_session,
    });
    await sessionStorage.setItem("address_save_session", address_save_session);
  };
};

/// get group item detail

export const getItemDetail = (item_id: any) => {
  return async (dispatch: any) => {
    let obj: any = {
      order_channel: "web",
      mode:
        sessionStorage.getItem("store_id_qr") &&
        sessionStorage.getItem("orderType")
          ? "qr"
          : "online",
    };
    dispatch({
      type: COMBO_DETAIL,
      payload: {},
    });
    Api.post(`menu/product/${item_id}`, obj)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: ITEM_DETAIL,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: ITEM_DETAIL,
            payload: [],
          });
        } else {
        }
      });
  };
};

export const favouriteAdd = (param1: any, store_id?: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    if (token) {
      var decoded: any = jwt_decode(token);
      param1.customer_id = decoded.customer.customer_id;
    }
    Api.post("customer/add_wishlist", param1, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          // if (param1.combo_id && !param1.group_id) {
          //   toast.error('Added to favourite items', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
          //   if (!param1.topDeal) {
          //     dispatch(menuCombosList());
          //   } else {
          //     dispatch(TopList(store_id));
          //   }
          // }
          // else if (param1.group_id) {
          toast.error("Added to favourite items", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          dispatch(menuItemsListByGroupsID(param1));
          dispatch(menuGroupsList(store_id));
          dispatch(featuredProducts(store_id));

          // } else if (param1.topItem) {
          //   toast.error('Added to favourite items', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 })
          //   dispatch(TopItemsList(store_id));
          // }
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

export const menuHeroItems = () => {
  return async (dispatch: any) => {
    Api.get("menu/banners")
      .then((response) => {
        if (response.data.success) {
          localStorage.setItem("array", response.data.successResponse.length);
          dispatch({
            type: HERO_ITEMS,
            payload: response.data.successResponse,
            loading: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: HERO_ITEMS,
            payload: [],
          });
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

export const forgotPassword = (email: any) => {
  return function (dispatch: any) {
    Api.post("/customer/forgot-password", {
      email: email,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FORGOT,
            isReset: true,
          });
          //  toast.error('We have sent you email on registered account',{hideProgressBar: true, autoClose: 6000})

          //  window.location.href = "/";
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: FORGOT,
            isReset: false,
          });
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  };
};

export const generateOtp = (email: any) => {
  return function (dispatch: any) {
    Api.post("/customer/generateOtp", {
      email: email,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FORGOT,
            isReset: true,
            respMessage: response.data.successResponse,
            switchTab: "2",
          });
          //  toast.error('We have sent you email on registered account',{hideProgressBar: true, autoClose: 6000})

          //  window.location.href = "/";
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: FORGOT,
            isReset: false,
            respMessage: err.response.data.message,
            switchTab: "1",
          });
          let error;
          if (typeof err.response.data.message === "string") {
            toast.error(err.response.data.message);
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  };
};

export const ValidateOtp = (data: any) => {
  return function (dispatch: any) {
    Api.post("/customer/validateOtp", data)
      .then((response) => {
        if (response.data.success) {
          dispatch({ type: SWITCH_TAB, switchTab: "3" });
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: true,
            OtprespMessage: response.data.successResponse,
            switchTab: "3",
          });
          //  toast.error('We have sent you email on registered account',{hideProgressBar: true, autoClose: 6000})

          //  window.location.href = "/";
        } else if (response.data.success == false) {
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: false,
            OtprespMessage: response.data.successResponse,
            switchTab: "2",
          });

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          Toast.fire({
            icon: "error",
            title: `${response.data.successResponse}`,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: false,
            OtprespMessage: err.response.data.message,
            switchTab: "2",
          });
          toast.error(err.response.data.message);

          let error;
          if (typeof err.response.data.message === "string") {
            toast.error(err.response.data.message);
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          Toast.fire({
            icon: "error",
            title: error,
          });
        }
      });
  };
};

export const ValidateRegistrationOtp = (data: any) => {
  return function (dispatch: any) {
    Api.post("/customer/validateOtp", data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: true,
            OtprespMessage: response.data.successResponse,
          });

          //  toast.error('We have sent you email on registered account',{hideProgressBar: true, autoClose: 6000})

          //  window.location.href = "/";

          dispatch(loginSignup(data.email, data.login_password));
          setTimeout(() => {
            let cart: any = sessionStorage.getItem("cart");
            let Cart: any = cart;
            let dataCart = JSON.parse(Cart);

            const checkCart = dataCart?.length > 0 ? "/payment" : `/`;
            window.location.href = checkCart;
          }, 2000);
        } else if (response.data.success == false) {
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: false,
            OtprespMessage: response.data.successResponse,
          });

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          Toast.fire({
            icon: "error",
            title: `${response.data.successResponse}`,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: VALIDATE_OTP,
            isValidOtp: false,
            OtprespMessage: err.response.data.message,
            switchTab: "2",
          });
          toast.error(err.response.data.message);

          let error;
          if (typeof err.response.data.message === "string") {
            toast.error(err.response.data.message);
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000,
          });
          Toast.fire({
            icon: "error",
            title: error,
          });
        }
      });
  };
};

export const changePasswordOtp = (data: any) => {
  return function (dispatch: any) {
    Api.post("/customer/changePasswordOtp", data)
      .then((response) => {
        if (response.data.success) {
          // dispatch({type: SWITCH_TAB, switchTab:'3'})
          //
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1500,
          });
          Toast.fire({
            icon: "success",
            title: "Your password has been reset. Login with new password.",
          });
          setTimeout(() => {
            window.location.href = `/login${window.location.search}`;
            dispatch({
              type: VALIDATE_OTP,
              isValidOtp: false,
              OtprespMessage: "",
              switchTab: "3",
            });
          }, 3000);
        } else if (response.data.success == false) {
          // dispatch({
          //   type: VALIDATE_OTP,
          //   isValidOtp: false,
          //   OtprespMessage: response.data.successResponse,
          //   switchTab: '2'
          // })
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            toast.error(err.response.data.message);
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
  };
};

export const favoritesList = () => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    if (token) {
      var decoded: any = jwt_decode(token);
      Api.get(`/customer/wishlist/${decoded.customer.customer_id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: FAV_MENU_ITEM,
              payload: response.data.successResponse,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: FAV_MENU_ITEM,
              payload: [],
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

export const deletefavourites = (wish_id: number) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    Api.delete(`/customer/del_wish/${wish_id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          toast.error("Favourite item removed successfully");
          dispatch(favoritesList());
          // Actions.refresh({ key: Math.random() });
          // Toast.show({
          //   text: 'Removed From Favourite items',
          //   type: 'success',
          //   duration: 5000,
          // });
        }
      })
      .catch((err) => {
        if (err.response) {
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

export const offersList = () => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    if (token) {
      Api.get(`customer/promos`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch({
              type: OFFERS_LIST,
              payload: response.data.successResponse,
              loading: false,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
            dispatch({
              type: OFFERS_LIST,
              payload: [],
              loading: false,
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

export const applyCoupon = (data: any) => {
  //type of cart is array
  return async (dispatch: any) => {
    Api.post(`/menu/apply_coupon`, data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: COUPON_DATA,
            payload: response.data.coupon,
            successREsponse: response.data.successResponse,
          });
          // toast.error(response.data.successResponse, { hideProgressBar: true, autoClose: 6000 })
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(error);
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};
export const setTabName = (tabName: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: CHECKOUT_TAB_NAME,
      tabName: tabName,
    });
  };
};
export const setTabNumber = (tabNumber: any) => {
  return async (dispatch: any) => {
    sessionStorage.setItem("tabNumber", tabNumber);
    dispatch({
      type: TABLE_NUMBER,
      tabNumber: tabNumber,
    });
  };
};
export const saveTaxRate = (taxRate: any) => {
  return async (dispatch: any) => {
    sessionStorage.setItem("tax_rate", taxRate);
    dispatch({
      type: SAVE_TAX_RATE,
      taxRate: taxRate,
    });
  };
};
export const saveAgreementCheck = (isChecked: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SAVE_AGREEMENT_CHECK,
      isChecked: isChecked,
    });
  };
};

export const getStoreByIdForOrders = (store_id: any) => {
  return async (dispatch: any) => {
    Api.get(`customer/store/${store_id}`).then((response: any) => {
      if (response.data.success) {
        dispatch({
          type: SELECT_STORE,
          storeSelected: response.data.successResponse,
        });
        dispatch(saveTaxDetails(response.data.successResponse.state_name));
      }
    });
  };
};

export const getStoreById = (store_id: any, mode?: any) => {
  return async (dispatch: any) => {
    // const sessionQRMode=sessionStorage.getItem("store_id_qr");
    Api.get(`customer/store/${store_id}`)
      // Api.get(`customer/store/${store_id}`, {
      //   headers: { Authorization: 'Bearer ' + token },
      // })
      .then((response) => {
        if (response.data.success) {
          // if (mode && mode == "Qr-Pickup" || "Pickup") {
          dispatch(menuGroupsList(store_id));
          dispatch(menuGroupsListHome(store_id));
          dispatch(TopList(store_id));
          // dispatch(TopItemsList(store_id));
          // dispatch(TopDealsHome(store_id))
          // }
          sessionStorage.setItem(
            "storeSelected",
            JSON.stringify(response.data.successResponse)
          );
          dispatch({
            type: SELECT_STORE,
            storeSelected: response.data.successResponse,
          });
          dispatch(saveTaxDetails(response.data.successResponse.state_name));
        }
      })
      .catch((err) => {
        if (err.response) {
          // window.location.href = `/404`;
          // Toast.show({
          //   text: error,
          //   buttonText: 'OK',
          //   duration: 5000,
          //   type: 'danger',
          // });
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
  };
};

export const orderDetail = (order_id: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ORDER_DETAIL,
      payload: [],
      loading: true,
    });
    let token: any = await sessionStorage.getItem("token");
    if (token) {
      Api.get(`/customer/order/${order_id}`, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            // dispatch(getStoreById(response.data.successResponse.order.store_id))
            setTimeout(() => {
              dispatch({
                type: ORDER_DETAIL,
                payload: response.data.successResponse,
                loading: false,
              });
            }, 500);
          }
        })
        .catch((err) => {
          if (err.response) {
            dispatch({
              type: ORDER_DETAIL,
              payload: [],
            });
          } else {
            // Toast.show({
            //   text: err.message,
            //   buttonText: 'OK',
            // });
          }
        });
    }
  };
};

export const orderDetailAfterPayment = (order_id: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ORDER_DETAIL,
      payload: [],
      loading: true,
    });
    Api.get(`/customer/orderAfterPayment/${order_id}`, {
      // headers: { Authorization: 'Bearer ' + token },
    })
      .then((response) => {
        if (response.data.success) {
          // dispatch(getStoreById(response.data.successResponse.order.store_id))
          setTimeout(() => {
            dispatch({
              type: ORDER_DETAIL,
              payload: response.data.successResponse,
              loading: false,
            });
          }, 500);
          let order_data: any = sessionStorage.getItem("order");
          if (JSON.parse(order_data)) {
            dispatch(stampAndAwards(JSON.parse(order_data)));
          }
        }
        sessionStorage.removeItem("cart");
        dispatch({
          type: GET_CART,
          payload: [],
        });
        // dispatch(saveCart(null))
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: ORDER_DETAIL,
            payload: [],
          });
        } else {
          // Toast.show({
          //   text: err.message,
          //   buttonText: 'OK',
          // });
        }
      });
    // let token: any = await sessionStorage.getItem('token');
    // if (token) {

    // }
  };
};

//add user address
export const addAddressUser = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    if (token) {
      Api.post(`/customer/add_address`, data, {
        headers: { Authorization: "Bearer " + token },
      })
        .then((response) => {
          if (response.data.success) {
            toast.error(response.data.successResponse, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
            dispatch(addressesListk());
            // window.location.reload();
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data === "string") {
              error = err.response.data;
            } else if (typeof err.response.data === "object") {
              error = err.response.data;
            }
          }
        });
    }
  };
};
export const deleteAddress = (addressData: any, address_id: number) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    Api.delete(`/customer/del_address/${address_id}`, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          var filtered = addressData.filter((element: any, index: any) => {
            return element.address_id !== address_id;
          });
          dispatch({
            type: ADDRESS_LIST,
            payload: filtered,
          });
          toast.error("Address deleted succesfully", {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        //
      });
  };
};
export const editAddress = (address_id: any, data: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    if (token) {
      Api.put(`/customer/edit_address/${address_id}`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => {
          if (response.data.success) {
            dispatch(addressesListk());
            toast.error(response.data.successResponse, {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            let error;
            if (typeof err.response.data.message === "string") {
              error = err.response.data.message;
            } else if (typeof err.response.data.message === "object") {
              error = err.response.data.message[0].replace(/_/g, " ");
            }
          } else {
          }
        });
    }
  };
};

export const addEmail = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    Api.post(`/customer/add_email`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.successResponse, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          // window.location.reload();
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data === "string") {
            error = err.response.data;
          } else if (typeof err.response.data === "object") {
            error = err.response.data;
          }
        }
      });
  };
};

export const addContact = (data: any) => {
  return function (dispatch: any) {
    dispatch({
      type: NETWORK_STATUS,
      payload: true,
    });
    let token: any = sessionStorage.token;
    Api.post(`/customer/add_contact`, data, {
      headers: { Authorization: "Bearer " + token },
    })
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.successResponse, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.href = `/menu${window.location.search}`;
          }, 3000);
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: NETWORK_STATUS,
            payload: false,
          });
          let error;
          if (typeof err.response.data === "string") {
            error = err.response.data;
          } else if (typeof err.response.data === "object") {
            error = err.response.data;
          }
        }
      });
  };
};

//Submit complaint
export const submitComplaint = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    Api.post(`/customer/feedback_application`, data)
      .then((response) => {
        if (response.data.success) {
          toast.error(response.data.successResponse, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setTimeout(() => {
            window.location.href = `/menu${window.location.search}`;
          }, 2000);
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data === "string") {
            error = err.response.data;
          } else if (typeof err.response.data === "object") {
            error = err.response.data;
          }
        }
      });
  };
};

export const updateLSM = (status: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: UPDATE_LSM,
      statusInfo: status,
    });
  };
};

export const getCustomerByPhone = (id: any) => {
  return function (dispatch: any) {
    Api.get(`/customer/oldCustomerNewPassword/${id}`)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: NEW_CUSTOMER_PHONE_URL,
            code: response.data.code,
            response: response.data.message,
            success: response.data.success,
            name: response.data.name,
          });
        }
      })
      .catch((err) => {});
  };
};

export const changePasswordOldCustomer = (newPass: any, phone: any) => {
  return function (dispatch: any) {
    Api.put(`/customer/changePasswordOldCustomer`, {
      phone: phone,
      login_password: newPass,
    })
      .then(async (response) => {
        if (response.data.success) {
          toast.error(
            "Password changed successfully, Logging in to your system",
            {
              position: toast.POSITION.TOP_CENTER,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          let token = response.data.accessToken;
          // dispatch({
          //   type: LOG_IN,
          //   isLoggedInError: false,
          // });
          await sessionStorage.setItem("token", token);
          await sessionStorage.setItem(
            "profile_pic",
            response.data.customer.profile_pic
          );
          await sessionStorage.setItem(
            "name",
            response.data.customer.login_name
          );
          await sessionStorage.setItem(
            "email",
            response.data.customer.email_address
          );
          await sessionStorage.setItem(
            "phone",
            response.data.customer.phone_number
          );
          await sessionStorage.setItem(
            "id",
            response.data.customer.customer_id
          );
          await sessionStorage.setItem("gender", response.data.customer.gender);
          await sessionStorage.setItem(
            "customer_birthday",
            response.data.customer.customer_birthday
          );
          await sessionStorage.setItem("profiledata", response.data.customer);
          window.location.href = `/menu${window.location.search}`;
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          toast.error(err.response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: false,
            autoClose: 7000,
          });
        } else {
          alert("Connection Lost!!");
        }
      });
  };
};
//update menu items from existing props
export const UpdateMenuItems = (data: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: MENU_ITEMS,
      payload: data.menuItemsData,
      groupName: data.group_name,
    });
  };
};

//redirect customer to tab1 incase he leave component for forgot password
export const updateForgotTab = (tab: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: FORGOT,
      isReset: "",
      respMessage: "",
      switchTab: tab,
    });
  };
};

//Validate Cart
export const ValidateCart = (data: any) => {
  return function (dispatch: any) {
    let token: any = sessionStorage.token;
    Api.post(`/menu/validateCart`, data)
      .then((response) => {
        if (response.data.success) {
          toast.info(response.data.successResponse, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
          window.location.href = `/menu${window.location.search}`;
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data === "string") {
            error = err.response.data;
          } else if (typeof err.response.data === "object") {
            error = err.response.data;
          }
        }
      });
  };
};

//get QR Menu for storeId
//groupslist show in header menu
export const getQrStoreMenu = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let data: any = {
      mode: "qr",
      store_id: store_id,
    };

    if (token) {
      var decoded: any = jwt_decode(token);
      data.customer_id = decoded.customer_id;
    }
    Api.post(`menu/totalCategories`, data)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: MENU_GROUPS_LIST,
            payload: response.data.groups,
            groupsData: response.data.groupsData,
            loaderFlag: false,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
          dispatch({
            type: MENU_GROUPS_LIST,
            payload: [],
            groupsData: [],
            loaderFlag: false,
          });
        } else {
          //
        }
      });
  };
};

export const getSingleStore = (id: number) => {
  return function (dispatch: any) {
    Api.get(`/customer/qrstore/${id}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: GET_STORE,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
        } else {
          alert(err.message);
        }
        dispatch({
          type: GET_STORE,
          payload: null,
        });
      });
  };
};

export const getCitiesWeb = () => {
  return function (dispatch: any) {
    // Api.get(`/customer/cities_web`)
    //   .then((response) => {
    //     if (response.data.success) {
    //       dispatch({
    //         type: CITIES_WEB,
    //         payload: response.data.successResponse
    //       })
    //     }
    //   }).catch(err => {
    //     if (err.response) {
    //     } else {
    //       alert(err.message)
    //     }
    //   });
  };
};

export const getTradeZonesWeb = (id: number) => {
  return function (dispatch: any) {
    Api.get(`/customer/tradezones_web/${id}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TRADE_ZONES_WEB,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
        } else {
          alert(err.message);
        }
      });
  };
};

export const getTradeAreas = (id: number) => {
  return function (dispatch: any) {
    Api.get(`/customer/tradeareas_web/${id}`)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: TRADE_AREAS_WEB,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          dispatch({
            type: TRADE_AREAS_WEB,
            payload: [],
          });
        } else {
          alert(err.message);
        }
      });
  };
};

export const resetTradeZones = () => {
  return function (dispatch: any) {
    dispatch({
      type: TRADE_ZONES_WEB,
      payload: [],
    });
  };
};

export const resetTradeAreas = () => {
  return function (dispatch: any) {
    dispatch({
      type: TRADE_AREAS_WEB,
      payload: [],
    });
  };
};

export const setCityName = (name: String) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_CITY_NAME,
      payload: name,
    });
  };
};

export const setTradeZoneName = (name: String, value: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_TRADE_ZONE_NAME,
      payload: name,
      value: value,
    });
  };
};

export const setTradeAreaName = (name: String, value: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: SET_TRADE_AREA_NAME,
      payload: name,
      value: value,
    });
  };
};

export const findLocalStoresWithoutMap = (store: any) => {
  return async (dispatch: any) => {
    if (store) {
      let data: any = [];
      data.push(store);
      //after store is find out check if the store is active and if it not active,
      //then find its backup stores array and push the backup store

      if (data.length > 0 && data[0].is_active == 0) {
        if (data[0].mainStoreId.length > 0) {
          let backupStoreData: any = [];
          data[0].mainStoreId.map((backupStore: any) => {
            if (backupStore.backupStoreId.is_active == 1) {
              backupStoreData.push(backupStore);
            }
          });
          //sorting array with priority basis if backup stores are greater than 1
          if (backupStoreData.length > 1) {
            backupStoreData.sort((a: any, b: any) => {
              return a.priority - b.priority;
            });
          }
          //finding the backupstore object from response data and match them by store_id
          let foundedStore: any = [];
          if (backupStoreData.length > 0) {
            let response: any;
            await Api.get("menu/stores").then((Responedata: any) => {
              response = Responedata.data.successResponse;
            });
            foundedStore.push(
              response.find(
                (store: any) =>
                  backupStoreData[0].backupStoreId.store_id == store.store_id
              )
            );
            // foundedStore.push(backupStoreData[0])

            dispatch({
              type: STORES_LIST,
              payload: foundedStore,
              // selectStoreId: foundedStore.length > 0 && foundedStore[0].backupStoreId.store_id,
              selectStoreId:
                foundedStore.length > 0 && foundedStore[0].store_id,
              pos_fee: foundedStore[0].state_id.pos_service_fee
                ? foundedStore[0].state_id.pos_service_fee
                : 0,
              // deliveryfee: foundedStore.length > 0 && foundedStore[0].country_id.delivery_fee,
              storeStatus: 0,
            });
            dispatch({
              type: SELECT_STORE,
              // storeSelected: foundedStore.length > 0 && foundedStore[0].backupStoreId,
              storeSelected: foundedStore.length > 0 && foundedStore[0],
            });
            dispatch(saveTaxDetails(data[0].state_id.state_name));
          } else {
            dispatch({
              type: STORES_LIST,
              payload: foundedStore,
              selectStoreId:
                foundedStore.length > 0 &&
                foundedStore[0].backupStoreId.store_id,
              // deliveryfee: foundedStore.length > 0 && foundedStore[0].country_id.delivery_fee,
              storeStatus: 0,
            });

            dispatch({
              type: SELECT_STORE,
              storeSelected:
                foundedStore.length > 0 && foundedStore[0].backupStoreId,
            });
          }
        } else {
          dispatch({
            type: STORES_LIST,
            payload: [],
            selectStoreId: "",
            // deliveryfee: data.length > 0 && data[0].country_id.delivery_fee,
            storeStatus: 0,
            pos_fee: 0,
          });
          dispatch({
            type: SELECT_STORE,
            storeSelected: {},
          });
          dispatch(saveTaxDetails(data[0].state_id.state_name));
        }
      } else {
        dispatch({
          type: STORES_LIST,
          payload: data,
          selectStoreId: data.length > 0 && data[0].store_id,
          deliveryfee: 0,
          storeStatus: 0,
          pos_fee: data[0].state_id
            ? data[0].state_id.pos_service_fee
              ? data[0].state_id.pos_service_fee
              : 0
            : 0,
        });
        dispatch({
          type: SELECT_STORE,
          storeSelected: data.length > 0 && data[0],
        });
        if (data.length > 0) {
          dispatch(saveTaxDetails(data[0].state_id.state_name));
        }
      }
      // dispatch(GetLSMitembyStoreId(data[0].store_id))
    }
  };
};

export const addNewAddressWithoutMap = (address: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: ADD_NEW_ADDRESS_WITHOUT_MAP,
      payload: address,
    });
  };
};

export const getTimezone = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        if (response.data.success) {
          // dispatch({
          //   type: GET_TIMEZONE,
          //   payload: response.data.successResponse
          // })
          return response.data.successResponse;
        }
      })
      .catch((err) => {
        if (err.message) {
          alert(err.message);
        }
      });
  };
};

export const getTimezoneForLater = (id: number) => {
  return function (dispatch: any) {
    return Api.get(`/customer/state/timezone/${id}`)
      .then((response) => {
        if (response.data.success) {
          // dispatch({
          //   type: GET_TIMEZONE,
          //   payload: response.data.successResponse
          // })
          return {
            time: response.data.successResponse,
            date: response.data.successLaterResponse,
          };
        }
      })
      .catch((err) => {
        if (err.message) {
          alert(err.message);
        }
      });
  };
};

export const cartToggle = (toggle: any) => {
  return function (dispatch: any) {
    dispatch({
      type: CART_TOGGLE,
      payload: toggle,
    });
  };
};

export const featuredProducts = (store_id: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    let obj: any = {};
    if (token) {
      var decoded: any = jwt_decode(token);
      obj.customer_id = decoded.customer.customer_id;
    }
    Api.post(`/menu/featured_products/online/${store_id}`, obj)
      .then((response) => {
        if (response.data.success) {
          dispatch({
            type: FEATURED_PRODUCTS,
            payload: response.data.successResponse,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: FEATURED_PRODUCTS,
          payload: [],
        });
        // if (err.message) {
        //   alert(err.message)
        // }
      });
  };
};

export const changeLocationMenuCall = (store_id: any) => {
  return function (dispatch: any) {
    dispatch(menuGroupsListHome(store_id));
    dispatch(menuGroupsList(store_id));
    // dispatch(TopDealsHome(store_id))
    // dispatch(TopItemsList(store_id))
    dispatch(featuredProducts(store_id));
  };
};

export const searchItems = (data: any) => {
  return function (dispatch: any) {
    try {
      Api.post(`menu/search_items`, data)
        .then((response) => {
          if (response.data.success) {
            let responseData = response.data.successResponse;

            dispatch({
              type: FILTERED_ITEMS,
              payload: responseData,
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: FILTERED_ITEMS,
            payload: [],
          });
        });
    } catch (e) {
      //
    }
  };
};

export const emptyFilteredItems = () => {
  return function (dispatch: any) {
    dispatch({
      type: FILTERED_ITEMS,
      payload: [],
    });
  };
};

export const resetCoupon = (obj: any) => {
  return async (dispatch: any) => {
    dispatch({
      type: COUPON_DATA,
      payload: {},
      successREsponse: false,
    });
  };
};

export const loginUser = (data: any, history: any) => {
  return function (dispatch: any) {
    Api.post("/customer/auth", data)
      .then((response) => {
        if (response.data.success) {
          let token = response.data.accessToken;
          sessionStorage.setItem("mainToken", token);
          // window.location.href = '/home'
          history.push("/home");
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0];
          }
          toast.warn(error, {
            position: toast.POSITION.TOP_CENTER,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          alert(err.message);
        }
      });
  };
};

export const updatePaymentStatus = (payment_id: any, data?: any) => {
  return async (dispatch: any) => {
    Api.put(`/customer/update_payment_status/${payment_id}`, data, {
      // headers: {
      //   Authorization: 'Bearer ' + token,
      // },
    })
      .then((response) => {
        if (response.data.success) {
          // toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
    // let token: any = await sessionStorage.getItem('token');
    // if (token) {

    // }
  };
};

export const savePaymentId = (payment_id: any, data: any) => {
  return async (dispatch: any) => {
    let token: any = await sessionStorage.getItem("token");
    Api.post(`/customer/save_payment_id/${payment_id}`, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        if (response.data.success) {
          // toast.error(response.data.successResponse, { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: 3000 });
        }
      })
      .catch((err) => {
        if (err.response) {
          let error;
          if (typeof err.response.data.message === "string") {
            error = err.response.data.message;
          } else if (typeof err.response.data.message === "object") {
            error = err.response.data.message[0].replace(/_/g, " ");
          }
        }
      });
    // let token: any = await sessionStorage.getItem('token');
    // if (token) {

    // }
  };
};
