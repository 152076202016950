import React from 'react'
import AboutUs from '../components/AboutUs'
import Footer from '../components/Footer'
import Header from '../components/Header'
function Aboutus(props:any) {
  return (
    <div className='page-wrapper'>
      <div id="page-wrap">
        <Header history={props.history} />
        <AboutUs />
        <Footer />
      </div>
    </div>
  )
}

export default Aboutus

