import React from "react";
import "./test.css";

const TestQrMode = () => {
  return (
    <div className="top_class d-flex justify-content-center items-center">
      <h1 className="text-center testheading">this is qr test screen</h1>
      <div></div>
    </div>
  );
};

export default TestQrMode;
