import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  signupk,
  loginCustomerWithSocial,
  saveAgreementCheck,
  addUserWithToken,
  ValidateRegistrationOtp,
} from "../../Redux/Actions";
import PhoneInput from "react-phone-input-2";
import { Link, useHistory } from "react-router-dom";
import "../PrivacyPolicy/PrivacyModal.css";

import PasswordStrengthBar from "react-password-strength-bar";
import "./NewSignup.css";
import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import PrivacyModal from "./../PrivacyPolicy/PrivacyModal";
import { useTranslation } from "react-i18next";
var generator = require("generate-password");

function NewSignup(props: any) {
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState("");
  const [address, setAddress] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isValidPass, setIsValidPass] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [repassword, setRePassword] = useState("");
  const [checkField, setCheckField] = useState(false);

  const [validateSignal, setValidateSignal] = useState("");

  const [errorFlag, setErrorFlag] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const [privacyChecked, setPrivacyChecked] = useState(false);

  function toggleHtmlOverflow(hidden: any) {
    document.documentElement.style.overflow = hidden ? "hidden" : "auto";
  }
  const toggleModal = () => {
    setShowModal(!showModal);
    toggleHtmlOverflow(!showModal);
  };

  const handleSavePrivacy = (isChecked: boolean) => {
    props.saveAgreementCheck(isChecked);
    setPrivacyChecked(isChecked);
  };

  const history = useHistory();
  const fistnameHandler = (e: any) => {
    setFirstname(e.target.value);
  };
  const adddressHandler = (e: any) => {
    setAddress(e.target.value);
  };
  const lastNameHandler = (e: any) => {
    setLastName(e.target.value);
  };
  const handleInputChange = (e: any) => {
    if (e.target.name === "password") {
      if (e.target.value.length < 8) {
        setPassword(e.target.value);
        setIsValidPass(false);
      } else {
        setPassword(e.target.value);
        setIsValidPass(true);
      }
    }
    // else {
    //     setPassword(e.target.value)
    //     setIsValidPass(true)
    // }
    if (e.target.name === "repassword") {
      if (e.target.value.length < 8) {
        setRePassword(e.target.value);
        setIsValidPass(false);
      } else {
        setRePassword(e.target.value);
        setIsValidPass(true);
      }
    } else {
      setRePassword(e.target.value);
      setIsValidPass(true);
    }
  };

  const validateEmail = (event: any) => {
    let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
    if (event.target.value != "") {
      if (reg.test(event.target.value)) {
        setEmail(event.target.value);
        setIsValidEmail(true);
      } else {
        setEmail(event.target.value);
        setIsValidEmail(false);
      }
    } else {
      setEmail(event.target.value);
      setIsValidEmail(true);
    }
  };
  const handleOnChange = (value: any) => {
    setPhone(value);
  };

  const checkPhone = (phone: any) => {
    if (phone) {
      // var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      let phoneno = /^(?:\47|3)(?:3)[0-9]{9}$/;
      // var mobileno = /^((\\+92-?)|0)?[0-9]{11}$/;
      // if (phone.match(phoneno)) {
      //     return true;
      // } else {
      //     return false;
      // }
      return true;
    }
  };
  const showStrength = (strength: any) => {};
  const checkPassword = (pwd: any) => {
    if (pwd.length < 8) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmit = () => {
    setErrorFlag(false);
    let str_phone_number = phone;
    if (str_phone_number.charAt(0) === "9") {
      str_phone_number = str_phone_number.substring(2);
      str_phone_number = "0" + str_phone_number;
    }
    if (
      firstname !== "" &&
      lastname !== "" &&
      password !== "" &&
      email !== "" &&
      isValidEmail === true &&
      isValidPass === true &&
      str_phone_number !== ""
    ) {
      const data: any = {
        first_name: firstname,
        last_name: lastname,
        login_name: firstname + " " + lastname,
        email_address: email,
        login_password: password,
        phone_number: str_phone_number,
        address_line_1: address,
        is_active: 1,
        is_guest: 0,
      };
      props.signupk(data);
    } else {
      setErrorFlag(true);
    }
  };
  const isUserReady = () => {
    let validateSignal = false;
    if (
      firstname !== "" &&
      lastname !== "" &&
      password === repassword &&
      email !== "" &&
      isValidEmail === true &&
      isValidPass === true &&
      phone
      // && checkField === true
    ) {
      validateSignal = true;
    }
    // if (phone.length != 12) {
    //     validateSignal = false;
    // }
    // if (!checkPhone(phone)) {
    //     validateSignal = false
    // }
    return validateSignal;
  };
  const checkboxHandler = () => {
    setCheckField(!checkField);
  };

  const handleKeyDown = (e: any) => {
    const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight"];
    if (
      e.target.value.length >= 6 &&
      !allowedKeys.includes(e.key) &&
      e.key !== "Delete"
    ) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <main className="main" style={{ backgroundColor: "#f7f7f7" }}>
        {!props.isOpen && (
          <div className="about-section ">
            <div className="container">
              <p className="signup-heading">{t("signup.create")}</p>
              <div className="row p-1 mt-5">
                <div
                  className="col-lg-12 col-12 p-1 p-md-5"
                  style={{
                    margin: "auto",
                    backgroundColor: "white",
                    borderRadius: "4px",
                    boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <div className="about-details inner-padding-mobile">
                    <div className="heading">
                      <div className="form-row">
                        <div className="form-group col-lg-6 col-md-12 col-12">
                          <h4 className="py-3 my-0">
                            {t("signup.form.fName")}
                          </h4>
                          <input
                            id="round"
                            type="text"
                            className="form-control input-font-size"
                            name="fname"
                            placeholder={t("signup.form.fNamePlchdr")}
                            onChange={(e) => fistnameHandler(e)}
                            required
                            // onChange={this.handleInputChange}
                          />
                        </div>

                        <div className="form-group col-lg-6 col-md-12 col-12">
                          <h4 className="py-3 my-0">
                            {t("signup.form.lName")}
                          </h4>
                          <input
                            id="round"
                            type="text"
                            className="form-control input-font-size"
                            name="lname"
                            placeholder={t("signup.form.lNamePlchdr")}
                            onChange={(e) => lastNameHandler(e)}
                            //onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-lg-6 col-md-12 col-12">
                          <h4 className="py-3 my-0">Email</h4>
                          <input
                            id="round"
                            type="email"
                            name="email"
                            // onChange={this.validateEmail}
                            className="form-control input-font-size"
                            placeholder="Enter Your Email"
                            required
                            onChange={(e) => validateEmail(e)}
                          />
                          {isValidEmail == false && email !== "" && (
                            <small className=" text-danger">
                              Invalid Email
                            </small>
                          )}
                        </div>
                        <div className="form-group col-lg-6 col-md-12 col-12">
                          <h4 className="py-3 my-0">Phone number</h4>
                          <PhoneInput
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            containerClass={"input-2"}
                            country={"no"}
                            disableDropdown={true}
                            onlyCountries={["no"]}
                            countryCodeEditable={false}
                            // // masks={{pk: ' (...) ..-..-...',}}
                            // // prefix={'+'}
                            placeholder={t("signup.form.phonePlchdr")}
                            value={phone}
                            onChange={handleOnChange}
                          />
                          {!checkPhone(phone) && phone !== "" && (
                            <small className=" text-danger">
                              {t("signup.form.invalidPhone")}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-lg-6 col-md-12 col-12">
                          <h4 className="py-3 my-0">Password</h4>
                          <div>
                            <input
                              id="round"
                              type="password"
                              name="password"
                              className="form-control input-font-size"
                              placeholder="Enter Your Password  "
                              required
                              onChange={(e) => handleInputChange(e)}
                            />
                            <PasswordStrengthBar
                              onChangeScore={(score) => {
                                showStrength(score);
                              }}
                              minLength={8}
                              password={password}
                            />
                            {/* <span className='hideicon'><BsEyeSlash /></span> */}
                            {!checkPassword(password) && password !== "" && (
                              <small className=" text-danger">
                                Password must be 8 characters long
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="form-group col-lg-6 col-md-12 col-12 margin-confirm-password">
                          <h4 className="py-3 my-0">Confirm Password</h4>
                          <div>
                            <input
                              id="round"
                              type="password"
                              name="repassword"
                              className="form-control input-font-size"
                              placeholder="Enter Your Confirm Password"
                              required
                              onChange={(e) => handleInputChange(e)}
                            />
                          </div>
                        </div>
                      </div>

                      {props.isChecked ? (
                        <div className="d-grid gap-2 col-12">
                          <button
                            className="btn signup-btn"
                            disabled={!isUserReady()}
                            type="button"
                            onClick={handleSubmit}
                          >
                            Create Account
                          </button>
                        </div>
                      ) : (
                        <div className="d-grid gap-2 col-12">
                          <button
                            className="btn signup-btn"
                            disabled={!isUserReady()}
                            type="button"
                            onClick={toggleModal}
                          >
                            Create Account
                          </button>
                        </div>
                      )}

                      {/* <div className="form-footer">
                                          </div> */}
                      {props.successStatusRegister == "successregister" ? (
                        <div className="alert alert-success alert-dismissible">
                          {/* {props.logink(emailSignup, passwordSignup)} */}
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                          >
                            &times;
                          </button>
                          <strong>{t("signup.form.thanks")}</strong>{" "}
                          {t("signup.form.successMsg")}
                        </div>
                      ) : null}
                      {props.emailExit === true ? (
                        <div className="alert alert-warning">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                          >
                            &times;
                          </button>
                          <strong>{t("signup.form.warning")} </strong>{" "}
                          {t("signup.form.warningMsg")}
                        </div>
                      ) : props.checkField === false ? (
                        <div className="alert alert-danger">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                          >
                            &times;
                          </button>
                          <strong>{t("signup.form.error")} </strong>{" "}
                          {t("signup.form.errorMsg")}
                        </div>
                      ) : null}
                    </div>
                    <p className="text-center">
                      {t("signup.haveAccount")} &nbsp;&nbsp;
                      <Link
                        className="font-weight-bold login_btn"
                        to={{
                          pathname: `/login`,
                          search: window.location.search,
                        }}
                      >
                        {t("signup.btns.login")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {props.isOpen && (
          <div className="about-section">
            <OtpRegister
              email={email}
              login_password={props.login_password}
              ValidateRegistrationOtp={props.ValidateRegistrationOtp}
            />
          </div>
        )}
      </main>
      <PrivacyModal
        showModal={showModal}
        onClose={toggleModal}
        onSave={handleSavePrivacy}
      />
    </div>
  );
}
const OtpRegister = (props: any) => {
  const [otp, setOtp] = useState("");
  const handleOnOTPChange = (e: any) => {
    setOtp(e.target.value);
  };

  const handleSubmitOTP = () => {
    let data = {
      email: props.email,
      otp: otp,
      login_password: props.login_password,
    };
    props.ValidateRegistrationOtp(data);
  };
  return (
    <div
      className="col-md-5 col-lg-4 col-md-offset-5 p-5"
      style={{
        margin: "10rem auto auto auto",
        backgroundColor: "white",
        borderRadius: "24px",
        boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
      }}
    >
      <div className="panel panel-default">
        <div className="panel-body">
          <div className="text-center">
            <h3>
              <i className="fa fa-lock fa-4x"></i>
            </h3>
            <h2 className="text-center subtitle">Enter One Time Password</h2>
            {/* <p>Check your phone number to get OTP code</p> */}
            <div className="login-form-1">
              <input
                id="round"
                value={otp}
                className="form-control"
                style={{ fontSize: "15px" }}
                size={40}
                type="text" // Change the type to "text"
                inputMode="numeric" // Indicate that only numeric input is expected
                pattern="[0-9]*" // Use a regex pattern to allow only numeric input
                placeholder="Enter OTP code"
                maxLength={6}
                name="otp"
                onChange={(e) => handleOnOTPChange(e)}
                required
              />
            </div>
            <br />
            <div className="login-form-1">
              <button
                id="roundb"
                // disabled={!this.isUserReady()}
                onClick={handleSubmitOTP}
                type="button"
                className="btn login-btn"
              >
                Proceed
              </button>

              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    successStatusRegister: state.login.successStatusRegister,
    isOpen: state.login.isOpen,
    emailExit: state.login.emailExit,
    isChecked: state.login.isChecked,
    emailSignup: state.login.emailSignup,
    passwordSignup: state.login.passwordSignup,
    login_password: state.login.login_password,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    signupk: function (data: any) {
      dispatch(signupk(data));
    },
    saveAgreementCheck: function (isChecked: any) {
      dispatch(saveAgreementCheck(isChecked));
    },
    addUserWithToken: (data: any) => {
      dispatch(addUserWithToken(data));
    },
    ValidateRegistrationOtp: function (data: any) {
      dispatch(ValidateRegistrationOtp(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewSignup);
