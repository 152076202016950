import React, { useState, useEffect } from 'react'
import './ordertrack.css'
import { orderDetail } from '../../Redux/Actions';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MailIcon from '@mui/icons-material/Mail';


interface orderDetail {
    orderid: number,
    orderDate: any,
    ordertime: any,
}

interface orderCardDetail {
    itemImage: any,
    itemTile: string,
    itemPrice: string,
    itemdetail1: any,
    itemdeatil2: any
}

interface CustomCardProps {
    title: string,
    detail1: string,
    detail2: string,
    image: string,
    price: string,
}



const OrderTrack = () => {

    const defaultProps = {
        center: {
            lat: 10.99835602,
            lng: 77.01502627
        },
        zoom: 11
    };

    const dummyData: orderDetail[] = [
        {
            orderid: 1878787787,
            orderDate: '2023-11-17',
            ordertime: '14:30',
        },
    ];

    const dummyDataOrder: orderCardDetail[] = [
        {
            itemImage: "salat.jpeg",
            itemTile: "1 Palace Salat",
            itemPrice: "kr 190",
            itemdetail1: '1x Extra sauce',
            itemdeatil2: '1x coke'
        },
        {
            itemImage: "salat.jpeg",
            itemTile: "1 Palace Salat",
            itemPrice: "kr 190",
            itemdetail1: '1x Extra sauce',
            itemdeatil2: '1x coke'
        },
         {
            itemImage: "salat.jpeg",
            itemTile: "1 Palace Salat",
            itemPrice: "kr 190",
            itemdetail1: '1x Extra sauce',
            itemdeatil2: '1x coke'
        },
        {
            itemImage: "salat.jpeg",
            itemTile: "1 Palace Salat",
            itemPrice: "kr 190",
            itemdetail1: '1x Extra sauce',
            itemdeatil2: '1x coke'
        },
    ]

    const [timer, setTimer] = useState('');
    const [loader, setLoader] = useState('');
    const [progress, setProgress] = useState(0);
    const [showTimer, setShowTimer] = useState(false);
    const [timeRemaining, setTimeRemaining] = useState(120);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress < 100) {
                    return prevProgress + 1;
                }
                clearInterval(interval);
                setShowTimer(true);
                return prevProgress;
            });
        }, 20000 / 100);
        return () => {
            clearInterval(interval)
        };
    }, []);
    const handleStartCountdown = () => {
        setProgress(100);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (timeRemaining > 0) {
                setTimeRemaining(timeRemaining - 1);
            } else {
                clearInterval(intervalId);

            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeRemaining]);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    const [response, setResponse] = useState(true);
    const [invoioceToggle, setInvoiceToggle] = useState(false);
    const hanldeInvoiceToggle = () => {
        setInvoiceToggle(!invoioceToggle);
    }

    const [conactModal, setContactModal] = useState(false);
    const conatactClose = () => setContactModal(false);
    const contactOpen = () => setContactModal(true);



    return (
        <div className='container mt-tp'>
            <div className='d-flex flex-row justify-content-start align-items-center mb-2 wrap-back'>
                <i className="bi bi-arrow-left" style={{ fontSize: "20px", marginRight: "10px" }}></i>
                <a href='/'> <span className='back-menu-btn ms-3'>Back to Menu</span></a>
            </div>
            <div className='container container-track'>
                <div className='header-container d-flex flex-column'>
                    <div className='d-flex flex-md-row justify-content-between flex-column'>
                        <div className='d-flex flex-row align-items-center'>
                            <span className='custom-font'>Estimated time remaining for pickup:</span>
                            <div className='request-box'>
                                {progress === 100 && response === true ? (
                                    <span className='font_timer'>
                                        {formattedMinutes}:{formattedSeconds}
                                    </span>
                                ) : (
                                    <span>Request Pending</span>
                                )}
                            </div>
                        </div>
                        <div className='d-md-flex align-items-center d-none'>
                            {
                                progress === 100 && response === true ?
                                    <div className='bell-box' onClick={handleShow}>
                                        <div className='notify-bell'>.</div>
                                        <i className="bi bi-bell" style={{ fontSize: "25px" }} onClick={handleShow}></i>
                                    </div> : ""
                            }
                            <div className='invoice-box'>
                                <i className="bi bi-journals"></i>
                                <span>Invoice</span>
                                <i className="bi bi-chevron-down" onClick={hanldeInvoiceToggle}></i>
                                {
                                    invoioceToggle && (

                                        <div className='invoice-dropdown d-flex flex-column'>
                                            <div className='d-flex align-items-center text-box' style={{ cursor: "pointer" }}>
                                                <MailIcon style={{ fontSize: "20px", color: "black" }} />
                                                <span className='font-dropdown'>Sent to Email</span>

                                            </div>
                                            <div className='d-flex align-items-center text-box' style={{ cursor: "pointer" }}>

                                                <PictureAsPdfIcon style={{ fontSize: "20px", color: "black" }} />
                                                <span className='font-dropdown'>Download</span>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-row order-detail-header flex-wrap'>
                        <p className='order_detail d-md-none py-0 my-0'>Order Order ID: <span>878787877777</span></p>
                        <div className='d-flex d-md-none order-detail-mobile'>
                            <p className='order_detail'>Order Date: <span className='ms-1'> 2023-11-17</span></p>
                            <p className='order_detail'>Order Palaced: <span> 14:30</span></p>
                        </div>
                        <p className='order_detail d-md-flex d-none'>Order Date: <span>2023-11-17</span></p>
                        <p className='order_detail d-md-flex d-none'>Order Palaced: <span>14:30</span></p>
                        <p className='order_detail d-md-flex d-none'>Order Order ID: <span>878787877777</span></p>
                    </div>
                    <div className='d-flex align-items-center d-md-none'>
                        <div className='invoice-box'>
                            <i className="bi bi-journals"></i>
                            <span>Invoice</span>
                            <i className="bi bi-chevron-down" onClick={hanldeInvoiceToggle}></i>
                            {
                                invoioceToggle && (

                                    <div className='invoice-dropdown d-flex flex-column'>
                                        <div className='d-flex align-items-center text-box' style={{ cursor: "pointer" }}>
                                            <MailIcon style={{ fontSize: "20px", color: "black" }} />
                                            <span className='font-dropdown'>Sent to Email</span>

                                        </div>
                                        <div className='d-flex align-items-center text-box' style={{ cursor: "pointer" }}>

                                            <PictureAsPdfIcon style={{ fontSize: "20px", color: "black" }} />
                                            <span className='font-dropdown'>Download</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        {
                            progress === 100 && response === true ?
                                <div className='bell-box' onClick={handleShow}>
                                    <div className='notify-bell'>.</div>
                                    <i className="bi bi-bell" style={{ fontSize: "25px" }} onClick={handleShow}></i>
                                </div> : ""
                        }

                    </div>
                </div>
                <hr className='text-black mx-0 ' />
                <div className='d-flex flex-column'>

                    {progress === 100 && response === true ? (
                        <CustomTimerbar />
                    ) : (
                        <div className='d-flex justify-content-center flex-column align-items-center mx-5'>
                            <h1 className='payment-head mb-0 pb-2 pt-md-4 pt-5'>Payment Successful</h1>
                            <p className='payment-desc py-0'>Waiting for time confirmation from the kitchen</p>
                            <ProgressBar now={progress} className='w-100 loading-bar' />
                        </div>
                    )}

                    {progress === 100 && response === false ?
                        <div className='d-flex flex-row justify-content-center align-items-center links-row'>
                            <a href='/' className='links'>Need more Info ?</a>
                            <button onClick={contactOpen} className='links contact-link btn-contact'>Contant us</button>
                        </div> : ""
                    }

                    <div className='d-flex flex-column order-card-section'>
                        {
                            dummyDataOrder.map((item, index) => (
                                <CutomCard title={item.itemTile} detail1={item.itemdetail1}
                                    detail2={item.itemdeatil2} price={item.itemPrice} image={item.itemImage} />
                            ))
                        }
                    </div>

                </div>
                <hr className='mx-0 hr-line' />
                <div className='payment-detail d-flex flex-column justify-content-around'>
                    <h1 className='payment-heading'>Payment details</h1>
                    <div className='d-flex flex-row justify-content-between'>
                        <p className='right-details'>Items total</p>
                        <p className='left-details'>kr 113</p>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <p className='right-details'>Vat <sub className='sub-text'>(15%)</sub></p>
                        <p className='left-details'>kr 29</p>
                    </div>
                    <div className='d-flex flex-row justify-content-between'>
                        <p className='right-bold'>Total</p>
                        <p className='left-bold'>kr 160</p>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header>
                    <Modal.Title>Notification</Modal.Title>
                    <div className='close-btn'> <i className="bi bi-x-lg font-weight-bold"></i></div>
                </Modal.Header>
                <Modal.Body className='main-modal'>

                    <div className='card-modal d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center '>
                            <i className="bi bi-bell" style={{ fontSize: "18px", marginBottom: "10px", marginRight: "15px" }}></i>
                            <p className='payment-heading'>Time updates</p>
                        </div>
                        <div className='mt-md-0 mt-1'>
                            <p className='left-details'>Due to some inconvenience we had to update order pickup time .</p>
                        </div>
                        <div className="d-flex flex-md-row flex-column justify-content-between mt-md-0 mt-1">
                            <div className='d-flex flex-row gap-5 modal-box'>
                                <p className='modal-box'>Previous pickup time:</p>
                                <p className='modal-box-p'>2:20 pm</p>
                            </div>
                            <div className='d-flex flex-row gap-5 modal-box '>
                                <p className='modal-box'>Previous pickup time:</p>
                                <p className='modal-box-p1'>2:20 pm</p>
                            </div>
                        </div>
                    </div>
                    <hr className='w-100 text-black' />
                </Modal.Body>
            </Modal>

            <Modal show={conactModal} onHide={conatactClose}
                centered>
                <Modal.Header>
                    <Modal.Title>Contact Informaction</Modal.Title>
                    <div className='close-btn'> <i className="bi bi-x-lg font-weight-bold"></i></div>
                </Modal.Header>
                <Modal.Body className='main-modal contact-modal'>
                    <div className='d-flex flex-column'>
                        <div className='d-flex flex-row justify-content-between contact-section-padding'>
                            <span className='left-text-contact'>Number</span>
                            <span className='right-text-contact'>123456</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between contact-section-padding'>
                            <span className='left-text-contact'>Email</span>
                            <span className='right-text-contact'>example@email.com</span>
                        </div>
                        <div className='d-flex flex-row justify-content-between contact-section-padding'>
                            <span className='left-text-contact'>Address</span>
                            <span className='right-text-contact'>example address </span>
                        </div>
                    </div>
                    <hr className='w-100 text-black' />
                    <div className='contact-section-padding'>
                        <img src={process.env.PUBLIC_URL + "/map.png"} alt='map image' />
                    </div>
                </Modal.Body>
            </Modal>


        </div >
    )
}


const CutomCard = ({ title, detail1, detail2, image, price }: CustomCardProps) => {
    return (
        <div className='d-flex flex-row justify-content-between align-items-center detail-card-container'>
            <div className='d-flex flex-row justify-content-center'>
                <img src={process.env.PUBLIC_URL + image} className='card-image'
                    alt='item_image' />
                <div className='d-flex flex-column justify-content-between card-main'>
                    <span className='detail-title'>{title}</span>
                    <div className='d-flex flex-row'>
                        <p className='detail-card'>{detail1} | </p>&nbsp;
                        <p className='detail-card'> {detail2}</p>
                    </div>
                </div>
            </div>
            <div className='d-flex flex-column justify-content-between'>
                <h1 className='detail-price'>{price}</h1>
                <p className='detail-item-price'>{price}</p>
            </div>
        </div>
    )
}


const CustomWaitTimer = () => {
    const [dotClasses, setDotClasses] = useState(['activedot', '', '', '']);
    const [lineWidth, setLineWidth] = useState(33);
    const [activeDots, setActiveDots] = useState(0);
    const [lineColor, setLineColor] = useState('#D0D5DD');
    const [progressCompleted, setProgressCompleted] = useState(false);
    useEffect(() => {
        const timeoutIds: NodeJS.Timeout[] = [];

        timeoutIds.push(setTimeout(() => updateDots(2), 5000));
        timeoutIds.push(setTimeout(() => updateDots(3), 10000));
        timeoutIds.push(setTimeout(() => updateDots(4), 15000));
        timeoutIds.push(setTimeout(() => setProgressCompleted(true), 20000)); // Set progress completion after 20 seconds



        return () => {
            timeoutIds.forEach(clearTimeout);

        };
    }, [progressCompleted]);

    const updateDots = (activeDotCount: any) => {
        const newDotClasses = Array.from({ length: 4 }, (_, index) => (index < activeDotCount ? 'activedot' : ''));
        setDotClasses(newDotClasses);
        setLineColor(activeDotCount === 4 ? '#4F8279' : '#D0D5DD');
        setLineWidth((activeDotCount / 4) * 100);
    };


    return (
        <div className={`d-flex flex-column mb-1 mt-2`}>
            <div className='d-flex justify-content-between' style={{ padding: "0px 10px" }}>
                <p className='timer-heading timer-active-heading'>Order Confirmed</p>
                <p className='timer-heading'>Prepraing your order</p>
                <p className='timer-heading'>Order is ready at resturent</p>
                <p className='timer-heading'>Order Completed</p>
            </div>
            <div className='position-relative mt-2 mb-2'>
                <div className='line-bar' style={{ width: `${lineWidth}%`, backgroundColor: lineColor, maxWidth: '720px' }}></div>
                <div className='dots d-flex justify-content-between align-items-center'>
                    {dotClasses.map((className, index) => (
                        <div key={index} className={`dot-single ${className}`}>.</div>
                    ))}

                </div>
            </div>
            {/* <div className='d-flex justify-content-between' style={{ padding: "0px 10px" }}>
                <p className='timer-heading'>2:00 pm</p>
                <p className='timer-heading'>2:05 pm</p>
                <p className='timer-heading'>2:15 pm</p>
                <p className='timer-heading'>2:20 pm</p>
            </div> */}
        </div>
    )
}


const CustomTimerbar = () => {
    const [progress, setProgress] = useState(0);
    const [activeDots, setActiveDots] = useState(['activedot', '', '', '']);


    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => (prevProgress < 100 ? prevProgress + (100 / 120) : 100));
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    useEffect(() => {
        const activeIndex = Math.floor(progress / 33.33);
        setActiveDots((prevDots) =>
            prevDots.map((_, index) => (index <= activeIndex ? 'activedot' : ''))
        );
    }, [progress]);

    return (
        progress === 100 ? 
          <div className="d-flex flex-column mb-1 mt-2 timer-main-container align-items-center completed-container">
            <h4 className='order-complete-heading'>Your Order is Completed</h4>
            <a href="/order" className='order-complete-btn'>Go to My Orders </a>
          </div>
         : 
          <div className='d-flex flex-column mb-1 mt-2 timer-main-container'>
              <div className='d-flex justify-content-between timer-container' style={{ padding: "0px 10px" }}>
                  <p className={`timer-heading ${activeDots[0] ? 'timer-active-heading' : ''}`}>Order Confirmed</p>
                  <p className={`timer-heading ${activeDots[1] ? 'timer-active-heading' : ''}`}>Preparing your order</p>
                  <p className={`timer-heading ${activeDots[2] ? 'timer-active-heading' : ''}`}>Order is ready at restaurant</p>
                  <p className={`timer-heading ${activeDots[3] ? 'timer-active-heading' : ''}`}>Order Completed</p>
              </div>
              <div className='position-relative mt-2 mb-2'>
                  <ProgressBar now={progress} className='h-1' />
                  <div className="dots d-flex justify-content-between align-items-center">
                      {activeDots.map((className, index) => (
                          <div key={index} className={`dot-single ${className}`}>
                              .
                          </div>
                      ))}
                  </div>
              </div>
          </div>
      )
                      }
export default OrderTrack