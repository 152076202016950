import React from "react";
import "./modeBar.css";
const ModeBar = (props: any) => {
  return (
    <div className="md-language-bar">
      <div className="md-container">
        <button
          disabled={!props.mode}
          onClick={() => props.handleModeClick("Dinein", true)}
          className="primary mode-button"
          style={{
            borderRight: "none",
            borderTopRightRadius: "0px",
            borderBottomRightRadius: "0px",
          }}
        >
          Dine In
        </button>
        <button
          disabled={props.mode}
          onClick={() => props.handleModeClick("Pickup", false)}
          className="primary mode-button"
          style={{
            borderLeft: "none",
            borderTopLeftRadius: "0px",
            borderBottomLeftRadius: "0px",
          }}
        >
          Pickup
        </button>
      </div>
    </div>
  );
};
export default ModeBar;
