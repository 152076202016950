import React, { useState, useEffect, useRef } from "react";
import { format, parse, isWithinInterval, parseISO } from "date-fns";
import "./header.css";
import {
  cartToggle,
  emptyFilteredItems,
  featuredProducts,
  getStoreById,
  menuGroupsListHome,
  saveOrderType,
  saveSelectStoreId,
  saveStore,
  saveurlMethod,
  searchItems,
  setTabName,
  TopItemsList,
  TopList,
  UpdateMenuItems,
} from "../../Redux/Actions/index";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  logoutCustomer,
  menuGroupsList,
  menuItemsListByGroupsID,
  getCart,
  saveCart,
} from "../../Redux/Actions/index";
import ProfileToggler from "../ProfileToggler";
import { Link } from "react-router-dom";
import MobileProfile from "../mobile-porfile/Mobile-profile";
import BannerModal from "../BannerModal/BannerModal";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import { BiSearch } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { MdOutlineShoppingCart } from "react-icons/md";
import moment from "moment";
import {
  cartOpenModal,
  isSideBar,
  searchState,
  setIsOpen,
  setTimeOver,
  setCloseBefore,
  setCloseTime,
} from "../../Redux/Reducers/searchReducer";
import CartToggler from "../CartToggler/CartToggler";
import { RootState } from "../../Redux/store";
import { FaFontAwesome } from "react-icons/fa";
import Sidebar from "../Sidebar";
import CloseHeader from "./CloseHeader";
import MenuIcon from "@mui/icons-material/Menu";
import OutsideClickHandler from "react-outside-click-handler";
import { isCartQuantity } from "../../Redux/Reducers/searchReducer";

function NewHeader(props: any) {
  const { i18n, t } = useTranslation();
  const searchTerm = useSelector(
    (state: RootState) => state.search.isSearching
  );
  const cartOpening = useSelector((state: RootState) => state.search.cartModal);
  const cartQuantity = useSelector(
    (state: RootState) => state.search.cartQuantity
  );
  const cartData = useSelector((state: RootState) => state.login.cartData);
  const sideBar = useSelector((state: RootState) => state.search.SideBar);
  const timerOver = useSelector((state: RootState) => state.search.timerOver);
  const startTimeStore = useSelector(
    (state: RootState) => state.search.storeStart
  );
  const closeTimeStore = useSelector(
    (state: RootState) => state.search.storeClose
  );
  const closeBefore = useSelector(
    (state: RootState) => state.search.storeClose
  );
  const closeTime = useSelector((state: RootState) => state.search.CloseTime);
  // console.log("cart quantity", cartData);
  const [pickupOpen, setPickupOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(searchTerm);
  const [search, setSearch] = useState("     ");
  const [address, setAddress] = useState("");
  const [appMenu, openAppMenu] = useState(false);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const currentTime = new Date().toLocaleTimeString("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //   });

  //   // Convert start and close times to comparable formats
  //   const startTime = new Date(`2023-01-01 ${startTimeStore}`);
  //   const closeTime = new Date(`2023-01-01 ${closeTimeStore}`);
  //   const currentTimeDate = new Date(`2023-01-01 ${currentTime}`);

  //   // Check if current time is within the range
  //   const isWithinTimeRange =
  //     currentTimeDate >= startTime && currentTimeDate < closeTime;

  //   // Set the timeOver state based on the condition
  //   dispatch(setTimeOver(!isWithinTimeRange));
  // }, [startTimeStore, closeTimeStore]);

  useEffect(() => {
    const currentFormattedTime = moment();
    const parsedStartTime = moment(startTimeStore, "hh:mm A");
    const parsedCloseTime = moment(closeTimeStore, "hh:mm A");

    console.log("currentFormattedTime", currentFormattedTime.format("hh:mm A"));
    console.log("parsedStartTime", parsedStartTime.format("hh:mm A"));
    console.log("parsedCloseTime", parsedCloseTime.format("hh:mm A"));

    const isWithinTimeRange =
      currentFormattedTime.isSameOrAfter(parsedStartTime) &&
      currentFormattedTime.isSameOrBefore(parsedCloseTime);

    console.log("isWithinTimeRange", isWithinTimeRange);
    dispatch(setTimeOver(!isWithinTimeRange));
  }, [startTimeStore, closeTimeStore, dispatch]);

  // useEffect(() => {
  //   const currentTime = new Date().toLocaleTimeString("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     hour12: true,
  //   });

  //   const closeTime = new Date(`2023-01-01 ${closeTimeStore}`);
  //   const currentTimeDate = new Date(`2023-01-01 ${currentTime}`);
  //   const timeDifferenceInMinutes =
  //     ((closeTime as any) - (currentTimeDate as any)) / (1000 * 60);
  //   if (timeDifferenceInMinutes <= 20) {
  //     dispatch(setCloseTime(true));
  //   }
  // }, [closeTimeStore, timerOver]);

  useEffect(() => {
    const currentTime = moment().format("hh:mm A");
    const closeTime = moment(closeTimeStore, "hh:mm A");

    const timeDifferenceInMinutes = closeTime.diff(
      moment(currentTime, "hh:mm A"),
      "minutes"
    );

    console.log("currentTime", currentTime);
    console.log("closeTime", closeTime.format("hh:mm A"));
    console.log("timeDifferenceInMinutes", timeDifferenceInMinutes);

    if (timeDifferenceInMinutes <= 20) {
      dispatch(setCloseTime(true));
    }
  }, [closeTimeStore, dispatch]);

  useEffect(() => {
    if (timerOver === true) {
      dispatch(setCloseTime(false));
    }
  });

  // console.log("here is timeover", timerOver)
  // console.log("here is closetime",closeTime)
  // console.log("here is store opentime", startTimeStore)
  // console.log("here is store opentime", closeTimeStore)

  useEffect(() => {
    let area_details: any = sessionStorage.getItem("area_details");
    if (area_details) {
      setAddress(area_details);
    }
    let v = "";
    let doc = document as any;
    if (pickupOpen) {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "hidden")
        : (v = "");
    } else {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "auto")
        : (v = "");
    }
  }, [pickupOpen]);

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    if (e.target.value == "") {
      props.emptyFilteredItems();
    } else {
      props.searchItems({
        text: e.target.value,
        store_id: null,
        data: props.groupsData,
      });
    }
  };
  const openAppModel = () => {
    openAppMenu(!appMenu);
  };

  const openCartModal = () => {
    dispatch(cartOpenModal(true));
    console.log("here is carttoggle", cartOpening)
   
  };

  const openSidebar = (e: any) => {
    dispatch(isSideBar(!sideBar));
    e.stopPropagation();
    // dispatch(setIsOpen(true))
    console.log("values form header", sideBar);
  };

  const closeSidebar = () => {
    dispatch(isSideBar(false));
  };
  const toggleSearch = () => {
    // setIsSearch(!isSearch);
    dispatch(searchState(!searchTerm));
  };
  const changeLang = (lang: any) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("locale", lang);
  };

  const [cartState, setCartState] = useState<number>(0);

  useEffect(() => {
    props.emptyFilteredItems();
  }, [window.location.pathname != "/"]);

  useEffect(() => {
    setSearch("");
    props.emptyFilteredItems();
  }, [pickupOpen]);

  let route = window.location.pathname;

  useEffect(() => {
    let store = sessionStorage.getItem("storeSelected");
    let parsedStore: any = store ? JSON.parse(store) : null;
    if (parsedStore) {
      props.saveStore(parsedStore);
    }
    if (pickupOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    var { groupList } = props;
    if (groupList.length == 0) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const store_id = urlParams.get("store_id");
      const mode = urlParams.get("md");

      if (!store_id && !mode && mode !== "Qr-Pickup") {
        sessionStorage.removeItem("store_id_qr");
        let storeSelected: any = sessionStorage.getItem("storeSelected");
        if (storeSelected) {
          let orderType: any = sessionStorage.getItem("orderType");
          let parsedStore: any = JSON.parse(storeSelected);
          props.saveStore(parsedStore);
          if (orderType) {
            props.saveOrderType(orderType);
          }
          props.saveSelectStoreId(parsedStore);
          props.menuGroupsListHome(parsedStore.store_id);
          props.menuGroupsList(parsedStore.store_id);
          props.featuredProducts(parsedStore.store_id);
          props.TopItemsList(parsedStore.store_id);
        } else {
          props.menuGroupsListHome(null);
          props.menuGroupsList(null);
          props.featuredProducts(null);
          props.TopItemsList(null);
          saveStoreAndMode();
        }
      } else {
        saveQrStoreAndMode();
      }
    }
    if (pickupOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const saveQrStoreAndMode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const store_id = urlParams.get("store_id");
    const mode = urlParams.get("md");
    if (store_id && mode) {
      props.getStoreById(store_id, mode);
      props.saveOrderType(mode);
      sessionStorage.setItem("orderType", mode);
      sessionStorage.setItem("store_id_qr", store_id);
    }
  };
  const saveStoreAndMode = () => {
    props.getStoreById(6, "");
  };

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("guestphone");
    localStorage.removeItem("orderId");
    sessionStorage.removeItem("tabNumber");

    window.location.href = "/";
  };
  let firstName = "";
  let lastName = "";
  let email = "";
  let token: any = sessionStorage.getItem("token");
  let storeSelected: any = sessionStorage.getItem("storeSelected");
  if (storeSelected) {
    storeSelected = JSON.parse(storeSelected);
  }
  if (token) {
    let user: any = jwtDecode(token);
    firstName = user.customer && user.customer.first_name;
    lastName = user.customer && user.customer.last_name;
    email = user.customer && user.customer.email_address;
  }
  const renderCartButton = (data: any) => {
    if (data.length != 0) {
      let cart: any = JSON.parse(data);
      let totalItemQuantity = 0;

      cart.map((item: any) => {
        totalItemQuantity += item.quantity;
      });

      if (totalItemQuantity !== cartState) {
        // Update the state after calculating totalItemQuantity
        setCartState(totalItemQuantity);
      }

      return (
        <>
          <span className="header-qty mr-2 mt-1">{totalItemQuantity} </span>
          <span className="header-qty mt-1">{" items"} </span>
        </>
      );
    }
  };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const checkMobileScreen = () => {
      setIsMobile(window.innerWidth <= 767); // Adjust the width threshold as needed
    };

    checkMobileScreen();
    window.addEventListener("resize", checkMobileScreen);
    return () => {
      window.removeEventListener("resize", checkMobileScreen);
    };
  }, []);

  return (
    <>
      <header className="web-header">
        <div
          style={{ height: "-webkit-fill-available" }}
          className="position-relative"
        >
          {isMobile ? (
            <Sidebar />
          ) : (
            <OutsideClickHandler onOutsideClick={closeSidebar}>
              <div className="sidebar_header_container">
                <Sidebar />
              </div>
            </OutsideClickHandler>
          )}
          <div className="" style={{ margin: "0px 5%" }}>
            <div
              className="row w-100 head-position "
              style={{ margin: "auto", maxWidth: "1200px" }}
            >
              <div className="col-4 p-0" style={{ marginTop: "26px" }}>
                <div className="menu-btn-container" onClick={openSidebar}>
                  <MenuIcon style={{ fontSize: "22px" }} />
                </div>

                <div className="header-btns">
                  <span
                    className="Menu-icon"
                    style={{ color: "#364D49" }}
                  ></span>
                  {/* <div className="d-xl-block d-lg-block d-md-none d-sm-none d-none">
                  <div className="d-flex">
                  {!sessionStorage.getItem("token") ? (
                    <>
                      <Link to={"/signup"} className=""
                      style={{
                        color: "#FFF",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 400,
                        lineHeight: "normal",
                        fontFamily: "Glacial Indifference",
                        width: "66px",
                        margin: "11.5px 0 0 26px"
                      }}
                      >
                        Sign up
                      </Link>
                      <Link to={"/login"} className="btn btn-secondary login"  style={{
                        marginLeft: "10px"
                      }}>
                        Login
                      </Link>
                    </>
                  ) : (
                    <></>
                  )}
                  </div>
                  </div> */}
                </div>
              </div>
              <div className="col-4 align-logo" style={{ marginTop: "26px" }}>
                <Link
                  to={{
                    pathname: "/",
                    search: window.location.search,
                  }}
                >
                  <img
                    className=""
                    style={{
                      height: "48.432px",
                      width: "250px",
                      background:
                        "url(process.env.PUBLIC_URL + /logo192.png), lightgray 0px 0px / 100% 100% no-repeat",
                      objectFit: "contain",
                    }}
                    src={process.env.PUBLIC_URL + "/logo192.png"}
                    alt="Palace Cafe Logo"
                  />
                </Link>
              </div>
              <div className="col-4" style={{ marginTop: "26px" }}>
                {sessionStorage.getItem("token") ? (
                  <div className="wh-private-icons-container">
                    <div className="row ">
                      <span
                        className="header-icon shopping-cart mr-3"
                        onClick={() =>
                          props.cartToggleAction(
                            props.cartToggle == true ? false : true
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="15"
                          viewBox="0 0 19 15"
                          fill="none"
                        >
                          <path
                            d="M7.14686 14.4997C7.52407 14.4997 7.82986 14.2059 7.82986 13.8436C7.82986 13.4812 7.52407 13.1875 7.14686 13.1875C6.76965 13.1875 6.46387 13.4812 6.46387 13.8436C6.46387 14.2059 6.76965 14.4997 7.14686 14.4997Z"
                            fill="#364D49"
                            stroke="#364D49"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M15.4447 14.4997C15.8219 14.4997 16.1277 14.2059 16.1277 13.8436C16.1277 13.4812 15.8219 13.1875 15.4447 13.1875C15.0675 13.1875 14.7617 13.4812 14.7617 13.8436C14.7617 14.2059 15.0675 14.4997 15.4447 14.4997Z"
                            fill="#364D49"
                            stroke="#364D49"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 1H3.73198L5.78097 12.1533H16.7089"
                            stroke="#364D49"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.78103 9.52852H16.4289C16.5079 9.52858 16.5845 9.50233 16.6456 9.45426C16.7067 9.40619 16.7485 9.33926 16.764 9.26486L17.9934 3.36019C18.0033 3.31258 18.0021 3.26344 17.9899 3.21633C17.9776 3.16922 17.9547 3.12531 17.9226 3.08777C17.8905 3.05023 17.8502 3.01999 17.8045 2.99924C17.7588 2.97849 17.7089 2.96774 17.6583 2.96777H4.41504"
                            stroke="#364D49"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      {renderCartButton(props.cart)}
                    </div>
                  </div>
                ) : (
                  <div
                    className="wh-private-icons-container"
                    style={{ alignItems: "baseline" }}
                  >
                    {window.location.pathname == "/chackout" ||
                    window.location.pathname == "/payment" ? (
                      ""
                    ) : (
                      <div className="row">
                        <span
                          className="header-icon shopping-cart mr-3"
                          onClick={() =>
                            props.cartToggleAction(
                              props.cartToggle == true ? false : true
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="15"
                            viewBox="0 0 19 15"
                            fill="none"
                          >
                            <path
                              d="M7.14686 14.4997C7.52407 14.4997 7.82986 14.2059 7.82986 13.8436C7.82986 13.4812 7.52407 13.1875 7.14686 13.1875C6.76965 13.1875 6.46387 13.4812 6.46387 13.8436C6.46387 14.2059 6.76965 14.4997 7.14686 14.4997Z"
                              fill="#364D49"
                              stroke="#364D49"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M15.4447 14.4997C15.8219 14.4997 16.1277 14.2059 16.1277 13.8436C16.1277 13.4812 15.8219 13.1875 15.4447 13.1875C15.0675 13.1875 14.7617 13.4812 14.7617 13.8436C14.7617 14.2059 15.0675 14.4997 15.4447 14.4997Z"
                              fill="#364D49"
                              stroke="#364D49"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M1 1H3.73198L5.78097 12.1533H16.7089"
                              stroke="#364D49"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M5.78103 9.52852H16.4289C16.5079 9.52858 16.5845 9.50233 16.6456 9.45426C16.7067 9.40619 16.7485 9.33926 16.764 9.26486L17.9934 3.36019C18.0033 3.31258 18.0021 3.26344 17.9899 3.21633C17.9776 3.16922 17.9547 3.12531 17.9226 3.08777C17.8905 3.05023 17.8502 3.01999 17.8045 2.99924C17.7588 2.97849 17.7089 2.96774 17.6583 2.96777H4.41504"
                              stroke="#364D49"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        {renderCartButton(props.cart)}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <hr style={{ margin: "0px" }}></hr> */}
        {(window.location.pathname === "/" && timerOver) || closeTime ? (
          <CloseHeader />
        ) : (
          ""
        )}
      </header>

      <CartToggler
        isOpenMenu={props.cartToggle}
        history={props.history}
        setTableNumber={function (): {} {
          throw new Error("Function not implemented.");
        }}
        activeHours={undefined}
        timeOptions={undefined}
        openTime={undefined}
        closetime={undefined}
      />
      <header className="mobile-header">
        <div
          className="row justify-content-between"
          style={{
            padding: "15px 0px",
            marginLeft: "5px",
            alignItems: "center",
          }}
        >
          <div
            className={`col-1 position-relative`}
            style={{ marginLeft: "5px" }}
          >
            <i
              className="fa-solid fa-bars custom_cart_icon"
              onClick={openSidebar}
            ></i>
          </div>
          <div className="col-6 header_logo_custom">
            <Link to={{ pathname: "/", search: window.location.search }}>
              <img
                // className="header-logo"
                src={process.env.PUBLIC_URL + "/logo192.png"}
                alt="Palace Cafe Logo"
                height={"80px"}
                width={"200px"}
              />
            </Link>
          </div>
          

          <div className={`col-2 position-relative`}>
            <span className="cart_quantity">{cartState}</span>

            <i
              className="fa-solid fa-cart-shopping custom_cart_icon"
              onClick={openCartModal}
              // onClick={() =>
              //   props.cartToggleAction(
              //     props.cartToggle == true ? false : true
              //   )
              // }
            ></i>
          </div>

          {sideBar && <Sidebar />}

          
        </div>
        {window.location.pathname == "/" && searchTerm ? (
          <div className="mobile-header-search">
            <form
              action="#"
              method="get"
              autoComplete="off"
              className="form-extra-margin"
            >
              <span className="search-icon">
                <BiSearch />
              </span>
              <input
                onChange={handleSearch}
                type="search"
                className="form-control input-mobile-header "
                name=""
                id=""
                autoComplete="false"
                required
              />
            </form>
          </div>
        ) : (
          ""
        )}
        {(window.location.pathname === "/" && timerOver) || closeTime ? (
          <CloseHeader />
        ) : (
          ""
        )}
      </header>
      <BannerModal openBanner={appMenu} onCloseBanner={openAppModel} />
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    groupList: state.login.groupList,
    cart: state.login.cartData,
    GroupName: state.login.GroupName,
    groupsData: state.login.groupsData,
    storeSelected: state.login.storeSelected,
    cartToggle: state.login.cartToggle,
    orderType: state.login.orderType,
    cityName: state.login.cityName,
    tradeZoneName: state.login.tradeZoneName,
    tradeAreaName: state.login.tradeAreaName,
    addressData: state.login.addressData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutCustomer: function () {
      dispatch(logoutCustomer());
    },
    getCart: function (history?: any) {
      dispatch(getCart(history));
    },
    menuGroupsList: function (store_id: any) {
      dispatch(menuGroupsList(store_id));
    },
    menuItemsListByGroupsID: function (data: any) {
      dispatch(menuItemsListByGroupsID(data));
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    saveurlMethod: function (url: any) {
      dispatch(saveurlMethod(url));
    },
    UpdateMenuItems: function (data: any) {
      dispatch(UpdateMenuItems(data));
    },
    TopList: function (store_id: any) {
      dispatch(TopList(store_id));
    },
    menuGroupsListHome: function (store_id: any) {
      dispatch(menuGroupsListHome(store_id));
    },
    TopItemsList: function (store_id: any) {
      dispatch(TopItemsList(store_id));
    },
    getStoreById: function (store_id: any, mode: any) {
      dispatch(getStoreById(store_id, mode));
    },
    saveOrderType: function (type: any) {
      dispatch(saveOrderType(type));
    },
    cartToggleAction: (toggle: any) => {
      dispatch(cartToggle(toggle));
    },
    featuredProducts: (store_id: any) => {
      dispatch(featuredProducts(store_id));
    },
    saveStore: (store: any) => {
      dispatch(saveStore(store));
    },
    saveSelectStoreId: (store: any) => {
      dispatch(saveSelectStoreId(store));
    },
    searchItems: (data: any) => {
      dispatch(searchItems(data));
    },
    emptyFilteredItems: () => {
      dispatch(emptyFilteredItems());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
