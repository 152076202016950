import React, { useContext, useEffect } from 'react';
import { NavContext } from '../context/NavContext';
import { RootState } from '../../../Redux/store';
import './Nav.css'
import { Link, animateScroll as scroll } from 'react-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { setCategory } from '../../../Redux/Reducers/searchReducer';

const NavLinkNew = ({ navLinkId, scrollToId,id }) => {
  const { activeNavLinkId, setActiveNavLinkId } = useContext(NavContext);
  const dispatch = useDispatch();

  useEffect(() => {
    const isSmallDevice = window.innerWidth < 600;
    if (!activeNavLinkId && isSmallDevice) {
      setActiveNavLinkId('Salater');
    }
  }, [activeNavLinkId, setActiveNavLinkId]);

  const handleClick = () => {
    setActiveNavLinkId(navLinkId);
   
    dispatch(setCategory(scrollToId));
    document.getElementById(scrollToId).scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

 



  const checkSectionInView = () => {
    const section = document.getElementById(scrollToId);
    if (section) {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
        setActiveNavLinkId(navLinkId);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkSectionInView);
    return () => {
      window.removeEventListener('scroll', checkSectionInView);
    };
  }, []);

  const defaultId = "Salater";

  return (
    <div className={`inner-single-category`} >
      <strong>
        <span
          id={navLinkId}
          className={activeNavLinkId   === navLinkId ? 'activeClass' : ''}
          onClick={handleClick}
          style={{ color: 'white', maxWidth: '100%', display: 'flex', fontSize: 'large' }}
        >
          <div className='font_size_small custom_font_onmobile' style={{ fontSize: '20px', fontFamily: 'Playfair Display', fontWeight: '500', fontStyle: 'normal', letterSpacing: '-0.5px', lineHeight: '32px' }}>{navLinkId}</div>
        </span>
      </strong>
    </div>
  );
};

export default NavLinkNew;
