import React, { Component, RefObject, useState } from "react";
import moment from "moment";
import NumericInput from "react-numeric-input";
import "./cartToggler.css";
import { CartkProps } from "../Interface/loginInterface";
import { MdOutlineShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import jwt_decode from "jwt-decode";
import Api from "../../ApiManager/index";
import OutsideClickHandler from "react-outside-click-handler";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/material/Box";
import TimeupModal from "./TimeupModal";
import EastIcon from "@mui/icons-material/East";
import { Modal } from "@mui/material";

import {
  SaveDiscountValue,
  applyCoupon,
  cartToggle,
  getCart,
  handleSpecialInstruction,
  resetCoupon,
  saveCart,
  saveOrderDateTime,
  saveOrderType,
  saveTaxRate,
  setTabName,
} from "../../Redux/Actions/index";
import { connect } from "react-redux";
import _ from "lodash";
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import ConfirmLocationModal from "../ConfirmLocationModal";
// import { Modal } from "react-responsive-modal";
import ModeBar from "../modeBar";
import { useSelector } from "react-redux";
import {
  setTimeOrder,
  setStoreClose,
  setStoreStart,
  setOpenTimerModal,
  setOrderTime,
} from "../../Redux/Reducers/searchReducer";
import {
  cartOpenModal,
  isCartQuantity,
  setTimeOver,
} from "../../Redux/Reducers/searchReducer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 0,
  px: 0,
  pb: 0,
};

class CartToggler extends Component<
  CartkProps,
  {
    coupon_code: any;
    discountFlag: boolean;
    pickupOpen: boolean;
    orderType: any;
    tabNumber: any;
    taxRate: any;
    futureDate: any;
    futureTime: any;
    openModel: any;
    options: any;
    mode: any;
    setTable: any;
    isSticky: boolean;
    toggleActive: Boolean;
    setTime: any;
    isHomePath: any;
    websiteHight: number;
    bgState: boolean;
    isFooterVisible: boolean;
    totalHeight: number;
    Width: number;
    Height: number;
    cartOpening: boolean;
    activeHours: { start: number; end: number };
    timeOptions: any;
    openTime: any;
    closetime: any;
    closeBefore: boolean;
    closeModal: boolean;
    OrderTypeState: any;
  }
> {
  itemTotal: any;
  tax: any;
  codeExeCount: number;
  discount: any;
  quantity: any;
  totalTax: any;
  couponDiscount: any;
  cartTotal: any;
  appliedCoupon: boolean;
  constructor(props: any) {
    super(props);
    this.state = {
      coupon_code: "",
      discountFlag: false,
      pickupOpen: false,
      orderType: "",
      tabNumber: 0,
      futureDate: new Date(),
      futureTime: "",
      taxRate: 0,
      openModel: false,
      options: [],
      mode: true,
      setTable: true,
      isSticky: false,
      toggleActive: false,
      setTime: "",
      isHomePath: "/",
      websiteHight: window.innerHeight,
      bgState: false,
      isFooterVisible: false,
      totalHeight: document.documentElement.scrollHeight,
      Width: 0,
      Height: 0,
      cartOpening: false,
      activeHours: {
        start: 8,
        end: 18,
      },
      timeOptions: [],
      openTime: "",
      closetime: "",
      closeBefore: false,
      closeModal: false,
      OrderTypeState: false,
    };
    this.itemTotal = 0;
    this.discount = 0;
    this.quantity = 0;
    this.totalTax = 0;
    this.codeExeCount = 0;
    this.couponDiscount = 0;
    this.appliedCoupon = false;
    this.handleChange = this.handleChange.bind(this);
    this.cartTogglerRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.footerRef = React.createRef();
  }

  private cartTogglerRef: RefObject<HTMLDivElement> = React.createRef();
  private footerRef: React.RefObject<HTMLDivElement>;

  toggleState = () => {
    this.setState({ toggleActive: !this.state.toggleActive });
  };

  CloseStoreModal = () => {
    this.setState({ closeModal: false });
    window.location.reload();
  };

  openStoreModal = () => {
    this.setState({ closeModal: true });
  };

  currentTime = moment().format("hh:mm A");
  setTime = localStorage.getItem("setTime");
  selectedMoment = moment(this.setTime || null, "hh:mm: A");
  minimumInterval = 10;
  timeDifferenceInMinutes = this.selectedMoment.diff(
    moment(this.currentTime, "hh:mm A"),
    "minutes"
  );

  checkTimeAndExecute = () => {
    const currentTime = new Date().toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    const closeTime = new Date(`2023-01-01 ${this.state.closetime}`);
    const currentTimeDate = new Date(`2023-01-01 ${currentTime}`);
    const timeDifferenceInMinutes =
      ((closeTime as any) - (currentTimeDate as any)) / (1000 * 60);

    if (timeDifferenceInMinutes <= 20) {
      this.openStoreModal();
    } else {
      this.goToCheckout();
    }
  };

  // getTime = (value: any) => {
  //   this.setState({ setTime: value });
  //   this.setState({ toggleActive: false });
  //   localStorage.setItem('setTime', this.state.setTime);
  // };

  getTime = (value: any) => {
    this.props.setOrderTime(value);
    const selectedTime = value || "As soon as possible";

    localStorage.setItem("setTime", selectedTime);
    this.setState({ setTime: selectedTime }, () => {
      this.setState({ futureTime: value });
      this.setState({ toggleActive: false });
    });
    if (value != "As soon as possible") {
      this.props.saveOrderDateTime(new Date(), selectedTime, "orderLater");
    } else {
      sessionStorage.removeItem("futureDate");
    }
  };

  updateTimeOptions = () => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    const closingHour = 24; // Set your closing hour here

    // Calculate the first interval until 30 minutes past the current hour
    const firstInterval = [
      `${currentHour}:${currentMinute}`,
      ...Array(10)
        .fill(0)
        .map((_, i) => `${currentHour}:${30 + i}`),
    ];

    // Calculate intervals in 5-minute increments until closing time
    const remainingIntervals: string[] = [];
    for (let hour = currentHour + 1; hour < closingHour; hour++) {
      for (let minute = 0; minute < 60; minute += 5) {
        remainingIntervals.push(`${hour}:${minute}`);
      }
    }

    // Update state with the new time options
    this.setState({
      options: [...firstInterval, ...remainingIntervals],
    });
  };

  generateTimeOptions = () => {
    const options = [];

    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    const openingTime = this.state.openTime.trim();
    const [openingHour, openingMinute] = openingTime.split(":");
    const openingHourAsNumber = parseInt(openingHour, 10) || 0;
    const openingMinuteAsNumber = parseInt(openingMinute, 10) || 0;

    const closingTime = this.state.closetime.trim();
    const [closingHour, closingMinute] = closingTime.split(":");
    const closingHourAsNumber = parseInt(closingHour, 10) || 0;
    const closingMinuteAsNumber = parseInt(closingMinute, 10) || 0;

    let startHour = Math.max(currentHour, openingHourAsNumber);
    let startMinute = Math.max(currentMinute, openingMinuteAsNumber);

    while (
      startHour < closingHourAsNumber ||
      (startHour === closingHourAsNumber && startMinute < closingMinuteAsNumber)
    ) {
      options.push(this.formatTime(startHour, startMinute));

      // Move to the next interval (5 minutes)
      startMinute += 5;

      // Check if the hour needs to be incremented
      if (startMinute >= 60) {
        startMinute = 0;
        startHour += 1;
      }
    }

    return options;
  };

  formatTime = (hour: any, minute: any) => {
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };

  // isTimeGreaterThanCurrent = (
  //   currentHour: number,
  //   currentMinute: number,
  //   targetHour: number,
  //   targetMinute: number
  // ): boolean => {
  //   return targetHour > currentHour || (targetHour === currentHour && targetMinute > currentMinute);
  // };

  handleTime = (selectTime: any) => {
    this.setState({ futureTime: selectTime });
  };

  handleResize = () => {
    this.setState({
      websiteHight: window.innerHeight,
    });
  };

  handleScroll() {
    // const footer = document.getElementById('my_footer_id');
    const footer = this.footerRef.current;
    const totalHeight = document.documentElement.scrollHeight;
    this.setState({
      totalHeight: window.scrollY + window.innerHeight,
    });
    if (footer) {
      const footerRect = footer.getBoundingClientRect();

      const isFooterVisible = footerRect.top >= 0;
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleScroll);

    this.props.getCart();
    this.props.isCartQuantity();
    this.props.cartOpenModal();
    this.generateOptions();
    const generatedTimeOptions = this.generateTimeOptions();
    this.setState({ timeOptions: generatedTimeOptions });
    this.setState({ timeOptions: generatedTimeOptions });
    let orderTypeMode = localStorage.getItem("OrderTypeMode");
    this.setState({ OrderTypeState: orderTypeMode });

    let pickupTime = localStorage.getItem("setTime");
    // this.setState({setTime:pickupTime})
    if (orderTypeMode === "false") {
      this.setState({ mode: false });
    } else {
      this.setState({ mode: true });
    }
    let tableNumber = localStorage.getItem("tableNumber");
    this.setState({ tabNumber: tableNumber });

    this.setState({ orderType: sessionStorage.getItem("orderType") });
    window.addEventListener("resize", this.handleResize);
    if (this.props.orderType == "Pickup") {
      //set 15% VAT Tax
      this.props.saveTaxRate(0.15);
      sessionStorage.setItem("tax_percent", "15");
      sessionStorage.setItem("tax_rate", "0.15");
    }
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleScroll);
  }

  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  handleChange(event: any) {
    this.setState({ coupon_code: event.target.value });
  }

  ApplyCoupan = () => {
    this.appliedCoupon = true;
    let { coupon_code, discountFlag } = this.state;
    let { orderType, cart } = this.props;
    this.codeExeCount = 0;
    let Cart: any = cart;
    let discountItemFlag = false;
    let dataCart = JSON.parse(Cart);
    dataCart.forEach((item: any) => {
      if (item) {
        if (item.selectedsize.discount_price) {
          this.setState({ discountFlag: true });
          discountItemFlag = true;
        }
      }
    });
    if (coupon_code !== "" && discountItemFlag == false) {
      this.props.getCart();
      let token: any = sessionStorage.getItem("token");
      let sessionStore: any = sessionStorage.getItem("storeSelected");
      let parsedStore: any = sessionStore ? JSON.parse(sessionStore) : null;
      let data = {
        store_id: parsedStore ? parsedStore.store_id : null,
        coupon_code: coupon_code,
        order_mode: "online",
        order_channel: orderType == "Delivery" ? "delivery" : "pickup",
      };
      if (token) {
        let decoded: any = jwt_decode(token);
        Object.assign(data, { customer_id: decoded.customer.customer_id });
      }
      this.props.applyCoupon(data);
      this.calcTotal(dataCart);
      //   if(!_.isEmpty(this.props.coupon) && coupon_code != '') {
      //     toast.error("Coupon applied successfully")
      // }
    } else {
      //   toast.error("Coupon can not be applied on discounted items");
    }
  };

  incrementQuantity(currentIndex: any) {
    let cart: any = sessionStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);
    if (dataCart[currentIndex].item) {
      let price = dataCart[currentIndex].selectedsize.discount_price
        ? Math.round(dataCart[currentIndex].selectedsize.discount_price)
        : dataCart[currentIndex].selectedsize.orderModePrice[0].mrp;
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.item.modifiers) {
            const keys = Object.keys(obj.item.modifiers);
            if (keys.length > 0) {
              for (let i = 0; i < keys.length; i++) {
                const selectedModifiers = obj.item.modifiers[keys[i]].filter(
                  (item: any) => item.selected === true
                );
                selectedModifiers.forEach((item: any) => {
                  obj.totalItemPrice += item.modifier_sale_price;
                });
              }
            }

            // const keys = Object.keys(obj.item.modifiers);
            // if(keys.length > 0)
            // {
            //   for (let i = 0; i < keys.length; i++) {
            //     const key = keys[i];
            //     obj.item.modifiers[key].map((item: any, index: any) => {
            //       if(item.selected == true)
            //       {
            //         obj.totalItemPrice += item.modifier_sale_price;

            //       }
            //     }
            //   )}
            // }
          }
          obj.totalItemPrice = obj.totalItemPrice + price;
          obj.quantity += 1;
        } else {
          //dataCart.splice(currentIndex, 1);
          this.props.saveCart(dataCart);
          this.props.getCart();
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 300);
    }
  }

  decrementQuantity(item: any, currentIndex: any) {
    let cart: any = sessionStorage.getItem("cart");
    let Cart: any = cart;
    let dataCart = JSON.parse(Cart);

    let products: any = [];
    let MapObj: any = {};
    //dataCart.forEach((cartItem:any) => {
    if (item.item) {
      MapObj = {
        quantity: 1,
        price: item.price,
        id: item.item.menu_item_id,
        name: item.item.item_name,
      };
    }
    products.push(MapObj);

    if (dataCart[currentIndex].item) {
      let price = dataCart[currentIndex].selectedsize.discount_price
        ? Math.round(dataCart[currentIndex].selectedsize.discount_price)
        : dataCart[currentIndex].selectedsize.orderModePrice[0].mrp;
      let makeAComboPrice = 0;
      dataCart.map((obj: any, index: any) => {
        if (currentIndex == index) {
          if (obj.quantity == 1) {
            dataCart.splice(currentIndex, 1);
            this.props.saveCart(dataCart);
            this.props.getCart();
          }
          const keys = Object.keys(obj.item.modifiers);
          if (keys.length > 0) {
            for (let i = 0; i < keys.length; i++) {
              const selectedModifiers = obj.item.modifiers[keys[i]].filter(
                (item: any) => item.selected === true
              );
              selectedModifiers.forEach((item: any) => {
                obj.totalItemPrice -= item.modifier_sale_price;
              });
            }
          }
          obj.totalItemPrice = obj.totalItemPrice - price - makeAComboPrice;
          obj.quantity -= 1;
        }
      });
      this.props.saveCart(dataCart);
      setTimeout(() => {
        this.props.getCart();
      }, 300);
    }
  }

  calcItemTotal = (carts: any) => {
    let sum = 0;
    carts.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
    });
    this.itemTotal = sum;
    return sum;
  };

  calcTotalTax = () => {
    let totalTaxAmount: number = 0; // Use number type for better precision
    let orderType = this.props.orderType;

    if (orderType) {
      let data: any = sessionStorage.getItem("cart");
      let cart: any = JSON.parse(data);
      let tax_rate: number = parseFloat(this.props.taxRate); // Ensure tax_rate is a number

      for (let index = 0; index < cart.length; index++) {
        const element = cart[index].item;
        let taxAmount: any = 0;
        // let net_price: number = element.price / (1 + tax_rate);
        let net_price: any =
          (Number(cart[index].quantity) * parseFloat(element.price)) /
          (1 + Number(tax_rate));
        let net_p: any = parseFloat(net_price).toFixed(2);
        taxAmount = Number(cart[index].quantity) * element.price - net_p;
        taxAmount = parseFloat(taxAmount).toFixed(2);
        // taxAmount = element.price - net_price;

        // totalTaxAmount += parseFloat(cart[index].quantity) * taxAmount;
        totalTaxAmount += Number(taxAmount);

        if (cart[index].item.modifiers) {
          if (typeof cart[index].item.modifiers == "object") {
            Object.keys(cart[index].item.modifiers).map((key) => {
              cart[index].item.modifiers[key].map((item: any, _index: any) => {
                if (item.selected == true) {
                  // let mod_net_price: number =
                  //   item.modifier_sale_price / (1 + tax_rate);
                  let modTaxAmount: number = 0;
                  // item.modifier_sale_price - mod_net_price;
                  let mod_net_price: any =
                    (Number(cart[index].quantity) *
                      parseFloat(item.modifier_sale_price)) /
                    (1 + Number(tax_rate));
                  let mod_net_p: any = parseFloat(mod_net_price).toFixed(2);
                  modTaxAmount =
                    Number(cart[index].quantity) * item.modifier_sale_price -
                    Number(mod_net_p);
                  totalTaxAmount += Number(modTaxAmount);
                }
              });
            });
          }
        }
      }
    }

    // Round totalTaxAmount to two decimal places
    totalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

    this.props.SaveTaxValue(totalTaxAmount);

    return totalTaxAmount;
  };

  calcTotal = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0;
    let sum: any = 0;
    let sum2: any = 0;
    // let Coupon: string = coupon && coupon.channel;
    data.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
      sum2 += parseInt(item.totalItemPrice);
    });
    let discountValue: any = 0;

    if (!_.isEmpty(coupon)) {
      if (coupon.type == "menu") {
        if (coupon.coupon_value !== 0) {
          this.couponDiscount = coupon.coupon_value;
          discountValue = coupon.coupon_value;
        } else {
          discountValue = (sum * coupon.percent) / 100;
          this.couponDiscount += discountValue;
        }
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      } else if (coupon.type == "group") {
        let groups_json: any = coupon.groups_json;
        let parsedGroupsJson: any = groups_json
          ? JSON.parse(groups_json)
          : null;
        data.forEach((item: any, _index: any) => {
          parsedGroupsJson &&
            parsedGroupsJson.map((json: any) => {
              if (item.item) {
                if (
                  item.item.item_group_id == json.value ||
                  item.item.group_id == json.value
                ) {
                  if (coupon.coupon_value !== 0) {
                    discountValue =
                      discountValue + coupon.coupon_value * item.quantity;
                    this.couponDiscount += discountValue;
                    // item.totalItemPrice = item.totalItemPrice - discountValue;
                  } else {
                    discountValue =
                      (item.totalItemPrice * coupon.percent) / 100;
                    this.couponDiscount += discountValue;
                    // item.totalItemPrice = item.totalItemPrice - discountValue;
                  }
                }
              }
            });
        });
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      } else if (coupon.type == "item") {
        let CouponitemsArr = JSON.parse(coupon.items_json);
        data.forEach((item: any, _index: any) => {
          CouponitemsArr.map((coupon_items: any) => {
            if (item.item && coupon_items.menu_item_id) {
              if (item.item.menu_item_id == coupon_items.menu_item_id) {
                if (coupon.coupon_value !== 0) {
                  discountValue =
                    discountValue + coupon.coupon_value * item.quantity;
                  this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - coupon.coupon_value;
                } else {
                  discountValue = (item.totalItemPrice * coupon.percent) / 100;
                  this.couponDiscount += discountValue;
                  // item.totalItemPrice = item.totalItemPrice - discountValue;
                }
              }
            }
          });
        });
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      } else if (coupon.type == "store") {
        if (coupon.coupon_value !== 0) {
          this.couponDiscount = coupon.coupon_value;
          discountValue = coupon.coupon_value;
        } else {
          discountValue = (sum * coupon.percent) / 100;
          //   this.couponDiscount += discountValue;
          discountValue = (sum * coupon.percent) / 100;
          this.couponDiscount += discountValue;
        }
        if (this.couponDiscount === 0 && this.codeExeCount == 0) {
          toast.error("Coupon can not be applied on these items");
          this.props.resetCoupon({});
          this.codeExeCount = 1;
          this.appliedCoupon = false;
        }
      }

      if (!_.isEmpty(this.props.coupon) && this.appliedCoupon) {
        toast.error("Coupon applied successfully");
        this.appliedCoupon = false;
      }
    } else {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    if (this.props.orderType == "Delivery") {
      if (!_.isEmpty(this.props.storeSelected)) {
        sum =
          this.props.pos_fee > 0
            ? sum -
              this.couponDiscount +
              deliveryfee +
              parseInt(this.props.pos_fee)
            : sum - this.couponDiscount + deliveryfee;
      } else {
        sum =
          this.props.pos_fee > 0
            ? sum - this.couponDiscount + parseInt(this.props.pos_fee)
            : sum - this.couponDiscount;
      }
    } else if (
      this.props.orderType == "Pickup" ||
      this.props.orderType == "Qr-Pickup"
    ) {
      sum =
        this.props.pos_fee > 0
          ? sum - this.couponDiscount + parseInt(this.props.pos_fee)
          : sum - this.couponDiscount;
    }
    this.props.SaveDiscountValue(this.couponDiscount);
    this.cartTotal = Math.round(sum);
    if (this.props.taxDataCash.tax_type == 1) {
      sum = parseInt(sum) + this.totalTax;
    } else {
      sum = parseInt(sum);
    }
    // sum = parseInt(sum) + this.totalTax;
    let finalSum = _.isNaN(sum) ? sum2 : sum;
    return Math.round(finalSum);
  };

  calcTotalDiscount = (discount: any) => {
    return discount;
  };
  openMiniCartModel = () => {
    this.setState({ openModel: true });
  };

  checkSelectedTime(selectedTime: any) {
    // Parse the current time
    const currentTime = moment();

    // Parse the selected time using the format 'hh:mm A'
    const selectedTimeMoment = moment(selectedTime, "hh:mm A", true);

    // Check if the selected time is valid and after the current time
    if (
      selectedTimeMoment.isValid() &&
      selectedTimeMoment.isAfter(currentTime)
    ) {
      // Calculate the end time of the 30-minute interval from the current time
      const intervalEndTime = currentTime.clone().add(30, "minutes");

      // Check if the selected time is within the next 30 minutes
      return selectedTimeMoment.isBefore(intervalEndTime);
    }

    // Return false for invalid or past times
    return false;
  }

  goToCheckout = () => {
    let orderType: any = sessionStorage.getItem("orderType");
    localStorage.setItem("special inc", this.props.special_ins);

    localStorage.setItem("OrderTypeMode", this.state.mode);
    let selectedTime = this.state.futureTime;
    const currentTime = moment().format("hh:mm A");
    let selectedMoment = moment(selectedTime, "hh:mm: A");

    const timeDifferenceInMinutes = selectedMoment.diff(
      moment(currentTime, "hh:mm A"),
      "minutes"
    );

    // if (orderType) {
    //   this.props.setTabName(
    //     sessionStorage.getItem("token") ? "payment" : "checkout"
    //   );
    // } else {
    //   this.onPickupOpenModal(true);
    // }

    const minimumInterval = 10;

    if (this.props.orderType === "Pickup") {
      if (timeDifferenceInMinutes < minimumInterval) {
        // alert("Please refresh the website to get the latest time intervals.");
        this.props.setOpenTimerModal(true);
        // window.location.reload();
        return;
      } else {
        if (orderType) {
          this.props.setTabName(
            sessionStorage.getItem("token") ? "payment" : "checkout"
          );
        } else {
          this.onPickupOpenModal(true);
        }
      }
    } else {
      if (orderType) {
        this.props.setTabName(
          sessionStorage.getItem("token") ? "payment" : "checkout"
        );
      } else {
        this.onPickupOpenModal(true);
      }
    }
    this.props.cartToggleAction(false);
  };

  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item) {
        if (item.selectedsize.discount_price) {
          discount +=
            item.quantity *
            Math.round(
              item.selectedsize.mrp - item.selectedsize.discount_price
            );
        }
      }
    });
    return discount;
  };
  countSelectedModifiers(cart: any, keyToCount: any) {
    let selectedModifiersCount = 0;

    cart.forEach((item: any) => {
      if (item.item.selected && item.item.modifiers[keyToCount]) {
        selectedModifiersCount += item.item.modifiers[keyToCount].length;
      }
    });

    return selectedModifiersCount;
  }
  getModifierPrice = (modifiers: any, carts: any, quantity: number): any => {
    let value = Object.keys(modifiers).reduce((count, key) => {
      return (
        count +
        carts.item.modifiers[key].reduce((innerCount: number, item: any) => {
          return (
            innerCount + (item.selected ? Number(item.modifier_sale_price) : 0)
          );
        }, 0)
      );
    }, 0);
    return quantity * value;
  };
  cartItem = (data: any) => {
    let tax_rate: any = sessionStorage.getItem("tax_rate");
    if (!_.isEmpty(data)) {
      let cart: any[] = JSON.parse(data);
      if (cart.length > 0) {
        return (
          <div>
            <div className="d-md-none d-flex flex-col align-item-center mt-0 justify-content-between header_mbl_Cart">
              <span className="cart-heading" style={{ paddingLeft: "16px" }}>
                Your Cart
              </span>
              <div
                className="d-flex gap-5 align-items-center"
                style={{ paddingRight: "16px" }}
                onClick={this.props.cartOpenModal}
              >
                <span className="cart-cancel-btn">Close</span>
                <EastIcon
                  className="font_close_mobile "
                  style={{ fontSize: "20px" }}
                />
              </div>
            </div>
            <hr
              className="d-md-none"
              style={{ marginTop: "20px", marginBottom: "13px" }}
            ></hr>
            <div
              className={
                window.innerWidth < 600 ? "ct-items-div p-3" : "ct-items-div"
              }
            >
              <div
                // style={{ overflowX: "hidden", overflowY: "hidden" }}
                className="cart-order-list"
              >
                {cart.map((carts, indexes) => (
                  <>
                    <div className="row" style={{ margin: "0px 0px" }}>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-9 d-flex">
                            <img
                              className="cart-image"
                              src={`${BASE_URL}${carts.item.image_url}`}
                              alt="product images"
                            />
                            <p className="cart-product-name ml-3">
                              {!_.isEmpty(carts.item) && (
                                <>
                                  {carts.item.item_name.length > 16
                                    ? `${carts.item.item_name.substring(
                                        0,
                                        16
                                      )}...`
                                    : carts.item.item_name}{" "}
                                </>
                              )}
                            </p>
                            {Object.keys(carts.item.modifiers).reduce(
                              (count, key) => {
                                return (
                                  count +
                                  carts.item.modifiers[key].reduce(
                                    (innerCount: any, item: any) => {
                                      return (
                                        innerCount + (item.selected ? 1 : 0)
                                      );
                                    },
                                    0
                                  )
                                );
                              },
                              0
                            ) > 0 && (
                              <p className="cart-choice-qty mt-2">
                                {Object.keys(carts.item.modifiers).reduce(
                                  (count, key) => {
                                    return (
                                      count +
                                      carts.item.modifiers[key].reduce(
                                        (innerCount: any, item: any) => {
                                          return (
                                            innerCount + (item.selected ? 1 : 0)
                                          );
                                        },
                                        0
                                      )
                                    );
                                  },
                                  0
                                ) + " choice item"}
                              </p>
                            )}
                          </div>
                          <div className="col-3">
                            <div className="product-price">
                              {`kr ${
                                Math.round(carts.price) * carts.quantity +
                                this.getModifierPrice(
                                  carts.item.modifiers,
                                  carts,
                                  carts.quantity
                                )
                              }`}
                            </div>

                            <div className="col-4">
                              <div className="cart-icons">
                                <img
                                  onClick={() =>
                                    this.decrementQuantity(carts, indexes)
                                  }
                                  src={
                                    process.env.PUBLIC_URL +
                                    (carts.quantity == 1
                                      ? "/assets/images/delete.svg"
                                      : "/assets/images/s1.svg")
                                  }
                                  alt="order-check"
                                  className="left-icon"
                                />
                                <span
                                  style={{
                                    width: carts.quantity > 9 ? "30px" : "30px",
                                  }}
                                  className="cart-cont"
                                >
                                  {carts.quantity}
                                </span>
                                <img
                                  onClick={() =>
                                    this.incrementQuantity(indexes)
                                  }
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/a1.svg"
                                  }
                                  alt="order-check"
                                  className="right-icon"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* {carts.selectedsize ? (
                            <p className="cart-product-price">
                              {carts.selectedsize.discount_price &&
                              carts.selectedsize.order_channel == "online" ? (
                                <></>
                              ) : (
                                <></>
                              )}
                            </p>
                          ) : (
                            <p className="cart-product-price">
                              {priceunit +
                                Math.round(carts.price) * carts.quantity}
                            </p>
                          )} */}
                      </div>
                    </div>
                    {<></>}

                    <hr
                      style={{ marginTop: "10px", marginBottom: "13px" }}
                    ></hr>
                  </>
                ))}
              </div>
              <hr className="divider mt-3"></hr>
              <div className="row mt-2" style={{ margin: "0px 0px" }}>
                <div className="col-12">
                  <p className="od">Order Details</p>
                </div>
                <div className="col-12">
                  {this.props.orderType && (
                    <div
                      className="d-flex justify-content-between"
                      style={{ width: "452px", height: "37px" }}
                    >
                      <p className="cart-single-item-left">{"Order Mode"}</p>
                      <p>
                        <ModeBar
                          mode={this.state.mode}
                          handleModeClick={(e: any) => this.handleModeClick(e)}
                        />
                      </p>
                    </div>
                  )}
                </div>
                <div className="col-12">
                  <div className="d-flex justify-content-between spi">
                    {this.state.mode == true
                      ? "Select order time"
                      : "Table Number"}
                  </div>
                </div>

                <div className="col-12 mt-1">
                  {this.state.mode == true ? (
                    this.props.orderType && (
                      // <p className="cart-single-item-left custom_inputs border_custom">
                      //   <select
                      //     id="asap"
                      //     onClick={() => this.handleClick("Pickup")}
                      //   >
                      //     <option value="1-asap">ASAP (20 min)</option>
                      //     {this.state.options.map((time: any, index: any) => (
                      //       <option key={index} value={"option" + index}>
                      //         {time}
                      //       </option>
                      //     ))}
                      //   </select>
                      // </p>
                      <>
                        <div
                          style={{ marginBottom: "12px" }}
                          className="cusomt_Input border-custom"
                          onClick={this.toggleState}
                        >
                          <span>
                            {this.state.setTime &&
                            this.state.setTime.length !== 0 ? (
                              <span
                                style={{ color: "black", fontWeight: "500" }}
                              >
                                {this.state.setTime}
                              </span>
                            ) : (
                              "Select Pickup time"
                            )}
                          </span>
                          <div onClick={this.toggleState}>
                            <KeyboardArrowDownIcon
                              style={{ fontSize: "25px" }}
                            />
                          </div>
                        </div>

                        {this.state.toggleActive && (
                          <div
                            className="custom_dropDown"
                            onClick={() => this.handleClick("Pickup")}
                          >
                            {/* <p
                              style={{
                                cursor: "pointer",
                                marginBottom: "5px",
                                marginTop: "0px",
                                color: "#414040",
                                width:'100%'
                              }}
                              className="time-Selector"
                              onClick={() =>
                                this.getTime("As soon as possible")
                              }
                            >
                              As soon as possible
                            </p> */}
                            {this.state.options.map((time: any, index: any) => (
                              <div
                                key={index}
                                onClick={() => this.getTime(time)}
                                className="mt-1 mb-1 time-Selector"
                                style={{ color: "#414040" }}
                              >
                                {time}
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )
                  ) : (
                    <p className="cart-single-item-left">
                      <input
                        style={{
                          width: "100%",
                          marginBottom: "0",
                          left: "10px",
                        }}
                        id="round"
                        type="text"
                        value={this.state.tabNumber}
                        className="form-control input-font-size table-input custom_inputs table_number border-custom"
                        name="table_number"
                        placeholder="Enter your table number"
                        onChange={(e) => this.tableHandler(e)}
                        required
                        ref={(input) => (this.tableInput = input)}
                      />
                    </p>
                  )}
                </div>
                <div className="col-12">
                  <div className="d-flex spi">
                    <span>Special instructions</span>
                    <p className="opt-value">{`(Optional)`}</p>
                  </div>
                  <textarea
                    defaultValue={this.props.special_ins}
                    className="form-control special-ins custom_inputs"
                    name="special_instructions"
                    onChange={this.props.handleSpecialInstruction}
                    style={{ color: "black", fontWeight: "500" }}
                  ></textarea>
                </div>
              </div>
              <hr className="divider mt-2"></hr>
              <div className="row mt-2" style={{ margin: "0px 0px" }}>
                <div className="col-12">
                  <p className="od">Order Payment</p>
                </div>
                <div className="col-12">
                  <div className={`form-group cart-coupon-apply`}>
                    <div className="cart-subtotal">
                      <p className="cart-single-item-left">Total NOK</p>
                      <p className="cart-single-item-right">
                        {priceunit + this.calcItemTotal(cart)}
                      </p>
                    </div>
                    <React.Fragment>
                      <>
                        <div className="cart-subtotal">
                          {this.state.mode && this.state.OrderTypeState ? (
                            <p className="cart-single-item-left">
                              of which VAT: {"(" + 0.15 * 100 + "%)"}
                            </p>
                          ) : (
                            <p className="cart-single-item-left">
                              of which VAT: {"(25%)"}
                            </p>
                          )}
                          <p className="cart-single-item-right">
                            {_.isNaN(this.calcTotalTax())
                              ? priceunit + 0
                              : priceunit + this.calcTotalTax()}
                          </p>
                        </div>
                      </>
                    </React.Fragment>
                    {
                      !_.isEmpty(this.props.coupon) && (
                        <div className="cart-subtotal">
                          <p
                            style={{ color: "#d60000", fontWeight: "bold" }}
                            className="cart-single-item-right"
                          >
                            Discount/Voucher (
                            {this.props.coupon && this.props.coupon.coupon_code}
                            )
                          </p>
                          <p
                            style={{ color: "#d60000", fontWeight: "bold" }}
                            className="cart-single-item-right"
                          >
                            {this.props.coupon &&
                            this.props.coupon.coupon_value !== 0
                              ? `-${
                                  priceunit +
                                  Math.round(
                                    this.calcTotalDiscount(
                                      this.props.discountAmount
                                    )
                                  )
                                }`
                              : `-${
                                  priceunit +
                                  Math.round(
                                    this.calcTotalDiscount(
                                      this.props.discountAmount
                                    )
                                  )
                                }`}
                          </p>
                        </div>
                      )
                      // )
                    }
                    {this.calculateOrderDiscount(cart) > 0 && (
                      <div className="cart-subtotal">
                        <p
                          style={{ color: "#d60000", fontWeight: "bold" }}
                          className="cart-single-item-right"
                        >
                          Your Discount
                        </p>
                        <p
                          style={{ color: "#d60000", fontWeight: "bold" }}
                          className="cart-single-item-right"
                        >
                          {priceunit + this.calculateOrderDiscount(cart)}
                        </p>
                      </div>
                    )}
                    {/* <hr style={{ margin: "auto" }}></hr> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div><button onClick={this.openStoreModal}>open modal</button></div> */}
            <div className="chk-out-btn">
              <Link
                to={
                  this.props.orderType === "Pickup"
                    ? this.props.closeBefore || this.props.timeOver
                      ? "/"
                      : sessionStorage.getItem("token") && this.props.orderType
                      ? "/payment"
                      : this.props.orderType
                      ? "/checkout"
                      : "#"
                    : this.props.closeBefore || this.props.timeOver
                    ? "/"
                    : sessionStorage.getItem("token") && this.props.orderType
                    ? "/payment"
                    : this.props.orderType
                    ? "/checkout"
                    : "#"
                }
                type="button"
                className={` ${
                  this.props.orderType === "Dinein" && !this.state.tabNumber
                    ? "disabled-btn-checkout btn btn-lg btn-block cart-buttons"
                    : "btn btn-lg btn-block cart-buttons"
                } ${
                  this.props.orderType === "Pickup" && !this.state.setTime
                    ? "disabled-btn-checkout btn btn-lg btn-block cart-buttons"
                    : "btn btn-lg btn-block cart-buttons"
                } `}
                // onClick={this.props.closeBefore || this.props.timeOver ? this.openStoreModal : this.goToCheckout}
                onClick={this.checkTimeAndExecute}
              >
                <span>
                  <span className="cart-checkout">
                    Go to checkout {priceunit}
                    {this.calcTotal(cart)}{" "}
                  </span>
                </span>
              </Link>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="empty-ct-items-div">
              <div className="row">
                <div className="col-12 text-center d-flex justify-content-center align-items-center">
                  <div className="basket-icon">
                    {this.props.timeOver || this.props.closeBefore ? (
                      <img
                        src={process.env.PUBLIC_URL + "/clock-regular.png"}
                        width={150}
                      />
                    ) : (
                      <img
                        src={process.env.PUBLIC_URL + "/cart.png"}
                        width={150}
                      />
                    )}
                  </div>
                </div>
                <div
                  className="col-12 text-center"
                  style={{ marginTop: "-29px" }}
                >
                  <div className="empty-cart">
                    {this.props.closeBefore ? (
                      <>
                        We’ll be back at{" "}
                        <span className="time-cart-close">
                          {this.state.openTime}
                        </span>
                      </>
                    ) : this.props.timeOver ? (
                      <>
                        We’ll be back at{" "}
                        <span className="time-cart-close">
                          {this.state.openTime}
                        </span>
                      </>
                    ) : (
                      "Your Cart is Empty"
                    )}
                  </div>
                </div>
                <div className="col-3"></div>
                <div className="col-6 text-center">
                  <div className="empty-cart-content">
                    {this.props.closeBefore ? (
                      <>The kitchen is closed right now</>
                    ) : this.props.timeOver ? (
                      <>The kitchen is closed right now</>
                    ) : (
                      "Tap the button down below to Create an order"
                    )}
                  </div>
                </div>
                <div className="col-3"></div>
              </div>
            </div>
            <div className="chk-out-btn">
              <Link
                to={"/"}
                type="button"
                className={`btn btn-lg btn-block cart-buttons ${
                  this.props.timeOver || this.props.closeBefore
                    ? "disabled-btn-checkout"
                    : ""
                }`}
                onClick={() => {
                  this.props.cartToggleAction(false);
                  this.props.cartOpenModal();
                }}
              >
                <span>
                  <span className="cart-checkout">Add products to cart</span>
                </span>
              </Link>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div>
          <div className="empty-ct-items-div">
            <div className="row">
              <div className="col-12 text-center d-flex justify-content-center align-items-center">
                <div className="basket-icon">
                  {this.props.timeOver || this.props.closeBefore ? (
                    <img
                      src={process.env.PUBLIC_URL + "/clock-regular.png"}
                      width={150}
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/cart.png"}
                      width={150}
                    />
                  )}
                </div>
              </div>
              <div
                className="col-12 text-center"
                style={{ marginTop: "-29px" }}
              >
                <div className="empty-cart">
                  {this.props.closeBefore ? (
                    <>
                      We’ll be back at{" "}
                      <span className="time-cart-close">
                        {this.state.openTime}
                      </span>
                    </>
                  ) : this.props.timeOver ? (
                    <>
                      <>
                        We’ll be back at{" "}
                        <span className="time-cart-close">
                          {this.state.openTime}
                        </span>
                      </>
                    </>
                  ) : (
                    "Your Cart is Empty"
                  )}
                </div>
              </div>
              <div className="col-3"></div>
              <div className="col-6 text-center">
                <div className="empty-cart-content">
                  {this.props.closeBefore ? (
                    <>The kitchen is closed right now</>
                  ) : this.props.timeOver ? (
                    <>The kitchen is closed right now</>
                  ) : (
                    "Tap the button down below to Create an order"
                  )}
                </div>
              </div>
              <div className="col-3"></div>
            </div>
          </div>
          <div className="chk-out-btn">
            <Link
              to={"/"}
              type="button"
              className={`btn btn-lg btn-block cart-buttons ${
                this.props.timeOver || this.props.closeBefore
                  ? "disabled-btn-checkout"
                  : ""
              }`}
              onClick={() => {
                this.props.cartToggleAction(false);
              }}
            >
              <span>
                <span className="cart-checkout">Add products to cart</span>
              </span>
            </Link>
          </div>
        </div>
      );
    }
  };
  handleAdd = async (item: any) => {
    let cart: any = [];
    this.props.getCart();
    let data: any = this.props.cart;
    if (!_.isEmpty(data)) {
      cart = JSON.parse(data);
    }
    if (!_.isEmpty(item)) {
      if (item.menu_item_id) {
        let sizearray: any = JSON.parse(item.item_size);
        //     //if Item is menu_item
        if (!_.isEmpty(cart)) {
          let found: any = "";
          cart.forEach((element: any) => {
            if (element["item"]) {
              if (element["item"].menu_item_id == item.menu_item_id) {
                if (sizearray[0].size == element.selectedsize.size) {
                  element.quantity = element.quantity + 1;
                  element.totalItemPrice = Math.round(
                    element.price * element.quantity
                  );
                  return (found = 1);
                }
              }
            }
          });
          if (found == "") {
            const itemcart = {
              item: item,
              quantity: 1,
              selectedsize: sizearray[0],
              price: sizearray[0].discount_price
                ? Math.round(sizearray[0].discount_price)
                : Math.round(sizearray[0].mrp),
              totalItemPrice:
                sizearray[0].discount_price &&
                sizearray[0].order_channel == "online"
                  ? Math.round(sizearray[0].discount_price)
                  : Math.round(sizearray[0].mrp),
              subTotal: Math.round(sizearray[0].price),
              image: item.image_url !== "" ? item.image_url : "",
            };
            cart.push(itemcart);
          }
        } else {
          const itemcart = {
            item: item,
            quantity: 1,
            selectedsize: sizearray[0],
            price: sizearray[0].discount_price
              ? Math.round(sizearray[0].discount_price)
              : Math.round(sizearray[0].mrp),
            totalItemPrice:
              sizearray[0].discount_price &&
              sizearray[0].order_channel == "online"
                ? Math.round(sizearray[0].discount_price)
                : Math.round(sizearray[0].mrp),
            subTotal: Math.round(sizearray[0].price),
            image: item.image_url !== "" ? item.image_url : "",
          };
          cart.push(itemcart);
        }
      }
    }
    this.props.saveCart(cart);
    this.props.getCart();
    toast.error(`${item.item_name && item.item_name} Added to cart`, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 1500,
    });
  };
  renderCartButton = (data: any) => {
    let cart: any = JSON.parse(data);
    let totalItemQuantity = 0;
    cart.map((item: any) => {
      totalItemQuantity += item.quantity;
    });

    return (
      <div className="cart-hover">
        <i className="fa fa-cart-plus"></i>
        <span className="badge-circle custom-default-green-button">
          {totalItemQuantity}
        </span>
      </div>
    );
  };
  setPickupOpen = (condition: any) => {
    this.setState({ pickupOpen: condition });
  };
  onPickupOpenModal = (_value?: any) => {
    this.setPickupOpen(!this.state.pickupOpen);
  };

  generateOptions = () => {
    Api.get(`customer/store/${6}`).then((response) => {
      if (response.data.success) {
        const parsedStore = response.data.successResponse;
        if (parsedStore && parsedStore.daysTiming) {
          const parsedjson = JSON.parse(parsedStore.daysTiming);
          console.log("parsedJson", parsedjson);
          if (parsedjson) {
            const currentDay = moment().format("dddd");
            const todayTiming = parsedjson.find(
              (item: { day: string }) => item.day === currentDay
            );
            console.log("today timing", todayTiming);
            if (todayTiming) {
              const store_open_time = todayTiming.opening;
              this.setState({ openTime: store_open_time });
              const store_close_time = todayTiming.closing;
              this.setState({ closetime: store_close_time });

              this.props.setStoreStart(store_open_time);
              this.props.setStoreClose(store_close_time);

              if (store_open_time && store_close_time) {
                const options: string[] = [];
                const currentHour = moment().hour();
                const currentMinute = moment().minute();
                const ClosehoursAsNumber = moment(
                  store_close_time,
                  "hh:mm A"
                ).hour();

                const timeUntilNextBoundary = 5 - (currentMinute % 5);
                let startHour = currentHour;
                let startMinute =
                  (currentMinute + timeUntilNextBoundary + 25) % 60;
                // Set the initial start time 30 minutes from the current time
                startMinute = (currentMinute + timeUntilNextBoundary + 25) % 60;
                startHour += Math.floor(
                  (currentMinute + timeUntilNextBoundary + 25) / 60
                );

                // Set the initial interval to 5 minutes
                let interval = 5;
                options.push(this.formatTime1(startHour, startMinute));

                while (startHour < ClosehoursAsNumber) {
                  startMinute += interval;
                  if (startMinute % 5 === 0) {
                    interval = 5;
                  }
                  if (startMinute >= 60) {
                    startMinute = 0;
                    startHour += 1;

                    // Add the current interval
                    options.push(this.formatTime1(startHour, startMinute));
                  } else {
                    // Add the current interval
                    options.push(this.formatTime1(startHour, startMinute));
                  }
                }

                const lastInterval = options[options.length - 1];
                const lastIntervalMoment = moment(lastInterval, "hh:mm A");
                const closingTimeMoment = moment(
                  store_close_time,
                  "hh:mm A"
                ).subtract(20, "minutes");

                if (lastIntervalMoment.isAfter(closingTimeMoment)) {
                  options.pop();
                  options.pop();
                  options.pop();
                  options.pop();
                }

                this.setOptions([...options]);
              }
            }
          }
        }
      }
    });
  };

  formatTime1 = (hour: any, minute: any) => {
    return moment({ hour, minute }).format("HH:mm");
  };

  setOptions = (options: any) => {
    this.setState({ options: options });
  };

  tableInput: HTMLInputElement | null = null;
  tableHandler = (e: any) => {
    sessionStorage.setItem("table_number", e.target.value);
    const tabNumber = e.target.value;
    const inputValue = e.target.value;
    this.setState({ tabNumber: tabNumber });
    localStorage.setItem("tableNumber", tabNumber);
    sessionStorage.setItem("TableStorageNumber", tabNumber);
  };

  handleClick = (mode: any) => {
    this.setState({ orderType: mode });
    sessionStorage.setItem("orderType", mode);
    this.props.saveOrderType(mode);
    let orderMode = sessionStorage.getItem("orderType");
    sessionStorage.removeItem("orderNow");
    if (orderMode && orderMode == "Pickup") {
      //set 15% VAT Tax
      this.props.saveTaxRate(0.15);
      sessionStorage.setItem("tax_percent", "15");
      sessionStorage.setItem("tax_rate", "0.15");
    }

    if (orderMode && orderMode == "Dinein") {
      const tabNumber = sessionStorage.getItem("table_number") || "";
      sessionStorage.removeItem("futureDate");
      this.setState({ tabNumber });
      this.setState({ mode: false });
      if (this.tableInput) {
        this.tableInput.value = tabNumber;
      }
      //set 25% VAT Tax
      this.props.saveTaxRate(0.25);
      sessionStorage.setItem("tax_percent", "25");
      sessionStorage.setItem("tax_rate", "0.25");
      // this.handleDineInRowClick("dinein", "on");
    }
  };
  handleModeClick = (mode: any, IsFuture?: any) => {
    if (mode == "Pickup" && IsFuture) {
      // if (e == "on") {
      this.setState({ mode: true });
      this.setState({ setTable: false });
      this.props.saveOrderType(mode);
      this.props.saveTaxRate(0.15);
      sessionStorage.setItem("tax_percent", "15");
      sessionStorage.setItem("tax_rate", "0.15");
      localStorage.setItem("OrderTypeMode", "true");
      // }
    }
    if (mode == "Pickup" && !IsFuture) {
      // sessionStorage.removeItem("futureDate");
      // sessionStorage.removeItem("futureTime");
      this.setState({ mode: true });
      this.setState({ setTable: false });
      this.props.saveOrderType(mode);
      this.props.saveTaxRate(0.15);
      sessionStorage.setItem("tax_percent", "15");
      sessionStorage.setItem("tax_rate", "0.15");
      localStorage.setItem("OrderTypeMode", "true");
    }
    if (mode == "Dinein") {
      this.setState({ mode: false });
      this.setState({ setTable: true });
      sessionStorage.removeItem("futureDate");
      const tabNumber = sessionStorage.getItem("table_number") || "";
      this.setState({ tabNumber });
      if (this.tableInput) {
        this.tableInput.value = tabNumber;
      }
      this.props.saveOrderType(mode);
      this.props.saveTaxRate(0.25);
      sessionStorage.setItem("tax_percent", "25");
      sessionStorage.setItem("tax_rate", "0.25");
      localStorage.setItem("OrderTypeMode", "false");
    }
  };

  render() {
    let { cart } = this.props;
    let { cartOpening } = this.props;

    const { tabNumber } = this.state;
    const orderType = sessionStorage.getItem("orderType");
    const isButtonDisabled = orderType === "Dinein" && !tabNumber;
    const handleOutsideClick = () => {
      this.props.cartToggleAction(false);
    };

    // const isHomePage = this.props.location.pathname === '/';

    return (
      <>
        <OutsideClickHandler onOutsideClick={handleOutsideClick}>
          <div hidden={this.props.isOpenMenu === true ? false : true}>
            <div
              className="sidebar-menu"
              style={{ boxShadow: "0 16px 24px rgba(0, 0, 0, .2)" }}
            >
              <div className="cart-upper-heading">
                <span
                  className="cart-heading ml-2"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Your Cart
                </span>
                <div className="row  d-flex gap-3 flex-row align-items-center">
                  <span
                    onClick={() => {
                      this.props.cartToggleAction(
                        this.props.cartToggle == true ? false : true
                      );
                    }}
                    className="cart-cancel-btn mr-0"
                  >
                    Close
                  </span>
                  <span>
                    <EastIcon
                      className="font_close_mobile "
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                </div>
              </div>
              {this.cartItem(cart)}
            </div>
          </div>
        </OutsideClickHandler>
        {/* {!_.isEmpty(cart) && JSON.parse(cart).length > 0 && (
          <div
            className="p_sticky_view_cart"
            onClick={() => this.openMiniCartModel()}
          >
            <div className="d-flex justify-content-between p_sticky_view_cart_btn">
              <div className="view_basket_icon">
                {this.renderCartButton(cart)}
              </div>
              <span>View cart</span>
              <span>
                {"NOK"} {this.calcTotal(JSON.parse(cart))}
              </span>
            </div>
          </div>
        )} */}

        {/*
        {window.location.pathname === '/' &&
          !_.isEmpty(cart) && JSON.parse(cart).length > 0 && (
          <div ref={this.cartTogglerRef} className={`d-flex flex-row justify-content-between cart_bar`} onClick={() => this.openMiniCartModel()}>
              <div className="view_basket_icon">
                {this.renderCartButton(cart)}

              </div>
            <span className="font_custom_cart">View cart</span>
              <span className="font_custom_cart">
                {"NOK"} {this.calcTotal(JSON.parse(cart))}
              </span>
            </div>
          )
        } */}

        <TimeupModal />

        {/* <Modal
          open={this.props.cartOpening}
          onClose={this.props.cartOpenModal}
          center
          closeOnOverlayClick={false}
          closeOnEsc={false}
          closeIconId="mbl-cart-model-close-btn"
          classNames={{modal:"customModal-mobile"}}
        >
          {this.cartItem(cart)}
        </Modal> */}
        <Modal
          open={this.props.cartOpening}
          onClose={this.props.cartOpenModal}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Box
            sx={{
              ...style,
              width: [370, 400, 400],
              border: "none",
              borderRadius: "10px",
            }}
            className="modal_container"
          >
            {this.cartItem(cart)}
          </Box>
        </Modal>

        {/* <Modal
          open={this.state.closeModal}
          showCloseIcon={false}
          closeIcon={null}
          onClose={this.CloseStoreModal}
          center
          closeOnOverlayClick
          closeOnEsc
        >
          <div className="d-flex justify-content-center align-items-center px-3 py-2 flex-column">
            <p className="mt-3 sorry_text">
              Sorry. Kitchen is closed now. You can order tomorrow.
            </p>
            <button className="done_button" onClick={this.CloseStoreModal}>
              Done
            </button>
          </div>
        </Modal> */}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    cart: state.login.cartData,
    cartOpening: state.search.cartModal,
    orderType: state.login.orderType,
    special_ins: state.login.special_instructions,
    storeSelected: state.login.storeSelected,
    deliveryfee: state.login.deliveryfee,
    paymentMethod: state.login.paymentMethod,
    taxAmount: state.login.taxAmount,
    taxDataCash: state.login.taxDataCash,
    taxDataCard: state.login.taxDataCard,
    pos_fee: state.login.pos_fee,
    discountAmount: state.login.discountAmount,
    cartToggle: state.login.cartToggle,
    coupon: state.login.coupon,
    futureDate: state.login.orderDate,
    futureTime: state.login.orderTime,
    preOrLater: state.login.preOrLater,
    taxRate: state.login.taxRate,
    timeOver: state.search.timerOver,
    Width: state.search.width,
    Height: state.search.height,
    closeBefore: state.search.CloseTime,
    tabNumber:
      state.login.tabNumber ||
      parseInt(sessionStorage.getItem("tableNumber") || "0"),
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getCart: function () {
      dispatch(getCart());
    },
    isCartQuantity: function (num: number) {
      dispatch(isCartQuantity(num));
    },
    cartOpenModal: function () {
      dispatch(cartOpenModal(false));
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    handleSpecialInstruction: (event: any) => {
      dispatch(handleSpecialInstruction(event));
    },
    SaveTaxValue: function (_taxAmount: any) {
      // dispatch(SaveTaxValue(taxAmount));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    cartToggleAction: (toggle: any) => {
      dispatch(cartToggle(toggle));
    },
    applyCoupon: (data: any) => {
      dispatch(applyCoupon(data));
    },
    resetCoupon: (obj: any) => {
      dispatch(resetCoupon(obj));
    },
    saveOrderType: function (orderType: any) {
      dispatch(saveOrderType(orderType));
    },
    saveTaxRate: function (taxRate: any) {
      dispatch(saveTaxRate(taxRate));
    },
    setOrderTime: (timeValues: any) => dispatch(setTimeOrder(timeValues)),
    setStoreStart: (value: any) => {
      dispatch(setStoreStart(value));
    },
    setStoreClose: (value: any) => {
      dispatch(setStoreClose(value));
    },
    saveOrderDateTime: (date: any, time: any, preOrLater: any) => {
      dispatch(saveOrderDateTime(date, time, preOrLater));
    },
    setOpenTimerModal: (toggle: any) => {
      dispatch(setOpenTimerModal(toggle));
    },
    setOpenOrderTime: (value: any) => {
      dispatch(setOrderTime(value));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartToggler);
