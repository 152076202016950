import { useTranslation } from "react-i18next";
import "./aboutUs.css";
function AboutUs(props: any) {
  const { t } = useTranslation();
  return (
    <div>
      <main className="main" style={{ backgroundColor: "#f7f7f7" }}>
        <div className="about-section">
          <div className="container">
            <h2 className="subtitle">{t("about.title")}</h2>

            <div className="row">
              <div className="col-lg-6 col-12" style={{ margin: "auto" }}>
                <img
                  className="image"
                  // src={process.env.PUBLIC_URL + "/assets/images/aboutuslogo.png"}
                  alt="PC Logo"
                />
              </div>
            </div>
            <div className="row p-3">
              <div
                className="col-lg-6 col-12 p-5"
                style={{
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: "24px",
                  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="about-details">
                  <p>
                    <h5 style={{ textAlign: "center" }}></h5>
                  </p>
                  <ul className="custom-ul">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
export default AboutUs;