export const LOGOUT_CUSTOMER = "LOGOUT_CUSTOMER";
export const SIGN_UP = "SIGN_UP";
export const STORES_LIST = "STORES_LIST";
export const PICKUP_STORES_LIST = "PICKUP_STORES_LIST";
export const MENU_COMBO_LIST = "MENU_COMBO_LIST";
export const MENU_GROUPS_LIST = "MENU_GROUPS_LIST";
export const MENU_ITEMS = "MENU_ITEMS";
export const ORDER_LIST = "ORDER_LIST";
export const GET_CART = "GET_CART";
export const SAVE_CART = "SAVE_CART";
export const STORE_NAME = "STORE_NAME";
export const GET_TAX = "GET_TAX";
export const STORE_ID = "STORE_ID";
export const STORE_TYPE = "STORE_TYPE";
export const ORDER_ID = "ORDER_ID";
export const ORDER_TYPE = "ORDER_TYPE";
export const STORE_TIME_CHECK = "STORE_TIME_CHECK";
export const ORDER_PERIOD = "ORDER_PERIOD";
export const ORDER_DATE_TIME = "ORDER_DATE_TIME";
export const COMBO_DETAIL = "COMBO_DETAIL";
export const COMBO_DETAIL_ID = "COMBO_DETAIL_ID";
export const ITEM_DETAIL = "ITEM_DETAIL";
export const ADDRESS_GUEST = "ADDRESS_GUEST";
export const SAVE_HOUSEDETAIL = "SAVE_HOUSEDETAIL";
export const SAVE_STREETDETAIL = "SAVE_STREETDETAIL";
export const SAVE_ADDRESSTYPE = "SAVE_ADDRESSTYPE";
export const SELECT_STORE_ID = "SELECT_STORE_ID";
export const SELECT_STORE = "SELECT_STORE";
export const DELIVERY_FEE = "DELIVERY_FEE";
export const ADD_GUEST = "ADD_GUEST";
export const PAYMENT = "PAYMENT";
export const CUSTOMER_ID = "CUSTOMER_ID";
export const FRANCHISE_APPLICATION = "FRANCHISE_APPLICATION";
export const LOGIN_SIGNUP = "LOGIN_SIGNUP";
export const SIGNUP_k2g = "SIGNUP_k2g";
export const TOP_LIST = "TOP_LIST";
export const TOP_DEALS_HOME = "TOP_DEALS_HOME";
export const HERO_ITEMS = "HERO_ITEMS";
export const LOG_Cart = "LOG_Cart";
export const FORGOT = "FORGOT";
export const ADDRESS_LIST = "ADDRESS_LIST";
export const FAV_LIST = "FAV_LIST";
export const FAV_MENU_ITEM = "FAV_MENU_ITEM";
export const OFFERS_LIST = "OFFERS_LIST";
export const COUPON_DATA = "COUPON_DATA";
export const CHECKOUT_TAB_NAME = "CHECKOUT_TAB_NAME";
export const RESTUARANTS = "RESTUARANTS";
export const ORDER_DATA = "ORDER_DATA";
export const GET_PROFILE = "GET_PROFILE";
export const HANDLE_PROFILE_INPUT = "HANDLE_PROFILE_INPUT";
export const TOP_SELLING_ITEMS = "TOP_SELLING_ITEMS";
export const ORDER_DETAIL = "ORDER_DETAIL";
export const SAVE_ADDRESS_USER = "SAVE_ADDRESS_USER";
export const SAVE_STORE_DETAILS = "SAVE_STORE_DETAILS";
export const ADD_EMAIL = "ADD_EMAIL";
export const ADD_CONTACT = "ADD_CONTACT";
export const SUBMIT_COMPLAINT = "SUBMIT_COMPLAINT";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const SUCCESS_FLAG = "SUCCESS_FLAG";
export const URL_MATCH_METHOD = "URL_MATCH_METHOD";
export const ORDER = "ORDER";
export const UPDATE_LSM = "UPDATE_LSM";
export const LSM_ITEMS = "LSM_ITEMS";
export const GET_TAX_CASH = "GET_TAX_CASH";
export const GET_TAX_CARD = "GET_TAX_CARD";
export const SAVE_TAX = "SAVE_TAX";
export const SAVE_DISCOUNT = "SAVE_DISCOUNT";
export const NETWORK_STATUS = "NETWORK_STATUS";
export const NEW_CUSTOMER_PHONE_URL = "NEW_CUSTOMER_PHONE_URL";
export const SWITCH_TAB = "SWITCH_TAB";
export const VALIDATE_OTP = "VALIDATE_OTP";
export const SPECIFIC_STORE_ITEMS = "SPECIFIC_STORE_ITEMS";
export const MENU_GROUPS_LIST_HOME = "MENU_GROUPS_LIST_HOME";
export const HANDLE_SPECIAL_INPUT = "HANDLE_SPECIAL_INPUT";
export const MENU_STORE_ID = "MENU_STORE_ID";
export const GET_STORE = "GET_STORE";
export const CITIES_WEB = "CITIES_WEB";
export const TRADE_ZONES_WEB = "TRADE_ZONES_WEB";
export const TRADE_AREAS_WEB = "TRADE_AREAS_WEB";
export const SET_CITY_NAME = "SET_CITY_NAME";
export const SET_TRADE_ZONE_NAME = "SET_TRADE_ZONE_NAME";
export const SET_TRADE_AREA_NAME = "SET_TRADE_AREA_NAME";
export const ADD_NEW_ADDRESS_WITHOUT_MAP = "ADD_NEW_ADDRESS_WITHOUT_MAP";
export const CART_TOGGLE = "CART_TOGGLE";
export const FEATURED_PRODUCTS = "FEATURED_PRODUCTS";
export const FILTERED_ITEMS = "FILTERED_ITEMS";
export const PICKUP_OPEN = "PICKUP_OPEN";
export const TABLE_NUMBER = "TABLE_NUMBER";
export const SAVE_TAX_RATE = "SAVE_TAX_RATE";
export const SAVE_AGREEMENT_CHECK = "SAVE_AGREEMENT_CHECK";
export const SAVE_COOKIEMODAL_CHECK = "SAVE_COOKIEMODAL_CHECK";
