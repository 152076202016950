import React, { Component, useEffect } from "react";
import { connect } from "react-redux";
import {
  orderDetail,
  orderDetailAfterPayment,
  ordersList,
} from "../../Redux/Actions/index";
// import _ from "lodash";
import { toast } from "react-toastify";
import "./acceptorders.css";
import { AcceptOrderProps, AcceptOrderState } from "../Interface/acceptOrder";
import { withTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import QRCode from "react-qr-code";
toast.configure();
class index extends Component<AcceptOrderProps, AcceptOrderState> {
  myRef: any;
  constructor(props: any) {
    super(props);
    this.state = {
      showDetail: false,
      orderType: "current",
      arrows: false,
      width: "",
      value: "https:order.palacecafe.no/login?param=true",
      open: false,
    };
    this.myRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const order_id = urlParams.get("paymentid");
    if (order_id) {
      this.props.orderDetailAfterPayment(order_id);
    }
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  changeType = (type: any) => {
    this.setState({ orderType: type, showDetail: false });
  };

  handleArrow = () => {
    this.setState({ arrows: !this.state.arrows });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  detailOfOrder = () => {
    let { orderDetail1, t } = this.props;

    return (
      <div style={{ height: "647px", marginTop: "100px" }}>
        <div className="main-order-details">
          <div className="order-number">
            <div className="w-100 text-center">
              <span className="order-text">{t("acceptOrder.orderPlaced")}</span>
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <p className="location-sub-total ">
              {orderDetail1 &&
                orderDetail1.order &&
                "#" + orderDetail1.order.order_id}
            </p>
            <p className="location-sub-total ml-5">
              {orderDetail1 &&
                orderDetail1.order &&
                orderDetail1.order.date_created}
            </p>
          </div>
          <hr className="mt-1 mb-3"></hr>
          {orderDetail1 &&
            orderDetail1.order &&
            orderDetail1.order.order_items.map((item: any, index: any) => {
              let modifiers: any =
                item.modifiers && item.modifiers.length > 0 && item.modifiers;
              return (
                <div className="location-cart-product-name">
                  <div className="d-flex justify-content-between">
                    <p className="location-cart-product-name ">
                      {item.order_item_quantity} x{" "}
                      {item.combo_id ? item.combo_name : item.item_name}
                    </p>
                    <p className="location-cart-product-name ml-5">
                      {item.order_item_grossprice} NOK
                    </p>
                  </div>
                  {
                    <>
                      {modifiers &&
                        modifiers.map((modifier: any) => {
                          return (
                            modifier.selected && (
                              <div className=" location-ndsub-details d-flex justify-content-between p-2">
                                <li style={{ paddingLeft: "1rem" }}>
                                  {modifier.modifier_name +
                                    " x " +
                                    modifier.quantity}
                                </li>
                                <p>
                                  {modifier.modifier_sale_price *
                                    modifier.quantity}{" "}
                                  NOK
                                </p>
                              </div>
                            )
                          );
                        })}
                    </>
                  }
                </div>
              );
            })}
          <hr className="mt-1 mb-2"></hr>
          <div className="scrollModify">
            {/* <div className="d-flex justify-content-between">
              <p className="location-sub-total"> {t("acceptOrder.orderNo")}</p>
              <p className="location-sub-total ml-5">
                {orderDetail1 &&
                  orderDetail1.order &&
                  orderDetail1.order.order_id}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="location-sub-total">{t("acceptOrder.date")}</p>
              <p className="location-sub-total ml-5">
                {orderDetail1 &&
                  orderDetail1.order &&
                  orderDetail1.order.date_created}
              </p>
            </div> */}
            <div className="d-flex justify-content-between">
              <p className="location-sub-total">{t("acceptOrder.mode")}</p>
              <p className="location-sub-total ml-5">
                {orderDetail1 &&
                  orderDetail1.order &&
                  orderDetail1.order.order_mode}
              </p>
            </div>
            {/* <div className="d-flex justify-content-between">
              <b className="sub-total">{t("acceptOrder.products")}</b>
            </div> */}
          </div>
          {/* <hr className="mt-1 mb-1 "></hr> */}
          <div className="d-flex justify-content-between">
            <p className="location-sub-total ">Subtotal</p>
            <p className="location-sub-total ml-5">NOK</p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="location-sub-total ">
              {orderDetail1 &&
              orderDetail1.order &&
              orderDetail1.order.tax_type == 0
                ? "Incl. Tax"
                : "of which VAT:"}
              {orderDetail1 &&
                orderDetail1.order &&
                "(" + orderDetail1.order.tax_percent + "%)"}
            </p>
            <p className="location-sub-total ml-5">
              {orderDetail1 &&
                orderDetail1.order &&
                orderDetail1.order.tax_amount.toFixed(0)}{" "}
              NOK
            </p>
          </div>
          {orderDetail1 &&
            orderDetail1.order &&
            orderDetail1 &&
            orderDetail1.order.discount > 0 && (
              <div className="d-flex justify-content-between">
                <p style={{ color: "green" }} className="location-sub-total ">
                  {t("acceptOrder.voucher")}
                </p>
                <p
                  style={{ color: "green" }}
                  className="location-sub-total ml-5"
                >
                  {orderDetail1 &&
                    orderDetail1.order &&
                    orderDetail1 &&
                    orderDetail1.order.discount}{" "}
                  NOK
                </p>
              </div>
            )}
          {orderDetail1 &&
            orderDetail1.order &&
            orderDetail1 &&
            orderDetail1.order.coupon_discount_value && (
              <div className="d-flex justify-content-between">
                <p style={{ color: "green" }} className="location-sub-total ">
                  {t("acceptOrder.voucher")}
                </p>
                <p
                  style={{ color: "green" }}
                  className="location-sub-total ml-5"
                >
                  {orderDetail1 &&
                    orderDetail1.order &&
                    orderDetail1 &&
                    orderDetail1.order.coupon_discount_value}{" "}
                  NOK
                </p>
              </div>
            )}

          <hr className="mt-2 mb-2"></hr>
          <div className="d-flex justify-content-between pt-6">
            <p className="location-total">{t("acceptOrder.total")}</p>
            <p className="location-total" style={{ marginTop: "8px" }}>
              {orderDetail1 &&
                orderDetail1.order &&
                orderDetail1.order.order_grossprice.toFixed(0)}{" "}
              NOK
            </p>
          </div>
          <h1 style={{ fontSize: "12px", marginTop: "-15px" }}>(ink. MVA)</h1>
          <hr className="mt-2 mb-0"></hr>
        </div>
        <button
          type="button"
          className="mt-2 btn track-order-btn"
          // onClick={handleSubmit}
        >
          <span className="shopping-name"> Track Order</span>
        </button>
      </div>
    );
  };

  render() {
    const dummyDataproduct: any[] = [
      {
        title: "1 x Grillet Ost & Skinke",
        price: "kr164",
      },
      {
        title: "1 x Grillet Ost & Skinke",
        price: "kr164",
      },
    ];

    const dummyExtraData: any[] = [
      {
        title: "1x Extra Sauce",
        price: "10kr",
      },
      {
        title: "1x Extra Sauce",
        price: "10kr",
      },
    ];
    const style = {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: [357, 560, 560],
      bgcolor: "background.paper",
      border: "0px solid #000",
      boxShadow: 24,
      borderRadius: "20px",
      p: 4,
    };

    return (
      <div className="main-container-order">
        <div className="header-order d-flex justify-content-center align-items-center">
          <span>Download Palace Cafe app to track your order</span>
          <button onClick={this.handleOpen}>Download</button>
        </div>
        <div className="container d-flex justify-content-center">
          <div className="container-outer">
            <div style={{ marginBottom: "15px" }}>
              <a href="/">
                <i
                  className="bi bi-arrow-left mr-3"
                  style={{ fontSize: "18px", color: "#364D49" }}
                ></i>
                <span className="back-menu-btn">Back to menu</span>
              </a>
            </div>
            <div className="order-box">
              <span className="order-heading">Your Order is Confirmed</span>
              <div className="order-detail-height">
                {this.props.orderDetail1 &&
                  this.props.orderDetail1.order &&
                  this.props.orderDetail1.order.order_items &&
                  this.props.orderDetail1.order.order_items.map(
                    (item: any, index: any) => (
                      <div key={index} className="d-flex flex-column">
                        <div className="section_detail_order d-flex justify-content-between align-items-center">
                          <span className="product_name">
                            {`${item.order_item_quantity} x ${item.item_name}`}
                          </span>
                          <span className="product_price">{`Kr ${item.order_item_grossprice}`}</span>
                        </div>
                        {item.modifiers && (
                          <div className="d-flex justify-content-between flex-column">
                            {item.modifiers.map(
                              (extra: any, extraIndex: any) => (
                                <div
                                  key={extraIndex}
                                  className="d-flex justify-content-between extra_detail_card"
                                >
                                  <span className="product_extra_title">{`${extra.quantity}x ${extra.modifier_name}`}</span>
                                  <span className="product_extra-price">{`kr ${extra.modifier_sale_price}`}</span>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )
                  )}
              </div>

              <hr className="my-2 custom-hr-line-accept" />
              {this.props.orderDetail1 && this.props.orderDetail1.order && (
                <div className="d-flex flex-column">
                  <h5 className="order-detail-heading">Order details</h5>
                  <div className="d-flex justify-content-between custom_detail_card">
                    <span className="order_detail_left">Order No</span>
                    <span className="order_detail_right">
                      {this.props.orderDetail1.order.order_id}
                    </span>
                  </div>
                  <div className="d-flex justify-content-between custom_detail_card">
                    <span className="order_detail_left">Order mode</span>
                    <span className="order_detail_right">
                      {this.props.orderDetail1.order.order_mode}
                    </span>
                  </div>
                  {this.props.orderDetail1.order.order_mode === "Dinein" && (
                    <div className="d-flex justify-content-between custom_detail_card">
                      <span className="order_detail_left">Order Time</span>
                      <span className="order_detail_right">
                        {this.props.orderDetail1.order.date_created.slice(
                          10,
                          20
                        )}
                      </span>
                    </div>
                  )}
                  <div className="d-flex justify-content-between custom_detail_card">
                    <span className="order_detail_left">
                      {this.props.orderDetail1.order.order_mode === "Dinein"
                        ? "Table No"
                        : "Pickup Time"}
                    </span>
                    {this.props.orderDetail1.order.order_mode === "Dinein" ? (
                      <>
                        <span className="order_detail_right">
                          {this.props.orderDetail1.order.table_number}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="order_detail_right">
                          {this.props.orderDetail1.order.future_status === 0
                            ? "ASAP(20 min)"
                            : this.props.orderDetail1.order.future_date.split(
                                " "
                              )[1]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}

              <hr className="my-2 custom-hr-line-accept" />
              {this.props.orderDetail1 && this.props.orderDetail1.order && (
                <div className="d-flex justify-content-between align-items-start  flex-column w-100">
                  <h5 className="order-detail-heading">Instructions</h5>
                  <span className=" mt-0 instruction-red order_detail_right custom_detail_card">
                    {this.props.orderDetail1.order.special_inst}
                  </span>
                </div>
              )}

              <hr className="my-2 custom-hr-line-accept" />
              {this.props.orderDetail1 && this.props.orderDetail1.order && (
                <>
                  <div className="d-flex flex-column">
                    <h5 className="order-detail-heading">Price</h5>
                    <div className="d-flex justify-content-between custom_detail_card">
                      {/* <span className="order_detail_left">Items total</span>
                      <span className="order_detail_right">
                        kr {this.props.orderDetail1.order.order_grossprice}
                      </span> */}
                    </div>
                    <div className="d-flex justify-content-between custom_detail_card">
                      <span className="order_detail_left">
                        VAT{" "}
                        {this.props.orderDetail1.order.order_mode ===
                        "Dinein" ? (
                          <sub className="font_subelement">(25%)</sub>
                        ) : (
                          <sub className="font_subelement">|(15%)</sub>
                        )}
                      </span>
                      <span className="order_detail_right">
                        {console.log(
                          "{this.props.orderDetail1.order.tax_amount}",
                          this.props.orderDetail1.order.tax_amount
                        )}
                        kr {this.props.orderDetail1.order.tax_amount}
                        {/* {this.props.orderDetail1.order.order_grossprice -
                          this.props.orderDetail1.order.order_netprice} */}
                      </span>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center justify-content-between custom_detail_card">
                    <span className="price-title-heading">Total</span>
                    <span className="price-total_right">
                      kr {this.props.orderDetail1.order.order_grossprice}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex flex-column ">
              <div className="d-flex mb-2">
                <span className="modal-heading">Download Application</span>
                <div className="close-btn-order" onClick={this.handleClose}>
                  <i className="bi bi-x"></i>
                </div>
              </div>
              <hr className="my-2" />
              <div className="d-flex flex-column justify-content-center align-items-center mt-2 qr-image-sec">
                {this.state.value && (
                  <QRCode
                    value={this.state.value}
                    bgColor={"#FFFFFF"}
                    fgColor={"#000000"}
                    size={256}
                  />
                )}
                <span className="qr-code-text mt-1">Scan QR Code</span>
              </div>
              <div className="d-md-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-none">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/apple.png"}
                  alt="qr code"
                />
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/play.png"}
                  alt="qr code"
                />
              </div>
              <div className="d-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-md-none">
                <a href="">
                  <img
                    className="qr-code-image"
                    src={process.env.PUBLIC_URL + "/apple.png"}
                    alt="qr code"
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=no.palacecafeapp.etcnor&hl=en&gl=US">
                  <img
                    className="qr-code-image"
                    src={process.env.PUBLIC_URL + "/play.png"}
                    alt="qr code"
                  />
                </a>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    ordersData: state.login.ordersData,
    orderDetail1: state.login.orderDetail1,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ordersList: function () {
      dispatch(ordersList());
    },
    orderDetailAfterPayment: function (id: any) {
      dispatch(orderDetailAfterPayment(id));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(index));
