import React, { useState } from "react";
import "./productModalv2.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { setProductModal } from "../../Redux/Reducers/searchReducer";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 0,
  px: 0,
  pb: 0,
};

interface Algenres {
  title: string;
}

interface extraItem {
  price: string;
}

interface ExtraItem extends Algenres {
  id: number;
  title: string;
  price: string;
}

const ProductModalv2 = () => {
  const [productModal, setProductModal1] = useState(true);
  const modalValue = useSelector((state:RootState)=> state.search.productModal);
  const dispatch = useDispatch();
  const handleModal = () => {
    setProductModal(true);
   dispatch(setProductModal(true))    
  };
  const handleCLosemOdal = () => {
    dispatch(setProductModal(true));
  };

  const AlgenreData: Algenres[] = [
    {
      title: "Gluten",
    },
    {
      title: "Rug",
    },
    {
      title: "Hvete",
    },
    {
      title: "Korn",
    },
  ];

  const ExtraData: ExtraItem[] = [
    { id: 0, title: "Extra Sauce", price: "kr 42" },
    {
      id: 1,
      title: "Extra Sauce",
      price: "kr 42",
    },
    {
      id: 2,
      title: "Extra Sauce",
      price: "kr 42",
    },
  ];

  const [selectedExtras, setSelectedExtras] = useState<number[]>([]);
  const handleCheckboxChange = (itemId: any) => {
    setSelectedExtras((prevSelectedExtras: any) =>
      prevSelectedExtras.includes(itemId)
        ? prevSelectedExtras.filter((id: any) => id !== itemId)
        : [...prevSelectedExtras, itemId]
    );
  };

  return (
    <>
      <Modal
        open={modalValue}
        onClose={handleCLosemOdal}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: [357, 400, 400],
            border: "none",
            borderRadius: "10px",
          }}
        >
          <div className="modal_content position-relative mb-md-2 mb-1">

           <div className="closeIcon" onClick={handleCLosemOdal}>
           <CloseIcon className="icon_close" />
           </div>

            <img
              src={process.env.PUBLIC_URL + "/product.png"}
              alt="product image"
              className="product_image"
            />
            <div className="d-flex flex-row justify-content-between mx-4 mt-2">
              <span className="product_name">Grillet Ost & Skinke</span>
              <span className="product_price">kr 109</span>
            </div>
            <div className="mx-4 mt-2">
              <span className="prodcut_short_desc">
                Focaccia brød med aioli dressing, tomat, ost, skinke og
                salatthis could go to two lines or even more.
              </span>
            </div>
            <div className="algenre_items mx-4 mt-2">
              <span className="algenre_heading">Allergens</span>
              <div className="subItem_container mt-2">
                {AlgenreData.map((item) => (
                  <div className="subItem_wrapper align-items-center">
                    <CheckCircleIcon className="icon_filled" />
                    <span className="subitem_heading">{item.title}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="extra_container mx-4 mt-2">
              <span className="extra_heading">Extra</span>
              {ExtraData.map((item) => (
                <div className="extra_item_wrapper mt-1" key={item.id}>
                  <div className="checkbox_container">
                    {selectedExtras.includes(item.id) ? (
                      <CheckBoxIcon
                        className="icon_empty"
                        onClick={() => handleCheckboxChange(item.id)}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        className="icon_empty"
                        onClick={() => handleCheckboxChange(item.id)}
                      />
                    )}
                    <span className="extra_item_heading">{item.title}</span>
                  </div>
                  <span className="extra_item_price">{item.price}</span>{" "}
                </div>
              ))}
            </div>
            <div className="extra_container mx-4 mt-2">
              <span className="extra_heading">Extra</span>
              {ExtraData.map((item) => (
                <div className="extra_item_wrapper mt-1" key={item.id}>
                  <div className="checkbox_container">
                    {selectedExtras.includes(item.id) ? (
                      <CheckBoxIcon
                        className="icon_empty"
                        onClick={() => handleCheckboxChange(item.id)}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        className="icon_empty"
                        onClick={() => handleCheckboxChange(item.id)}
                      />
                    )}
                    <span className="extra_item_heading">{item.title}</span>
                  </div>
                  <span className="extra_item_price">{item.price}</span>{" "}
                </div>
              ))}
            </div>
            <div className="extra_container mx-4 mt-2">
              <span className="extra_heading">Extra</span>
              {ExtraData.map((item) => (
                <div className="extra_item_wrapper mt-1" key={item.id}>
                  <div className="checkbox_container">
                    {selectedExtras.includes(item.id) ? (
                      <CheckBoxIcon
                        className="icon_empty"
                        onClick={() => handleCheckboxChange(item.id)}
                      />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        className="icon_empty"
                        onClick={() => handleCheckboxChange(item.id)}
                      />
                    )}
                    <span className="extra_item_heading">{item.title}</span>
                  </div>
                  <span className="extra_item_price">{item.price}</span>{" "}
                </div>
              ))}
            </div>
          </div>
          <div className="cart_wrapper mx-2">
            <div className="button_wrapper">
              <img
                src={process.env.PUBLIC_URL + "/minus.png"}
                alt="product image"
                className="increment"
              />
              <span className="cart_title">1</span>
              <img
                src={process.env.PUBLIC_URL + "/plus.png"}
                alt="product image"
                className="increment"
              />
            </div>
            <div className="cart_button">
              <button className="button_cart">Add kr 125</button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProductModalv2;
