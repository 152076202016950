import React, { Suspense, useEffect } from "react";
import Header from "../components/Header";
import HomeSlider from "../components/HomeSlider";

// import FeatureSection from '../components/featuresSection/FeatureSection'
import Footer from "../components/Footer";
// import PopularProducts from '../components/PopularProducts'
import MobileSection from "../components/MenuSection";

import { connect } from "react-redux";
import { addressesListk, menuHeroItems } from "../Redux/Actions/index";
import { Main } from "../components/menu/pages";
import { Nav } from "../components/menu/nav";
import NavProvider from "../components/menu/context/NavContext";
import NewMenu from "./newMenu";
import FeatureMenuProducts from "../components/FeatureMenuProducts";
import NewSlider from "../components/NewSlider";
import NewSliderv2 from "../components/NewSliderv2/NewSlider";
// const FeatureSection = React.lazy(() => import('../components/FeaturesSection'));

class Home extends React.Component<
  {
    topDealsHome: any;
    history: any;
    menuHeroItems: any;
    heroItem: any;
    addressesListk: any;
  },
  {}
> {
  componentDidMount() {
    document.title = "Home";
  }

  render() {
    let history = this.props.history;
    return (
      <div className="page-wrapper">
        <Header history={history} />
        <div
          className="row mobile-app-install-banner"
          style={{
            background: "#d9d9d9",
            alignItems: "center",
            padding: "10px",
            marginLeft: "0px",
            marginTop: "82px",
            display: "none"
          }}
        >
          <div className="col-9">
            <p className="install-para-mbl-hdr">
              Last ned appen og night fordleer digital stemplel
            </p>
          </div>
          <div className="col-3 text-right">
            <button
              className="install-button-mobile-header"
              // onClick={() => openAppModel()}
            >
              Install
            </button>
          </div>
        </div>
        {/* <HomeSlider /> */}
        <NewSliderv2 />
        {/* <NewSlider /> */}
        {/* <Suspense fallback={<div>Loading...</div>}>  */}
        <NewMenu />
        {/* <FeatureMenuProducts history={this.props.history} /> */}
        {/* <FeaturesSection history={history} /> */}

        {this.props.topDealsHome && this.props.topDealsHome.length > 0 ? (
          <>
            {/* <PopularProducts history={history} /> */}
            <MobileSection history={history} />
          </>
        ) : (
          <div>{/* <LoaderSkeleton /> */}</div>
        )}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    topDealsHome: state.login.topDealsHome,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    menuHeroItems: () => {
      dispatch(menuHeroItems());
    },
    addressesListk: function () {
      dispatch(addressesListk());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
