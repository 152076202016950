import React, { Component } from "react";
import { OtpProps, OtpStates } from "../Interface/loginInterface";
import { connect } from "react-redux";
import "./style.css";
import {
  changePasswordOtp,
  forgotPassword,
  generateOtp,
  updateForgotTab,
  ValidateOtp,
} from "../../Redux/Actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

toast.configure();

class index extends Component<OtpProps, OtpStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: "",
      email: "",
      phone: "",
      otp: "",
      isValidEmail: true,
      isValidPhone: false,
      isValidPass: false,
      newPass: "",
      confirmPass: "",
      changePassMsg: "",
      list: "1",
      passError: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }
  componentWillUnmount() {
    this.setState({
      list: "1",
      email: "",
      phone: "",
      otp: "",
      isValidEmail: true,
      isValidPhone: false,
      isValidPass: false,
      newPass: "",
      confirmPass: "",
      changePassMsg: "",
    });
    let tab = "1";
    this.props.updateForgotTab(tab);
  }
  handleSubmit = () => {
    const { email } = this.state;
    this.props.generateOtp(email);
  };
  handleSubmitOTP = () => {
    const { email, otp } = this.state;

    let data = {
      email: email,
      otp: otp,
    };
    this.props.ValidateOtp(data);
    if (this.tableInput) {
      this.tableInput.value = "";
    }
  };
  tableInput: HTMLInputElement | null = null;
  handleInputChange(event: { target: { name: any; value: any } }) {
    if (event.target.name === "newPass") {
      if (event.target.value.length < 8) {
        this.setState({
          [event.target.name]: event.target.value,
          isValidPass: false,
        });
      } else {
        this.setState({
          [event.target.name]: event.target.value,
          isValidPass: true,
        });
      }
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }
  validateEmail(event: { target: { name: any; value: any } }) {
    let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (reg.test(event.target.value) === false) {
      this.setState({ email: event.target.value, isValidEmail: false });
    } else {
      this.setState({ email: event.target.value, isValidEmail: true });
    }
  }
  validatePhone = (event: { target: { name: any; value: any } }) => {
    let reg = /^[0-9\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      this.setState({ phone: event.target.value, isValidPhone: true });
    } else {
      event.target.value = event.target.value.slice(
        0,
        event.target.value.length - 1
      );
      this.setState({ phone: event.target.value, isValidPhone: false });
    }
  };
  isUserReady = () => {
    let { email } = this.state;
    return email !== "";
  };

  isUserPasswordReady = () => {
    let { isValidPass, newPass, confirmPass } = this.state;
    let validateSignal = false;
    if (newPass !== "" && confirmPass !== "" && isValidPass) {
      validateSignal = true;
    }
    return validateSignal;
  };
  checkPhone = (phone: any) => {
    if (phone) {
      var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      var mobileno = /^((\\+91-?)|0)?[0-9]{12}$/;
      if (phone.match(phoneno) || phone.match(mobileno)) {
        return true;
      } else {
        return false;
      }
    }
  };
  handleSubmitPass = () => {
    let { newPass, confirmPass, email, otp } = this.state;

    let data = {
      password: newPass,
      email: email,
      otp: otp,
    };
    if (newPass == confirmPass) {
      console.log("");
      // this.props.signupk(data);
      this.props.changePasswordOtp(data);
    } else {
      this.setState({ changePassMsg: "Password don't match", passError: true });
      toast.error("Password not match");
    }
  };
  handleOnChange = (value: any, data: any) => {
    this.setState({ phone: value }, () => {
      console.log(this.state.phone);
    });
  };

  checkPassword = (pwd: any) => {
    if (pwd.length < 8) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    return (
      <div className="main" style={{ backgroundColor: "#f7f7f7" }}>
        <br />
        <div className="about-section" style={{marginTop: "8rem" }}>
          <div className="container">
            <div className="row p-3">
              {this.props.switchTab === "1"
                ? this.PhoneFields()
                : this.props.switchTab === "2"
                ? this.OtpRegister()
                : this.props.switchTab === "3"
                ? this.UpdatePassword()
                : null}
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  }
  PhoneFields = () => {
    return (
      <div
        className="col-md-5 col-lg-4 col-md-offset-5 p-5"
        style={{
          margin: "auto",
          backgroundColor: "white",
          borderRadius: "5px",
          boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
        }}
      >
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="text-center">
              <h3>
                <i className="fa fa-lock fa-4x"></i>
              </h3>
              <h2 className="text-center subtitle">Forgot Password?</h2>
              <p>Get OTP code with your registered email </p>
              <div className="login-form-1">
                <input
                  id="round"
                  type="email"
                  name="email"
                  onChange={this.validateEmail}
                  className="form-control input-font-size"
                  placeholder="Enter Your Email"
                  required
                />
                {this.state.isValidEmail == false &&
                  this.state.email !== "" && (
                    <small className=" text-danger">Invalid Email</small>
                  )}
              </div>
              <br />
              <div className="login-form-1">
                <button
                  id="roundb"
                  disabled={!this.isUserReady()}
                  onClick={this.handleSubmit}
                  type="button"
                  className="btn btn login-btn"
                >
                  Get Code
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  OtpRegister = () => {
    return (
      <div
        className="col-md-5 col-lg-4 col-md-offset-5 p-5"
        style={{
          margin: "auto",
          backgroundColor: "white",
          borderRadius: "24px",
          boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
        }}
      >
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="text-center">
              <h3>
                <i className="fa fa-lock fa-4x"></i>
              </h3>
              <h2 className="text-center subtitle">Enter One Time Password</h2>
              {/* <p>Check your phone number to get OTP code</p> */}
              <div className="login-form-1">
                <input
                  id="round"
                  defaultValue={""}
                  value={this.state.otp}
                  className="form-control"
                  style={{ fontSize: "15px" }}
                  size={40}
                  type="tel"
                  placeholder="Enter OTP code "
                  maxLength={11}
                  name="otp"
                  onChange={this.handleInputChange}
                  required
                  ref={(input) => (this.tableInput = input)}
                />
              </div>
              <br />
              <div className="login-form-1">
                <button
                  id="roundb"
                  disabled={!this.isUserReady()}
                  onClick={this.handleSubmitOTP}
                  type="button"
                  className="btn login-btn"
                >
                  Proceed
                </button>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  UpdatePassword = () => {
    return (
      <div
        className="col-md-5 col-lg-4 col-md-offset-5 p-5"
        style={{
          margin: "auto",
          backgroundColor: "white",
          borderRadius: "24px",
          boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
        }}
      >
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="text-center">
              <h3>
                <i className="fa fa-key fa-4x"></i>
              </h3>
              <h2 className="text-center subtitle">Change Password</h2>
              <div className="login-form-1">
                <input
                  id="round"
                  style={{ fontSize: "15px" }}
                  defaultValue=""
                  size={40}
                  type="password"
                  className="form-control"
                  placeholder="New Password "
                  name="newPass"
                  onChange={this.handleInputChange}
                  required
                />
                {!this.checkPassword(this.state.newPass) &&
                  this.state.newPass !== "" && (
                    <small className="form-text text-danger">
                      Password must be 8 characters long
                    </small>
                  )}
                <input
                  id="round"
                  style={{ fontSize: "15px" }}
                  defaultValue=""
                  size={40}
                  type="password"
                  className="form-control"
                  placeholder="Confirm Password"
                  name="confirmPass"
                  onChange={this.handleInputChange}
                  required
                />
              </div>
              <br />
              <div className="login-form-1">
                {/* <input
                      id="roundb"
                      name="recover-submit"
                      style={{
                        width: "145%",
                        marginRight: "45px",
                        marginLeft: "-45px",
                      }}
                      onClick={this.handleSubmit}
                      className="btn btn-lg btn-danger"
                      value="Reset Password"
                      type="submit"
                    /> */}
                <button
                  id="roundb"
                  disabled={!this.isUserPasswordReady()}
                  onClick={this.handleSubmitPass}
                  type="button"
                  className="btn login-btn"
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
const mapStateToProps = (state: any) => {
  return {
    isReset: state.login.isReset,
    respMessage: state.login.respMessage,
    switchTab: state.login.switchTab,
    isValidOtp: state.login.isValidOtp,
    OtprespMessage: state.login.OtprespMessage,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    generateOtp: function (phone: any) {
      dispatch(generateOtp(phone));
    },
    ValidateOtp: function (data: any) {
      dispatch(ValidateOtp(data));
    },
    changePasswordOtp: function (data: any) {
      dispatch(changePasswordOtp(data));
    },
    updateForgotTab: function (tab: any) {
      dispatch(updateForgotTab(tab));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
