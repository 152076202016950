import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import OrderTrack from '../components/OrderTrack'

const Ordertrack = (props: any) => {
    return (
        <div className="page-wrapper">
            <Header history={props.history} />
            <OrderTrack />
            <Footer />
        </div>
    )
}

export default Ordertrack