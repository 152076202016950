import PhoneInput from "react-phone-input-2";
import "./NewProfile.css";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { editProfile } from "../../Redux/Actions/index";
import _ from "lodash";
import { toast } from "react-toastify";
// import "./profile.css";
import Geocode from "react-geocode";
import { GoogleMapsAPI, priceunit } from "../../ApiManager/client-config";
import moment from "moment";
import Map from "./Map";
import ReactStars from "react-rating-stars-component";
import { BASE_URL } from "../../ApiManager/client-config";
import jwtDecode from "jwt-decode";
import PasswordStrengthBar from "react-password-strength-bar";
import ChangePasswordModal from "../ChangePasswordModal";
import { useTranslation } from "react-i18next";
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

toast.configure();

function NewProfile(props: any) {
  const { t } = useTranslation();
  let token: any = sessionStorage.getItem("token");
  let userData: any;
  userData = token ? jwtDecode(token) : null;
  let ph: any =
    userData &&
    userData.customer.phone_number &&
    userData.customer.phone_number &&
    userData.customer.phone_number.slice(
      userData.customer.phone_number[0] == "9" ? 2 : 1
    );
  ph = "47" + ph;
  const [firstName, setFirstname] = useState(
    userData && userData.customer.first_name
  );
  const [lastName, setLastname] = useState(
    userData && userData.customer.last_name
  );
  const [gender, setGender] = useState(userData && userData.customer.gender);
  const [dOb, setDob] = useState(
    userData ? userData.customer.customer_birthday : "0000-00-00T00:00:00"
  );
  const [Phone, setPhone] = useState(ph);
  const [email, setEmail] = useState(
    userData && userData.customer.email_address
  );
  const [image, setImage] = useState<any>(null);

  const handleImage = (e: any) => {
    var size = 1e6;
    if (e.target.files[0].size > size) {
      toast.error("Image size too large. Upload image less then 1mb", {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: false,
        autoClose: 4000,
      });
    } else {
      setImage(e.target.files[0]);
    }
  };

  const handlefirstName = (e: any) => {
    setFirstname(e.target.value);
  };

  const handlelastName = (e: any) => {
    setLastname(e.target.value);
  };
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [id, setId] = useState(null);

  const onOpenModal = (value: any) => {
    setType(value);
    setOpen(true);
  };
  useEffect(() => {
    let v = "";
    let doc = document as any;
    if (open) {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "hidden")
        : (v = "");
    } else {
      doc.getElementsByTagName("html")[0]
        ? (doc.getElementsByTagName("html")[0].style.overflow = "auto")
        : (v = "");
    }
  }, [open]);

  const onCloseModal = () => setOpen(false);

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handleGender = (e: any) => {
    setGender(e.target.value);
  };
  const handleOnChange = (value: any) => {
    setPhone(value);
  };
  const checkPhone = (phone: any) => {
    if (phone) {
      // var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      let phoneno = /^(?:\47|3)(?:3)[0-9]{9}$/;
      // var mobileno = /^((\\+92-?)|0)?[0-9]{11}$/;
      // if (phone.match(phoneno)) {
      //     return true;
      // } else {
      //     return false;
      // }
      return true;
    }
  };
  const handleDob = (e: any) => {
    setDob(e.target.value);
  };

  const handleSubmit = () => {
    let data = new FormData();
    let phone = "0" + Phone.slice(2);
    data.append("first_name", firstName);
    data.append("last_name", lastName);
    data.append("email_address", email);
    data.append("phone_number", phone);
    data.append("gender", gender);
    data.append("customer_birthday", dOb);
    data.append("login_name", firstName + " " + lastName);
    data.append("file", image);
    props.editProfile(userData.customer.customer_id, data);
  };
  return (
    <div className="profile-mobile-div">
      <main className="main" style={{ backgroundColor: "#f7f7f7" }}>
        <div className="about-section">
          <div className="container">
            <div className="row p-3 custom_profile_margin">
              <div
                className="col-lg-10 col-12 p-5"
                style={{
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: "4px",
                  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="about-details">
                  <div className="heading">
                    <div className="form-row">
                      <div className="form-group col-lg-6 col-md-12 col-12">
                        <h4 className="py-3 my-0"> {t("profile.fName")} </h4>
                        <input
                          id="round"
                          type="text"
                          className="form-control input-font-size"
                          name="firstname"
                          value={firstName}
                          placeholder={t("profile.fName")}
                          required
                          onChange={handlefirstName}
                        />
                      </div>

                      <div className="form-group col-lg-6 col-md-12 col-12">
                        <h4 className="py-3 my-0">{t("profile.lName")}</h4>
                        <input
                          id="round"
                          type="text"
                          className="form-control input-font-size"
                          name="lastname"
                          value={lastName}
                          placeholder={t("profile.lName")}
                          onChange={handlelastName}
                        />
                      </div>
                    </div>
                    <h4 className="py-3 my-0">{t("profile.phone")}</h4>
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                        autoFocus: true,
                      }}
                      containerClass={"input-2"}
                      country={"no"}
                      disableDropdown={true}
                      onlyCountries={["no"]}
                      countryCodeEditable={false}
                      // // masks={{pk: ' (...) ..-..-...',}}
                      // // prefix={'+'}
                      placeholder="3..-......."
                      value={Phone}
                      onChange={handleOnChange}
                    />
                    {!checkPhone(Phone) && Phone !== "" && (
                      <small className=" text-danger">
                        {t("profile.invalidPhone")}
                      </small>
                    )}
                    <h4 className="py-3 my-0">{t("profile.email")}</h4>
                    <input
                      id="round"
                      type="email"
                      name="email"
                      onChange={handleEmail}
                      className="form-control input-font-size"
                      placeholder={t("profile.emailPlchdr")}
                      value={email}
                      required
                    />

                    <h4 className="py-3 my-0">{t("profile.dob")} </h4>
                    <div className="input-group">
                      <input
                        id="round"
                        defaultValue={dOb && dOb.split("T")[0]}
                        style={{ fontSize: "15px", position: "initial" }}
                        type="date"
                        max={moment().local().format("YYYY-MM-DD")}
                        className="form-control laterDateInput"
                        onChange={handleDob}
                        required
                      />
                    </div>
                    <div className="form-footer">
                      <button
                        className="btn signup-btn"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {t("profile.btns.update")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
// editProfile
const mapStateToProps = (state: any) => {
  return {};
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    editProfile: (id: any, data: any) => {
      dispatch(editProfile(id, data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewProfile);
