import React from 'react'
import { Link } from 'react-router-dom';
import "./style.css";

export const Notfound = () => {
    return (
        <div className="notFoundContainer mt-3">
            <div className="childOne">
                <a style={{fontSize:"200px","color":"#345C59"}}>404</a>
                {/* <img
                    style={{ maxWidth: "207px" }}
                    src="/assets/images/notfoundlogo.png"
                    alt="404 Logo"
                /> */}
            </div>
            <div>
                <Link
                    to={{ pathname: `/`, search: window.location.search }}
                    className="btn btn-outline-danger mt-3 btn-sm customizeBtn"
                    id="roundb" >
                    Oops! Vi beklager noe gikk galt.
                </Link>
            </div>



            <div className="paragraphDiv mt-1">
                {/* <span>Looks like we wandered off a little bit, <br />click on the button above to see  our menu </span> */}
            </div>
        </div>
    )
}
