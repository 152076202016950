import React, { useEffect } from "react";
import Header from "../components/Header/index";
import { Notfound } from "../components/NotFound";
import Footer from "../components/Footer";


function NotFound  (props:any) {

    useEffect(() => {
        document.title = "404";

        return () => {

        }
    }, [])


    return (
        <div>
            <div>
                <Header history={props.history} />
                <Notfound />
                <Footer />
            </div>
        </div>
    );
    }

    export default NotFound