import React from 'react';

function LoaderSkeleton() {

return (
    <div className='container'>
            <div className="row" style={{ marginTop: "16px" }}>
              <div className="col-lg-4 col-md-6 col-sm-12">
                
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>


              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className="row" style={{ marginTop: "16px" }}>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className="row" style={{ marginTop: "16px" }}>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="col-lg-4 col-md-6 col-sm-12" style={{ marginBottom: "10px" }}>
                <div className="timeline-wrapper">
                  <div className="timeline-item">
                    <div className="animated-background">
                      <div className="background-masker header-top"></div>
                      <div className="background-masker header-left"></div>
                      <div className="background-masker header-right"></div>
                      <div className="background-masker header-bottom"></div>
                      <div className="background-masker subheader-left"></div>
                      <div className="background-masker subheader-right"></div>
                      <div className="background-masker subheader-bottom"></div>
                      <div className="background-masker content-top"></div>
                      <div className="background-masker content-first-end"></div>
                      <div className="background-masker content-second-line"></div>
                      <div className="background-masker content-second-end"></div>
                      <div className="background-masker content-third-line"></div>
                      <div className="background-masker content-third-end"></div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
)

}

export default LoaderSkeleton