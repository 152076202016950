import React from 'react'
import './NewFooter.css';

const NewFooter = () => {
    return (
        <div className='main_footer d-md-flex flex-column  d-none d-lg-none' id='my_footer_id'>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <div className='footer_logo d-flex flex-column'>
                    <img src={process.env.PUBLIC_URL + "/logo192.png"} className='logo_footer_custom' alt='website_logo' width={200} height={200} />

                    <div className='social_media_links d-flex flex-row padding_custom_footer mt-1'>
                      <a href='https://www.facebook.com/palacecafe.no'><i className="bi bi-facebook transparent-white-icon"></i></a>
                     <a href='https://www.instagram.com/palacecafe.no/'> 
                     <i className="bi bi-instagram transparent-white-icon"></i></a>
                        
                    </div>

                </div>
                <div className='footer_address d-flex flex-column padding_custom_footer'>
                    <span><i className="bi bi-geo-alt-fill mx-3"></i>Glommengata 69, 2211 Kongsvinger</span>
                    <span><i className="bi bi-telephone-fill mx-3 "></i>+47 414 22 274</span>
                    <span><i className="bi bi-envelope-fill mx-3"></i>bestilling@palacecafe.no</span>
                </div>
            </div>
            <hr className='text-white w-100' />
            <div className='d-flex flex-row justify-content-between mt-2'>
                <div className="link_footer d-flex flex-row justify-content-evenly padding_custom_footer">
                    <a href="" className="mx-3">Terms and Conditons</a>
                    <a href="" className="mx-3">Privacy Policy</a>
                    <a href="" className="mx-3">Cookies</a>
                </div>
                <p className="my-3 text-white padding_custom_footer copy_right_font" style={{ fontFamily: 'Glacial Indifference' }}>@ 2023 PalaceCafe. All Rights Reserved.</p>
            </div>
            <div className="d-flex flex-row text-center padding_custom_footer">
                <span className="text-white mx-3" style={{ fontFamily: 'Glacial Indifference' }}>Powered By</span>
                <img src={process.env.PUBLIC_URL + "/footer_side_logo.png"} />
            </div>
        </div>
    )
}

export default NewFooter