import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import MenuDetails from '../components/MenuDetails'
import NavProvider from '../components/menu/context/NavContext'
import Nav from '../components/menu/nav/Nav'
import Main from '../components/menu/pages/Main'

function NewMenu(props: any) {
  useEffect(() => {
    document.title = "Menu";
  })
  return (
    <>
      {/* <Header history={props.history}/> */}
      {/* <div className='container'> */}
          <NavProvider>
              <Nav />
              <Main />
          </NavProvider>
        {/* </div> */}
      {/* <MenuDetails history={props.history}/> */}
      {/* <Footer /> */}
    </>
  )
}

export default NewMenu;