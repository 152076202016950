import _ from "lodash";
import React from "react";
import { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  franchinseApplication,
  orderDetail,
  ordersList,
  trackOrder,
} from "../../Redux/Actions/index";
import {
  FranchiseAppkProps,
  FranchiseAppkState,
} from "../Interface/loginInterface";
import "./track.css";
import jwt_decode from "jwt-decode";

class index extends Component<
  { storeData: any; trackOrder: (data: any) => {} },
  {
    order_id: any;
    phone: any;
    email_address: any;
    isValidEmail: any;
    [x: number]: any;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = {
      order_id: "",
      email_address: "",
      phone: "92",
      isValidEmail: true,
    };
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isOrderReady = this.isOrderReady.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
  }
  componentDidMount() {
    let token: any = sessionStorage.getItem("token");
    let phone = sessionStorage.getItem("guestphone");

    // let p = sessionStorage.getItem("phone_number")

    let order_id = localStorage.getItem("orderId");
    if (order_id) {
      const data: any = {
        order_id: order_id,
        phone_number: null,
      };
      if (token) {
        let decoded: any = jwt_decode(token);
        data.phone_number = decoded.customer.phone_number;
        this.props.trackOrder(data);
        this.setState({
          order_id: order_id,
          phone: "92" + decoded.customer.phone_number.slice(1),
        });
      } else {
        data.phone_number = phone;
        this.props.trackOrder(data);
        this.setState({
          order_id: order_id,
          phone: phone,
        });
      }
    }
    window.scrollTo(0, 0);
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }
  validateEmail(event: { target: { name: any; value: any } }) {
    let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
    if (reg.test(event.target.value) === false) {
      this.setState({ email_address: event.target.value, isValidEmail: false });
    } else {
      this.setState({ email_address: event.target.value, isValidEmail: true });
    }
  }
  isOrderReady() {
    let { order_id, phone } = this.state;
    return order_id !== "" && phone !== "" && phone && phone.length == 12;
  }
  handleSubmit = (event: any) => {
    const { order_id, phone } = this.state;
    let token = sessionStorage.getItem("token");
    let str_phone_number = phone;
    if (str_phone_number.charAt(0) === "9") {
      str_phone_number = str_phone_number.substring(2);
      str_phone_number = token
        ? "0" + str_phone_number
        : "92" + str_phone_number;
    }
    const data: any = {
      order_id: order_id,
      phone_number: str_phone_number,
    };
    this.props.trackOrder(data);
  };

  validatePhone(event: { target: { name: any; value: any } }) {
    let maxNum = 11;
    if (event.target.value.length > maxNum) {
      event.target.value = event.target.value.slice(0, maxNum);
    }
    let reg = /[^0-9]/;
    if (reg.test(event.target.value) == false) {
      this.setState({ phone: event.target.value });
    } else {
      this.setState({ phone: event.target.value.replace("") });
    }
  }
  handleOnChange = (value: any, data: any) => {
    this.setState({ phone: value });
  };
  render() {
    let { storeData } = this.props;
    return (
      <main className="main" style={{ backgroundColor: "#f7f7f7" }}>
        <br />
        <div className="about-section">
          <h2 id="txt" className="subtitle text-dark">
            Track Order
          </h2>

          <div className="container">
            <div className="row p-3">
              <div
                className="col-md-12 col-lg-8 col-md-offset-12 p-5"
                style={{
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: "24px",
                  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div id="content">
                      <p style={{ fontSize: "17px" }}>
                        To track your order please enter your registered phone
                        number and Order ID in the box below and press the
                        "Track" button. This was given to you on your receipt
                        and in the confirmation message you have received.
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 mb-12">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <h5 id="txt" className="text-dark">
                              Phone Number
                            </h5>
                            <PhoneInput
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              containerClass={"input-2"}
                              country={"pk"}
                              disableDropdown={true}
                              onlyCountries={["pk"]}
                              countryCodeEditable={false}
                              // // masks={{pk: ' (...) ..-..-...',}}
                              // // prefix={'+'}
                              placeholder="3..-......."
                              value={this.state.phone}
                              onChange={this.handleOnChange}
                            />
                          </div>
                          <div className="form-group col-md-12">
                            <h5 id="txt" className="text-dark">
                              Order ID
                            </h5>
                            <input
                              name="order_id"
                              value={this.state.order_id}
                              placeholder="Enter your order ID"
                              style={{ fontSize: "15px" }}
                              type="text"
                              className="form-control"
                              id="round"
                              required
                              onChange={this.handleInputChange}
                            />
                          </div>

                          <div className="form-group col-md-12">
                            <button
                              id="h"
                              onClick={this.handleSubmit}
                              disabled={!this.isOrderReady()}
                              className="btn login-btn"
                            >
                              Track
                            </button>
                          </div>
                        </div>
                      </div>
                      {!_.isEmpty(storeData) && (
                        <div className=" col-md-12 col-lg-12 col-md-offset-12">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="text-dark" id="txt">
                                Order Information
                              </h4>
                            </div>
                            <div className="form-group col-md-6">
                              <h5 id="txt" className="text-dark">
                                Order ID
                              </h5>
                              <input
                                name="order_id"
                                disabled
                                value={storeData.order_id}
                                style={{ fontSize: "15px" }}
                                className="form-control"
                                id="round"
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <h5 id="txt" className="text-dark">
                                Order Total
                              </h5>
                              <input
                                name="order_id"
                                disabled
                                value={storeData.order_grossprice}
                                style={{ fontSize: "15px" }}
                                type="text"
                                className="form-control"
                                id="round"
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <h5 id="txt" className="text-dark">
                                Order Method:
                              </h5>
                              <input
                                name="order_id"
                                disabled
                                value={storeData.delivery_status}
                                style={{ fontSize: "15px" }}
                                type="text"
                                className="form-control"
                                id="round"
                                required
                              />
                            </div>
                            <div className="form-group col-md-6">
                              <h5 id="txt" className="text-dark">
                                Order Status:
                              </h5>
                              <input
                                name="order_id"
                                disabled
                                value={storeData.order_status_description}
                                style={{
                                  fontSize: "15px",
                                  textTransform: "capitalize",
                                }}
                                type="text"
                                className="form-control"
                                id="round"
                                required
                              />
                            </div>
                            <div className="form-group col-md-12">
                              <h5 id="txt" className="text-dark">
                                Delivery Address:
                              </h5>
                              <input
                                name="order_id"
                                disabled
                                value={storeData.delivery_address}
                                style={{ fontSize: "15px" }}
                                type="text"
                                className="form-control"
                                id="round"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    storeData: state.login.storeData,
    ordersData: state.login.ordersData,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    trackOrder: function (data: any) {
      dispatch(trackOrder(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(index);
