import React from 'react'
import Contact from '../components/Contact'
import Header from '../components/Header'
import Footer from '../components/Footer'

function Newcontact(props:any) {
  return (
    <div className="page-wrapper">
      <Header history={props.history} />
    <Contact/>
    <Footer/>
</div>
  )
}

export default Newcontact
