import React, { Component, isValidElement, useState } from "react";
import { connect } from "react-redux";
import { orderDetail, ordersList } from "../../Redux/Actions/index";
import _ from "lodash";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDOMServer from "react-dom/server";
import "./orders.css";
import jsPDF from "jspdf";
import moment from "moment";
import {
  ordersProps,
  ordersState,
  OrderCardProps,
} from "../Interface/OrdersInterface";
import "./newOrder.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import MailIcon from "@mui/icons-material/Mail";
import QRCode from "react-qr-code";

toast.configure();

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: [357, 500, 560],
  bgcolor: "#fff",
  border: "2px solid #fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
class index extends Component<ordersProps, ordersState, OrderCardProps> {
  myRef: any;

  constructor(props: any) {
    super(props);
    this.state = {
      showDetail: false,
      orderType: "current",
      arrows: false,
      width: "",
      modal: false,
      invoioceToggle: false,
      open: false,
    };
    this.myRef = React.createRef();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // this.props.orderDetail();
    this.props.ordersList();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }
  changeType = (type: any) => {
    this.setState({ orderType: type, showDetail: false });
  };

  handleArrow = () => {
    this.setState({ arrows: !this.state.arrows });
  };

  closeIcon = () => {
    return (
      <div className="close-btn close-s">
        <i className="bi bi-x-lg" onClick={this.CloseModal}></i>
      </div>
    );
  };
  handleModal = (id: any) => {
    this.setState({ modal: true });
    this.showOrderDetail(id);
  };
  CloseModal = () => {
    this.setState({ modal: false });
  };

  handleInvoiceToggle = () => {
    this.setState({ invoioceToggle: !this.state.invoioceToggle });
  };

  showOrderDetail = (id: any) => {
    this.props.orderDetail(id);
  };

  detailOfOrder = () => {
    let { orderDetail1 } = this.props;
    let taxAmount: any = 0;
    if (orderDetail1.order) {
      taxAmount = parseInt(orderDetail1.order.tax_amount);
    }

    let orderStatus: any;
    let orderStatusForCancel: any;
    if (orderDetail1 && orderDetail1.order) {
      if (orderDetail1.order.order_status_code == 1) {
        orderStatus = "We have received your order";
      } else if (orderDetail1.order.order_status_code == 2) {
        orderStatus = "We are making your order";
      } else if (orderDetail1.order.order_status_code == 3) {
        orderStatus = "Our rider is on the way";
      } else if (orderDetail1.order.order_status_code == 4) {
        orderStatus = "Order is delivered";
      } else if (orderDetail1.order.order_status_code == 5) {
        orderStatus = "Order is picked";
      } else if (orderDetail1.order.order_status_code == 6) {
        orderStatus = "Order is canceled";
        orderStatusForCancel = "For more information contact to support";
      } else if (orderDetail1.order.order_status_code == 8) {
        orderStatus = "Order is ready !";
      }
    }

    let width: any;
    if (orderDetail1 && orderDetail1.order) {
      if (orderDetail1.order.order_status_description == "pending") {
        width = "20%";
      } else if (orderDetail1.order.order_status_description == "in kitchen") {
        width = "40%";
      } else if (
        orderDetail1.order.order_status_description == "ready to dispatch"
      ) {
        width = "60%";
      } else if (orderDetail1.order.order_status_description == "dispatched") {
        width = "80%";
      } else if (
        orderDetail1.order.order_status_description == "delivered" ||
        orderDetail1.order.order_status_description == "picked"
      ) {
        width = "100%";
      } else if (orderDetail1.order.order_status_description == "cancelled") {
        width = "0%";
      } else {
        width = "0%";
      }
    }
    let lastElement =
      orderDetail1 &&
      orderDetail1.orderItems &&
      orderDetail1.orderItems.length - 1;

    return (
      <div className="main-order-details">
        <div className="order-number">
          <span className="order-text">Your Order</span>
          <span className="order-number-text">
            {" "}
            Order #
            {orderDetail1 && orderDetail1.order && orderDetail1.order.order_id}
          </span>
        </div>
        <div className="outer-delivery-image">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/deliveryImage.svg"}
          />
        </div>
        <p className="delivery-image-text">
          {orderStatus}
          <br />
          {orderStatusForCancel}
        </p>
        <div className="progress custom-width">
          <div
            className="progress-bar bg-danger"
            role="progressbar"
            style={{ width: width }}
            aria-valuenow={100}
            aria-valuemin={0}
            aria-valuemax={100}
          ></div>
        </div>

        <hr style={{ marginTop: "auto", marginBottom: "27px" }}></hr>

        <div className="d-flex justify-content-between">
          <p className="sub-total">Your Order number</p>
          <p className="sub-details ">
            #{orderDetail1 && orderDetail1.order && orderDetail1.order.order_id}
          </p>
        </div>

        <hr style={{ margin: "auto" }}></hr>
        <div className="scrollModify">
          <div className="d-flex justify-content-between">
            <b className="sub-total mt-1">Products</b>

            <div onClick={this.handleArrow}>
              {this.state.arrows ? (
                <a
                  className="fa fa-angle-up collapsed mt-1"
                  data-toggle="collapse"
                  href="#collapseExample1"
                  role="tablist"
                  aria-expanded="false"
                  aria-controls="collapseExample1"
                  style={{ fontSize: "30px", color: "black" }}
                ></a>
              ) : (
                <a
                  className="fa fa-angle-down collapsed mt-1"
                  data-toggle="collapse"
                  href="#collapseExample1"
                  role="tablist"
                  aria-expanded="false"
                  aria-controls="collapseExample1"
                  style={{ fontSize: "30px", color: "black" }}
                ></a>
              )}
            </div>

            <p></p>
          </div>

          {orderDetail1 &&
            orderDetail1.orderItems &&
            orderDetail1.orderItems.map((item: any, index: any) => {
              let choices: any =
                item.combo_choices && JSON.parse(item.combo_choices);
              let modifiers: any = item.modifiers && JSON.parse(item.modifiers);
              let size: any = item.item_size && JSON.parse(item.item_size);
              return (
                <div className="">
                  <div className="d-flex justify-content-between">
                    <p className="sub-details ">
                      {item.order_item_quantity} x{" "}
                      {item.combo_id ? item.combo_name : item.item_name}
                    </p>
                    <p className="sub-details ml-5">
                      NOK{" "}
                      {item.combo_id
                        ? item.order_item_grossprice * item.order_item_quantity
                        : modifiers && modifiers.length > 0
                        ? size && size.mrp * item.order_item_quantity
                        : item.order_item_grossprice * item.order_item_quantity}
                    </p>
                  </div>

                  {item.combo_id > 0 && (
                    <>
                      <p className="sub-total">Combo Items</p>
                      {choices &&
                        choices.length > 0 &&
                        choices.map((choice: any) => {
                          return (
                            choice.selected && (
                              <div className="d-flex justify-content-between">
                                <p className="sub-details ">
                                  {choice.quantity} x {choice.item_name}
                                </p>
                                <p className="sub-details ml-5">NOK 0</p>
                              </div>
                            )
                          );
                        })}
                    </>
                  )}

                  {
                    <>
                      {modifiers && modifiers.length > 0 && (
                        <p className="sub-total">Modifier Items</p>
                      )}
                      {modifiers &&
                        modifiers.map((modifier: any) => {
                          return (
                            modifier.selected && (
                              <div className="d-flex justify-content-between">
                                <p className="sub-details ">
                                  {modifier.quantity} x {modifier.modifier_name}
                                </p>
                                <p className="sub-details ml-5">
                                  NOK {modifier.modifier_sale_price}
                                </p>
                              </div>
                            )
                          );
                        })}
                    </>
                  }
                </div>
              );
            })}
        </div>

        <hr className="mt-1 mb-1 "></hr>

        {orderDetail1 &&
          orderDetail1.order &&
          orderDetail1.order.tax_type == 1 &&
          orderDetail1.order.delivery_status != "Pickup" &&
          orderDetail1.order &&
          orderDetail1.order.delivery_status != "Qr-Pickup" && (
            <div className="d-flex justify-content-between">
              <p className="sub-details ">Delivery</p>
              <p className="sub-details ml-5">
                NOK{" "}
                {orderDetail1 &&
                  orderDetail1.order &&
                  orderDetail1.order.delivery_fee}
              </p>
            </div>
          )}

        <div className="d-flex justify-content-between">
          <p className="sub-details ">
            {orderDetail1 &&
            orderDetail1.order &&
            orderDetail1.order.tax_type == 0
              ? "VAT"
              : ""}
          </p>
          <p className="sub-details ml-5">
            NOK{" "}
            {orderDetail1 &&
              orderDetail1.order &&
              orderDetail1.order.tax_amount}
          </p>
        </div>

        {orderDetail1.order &&
          orderDetail1.order.delivery_status == "Delivery" && (
            <div className="d-flex justify-content-between">
              <p className="sub-details ">Delivery Fee</p>
              <p className="sub-details ml-5">
                {orderDetail1 &&
                orderDetail1.order &&
                orderDetail1.order.delivery_fee
                  ? orderDetail1.order.delivery_fee
                  : "0"}
              </p>
            </div>
          )}

        {orderDetail1 &&
          orderDetail1.order &&
          orderDetail1 &&
          orderDetail1.order.discount > 0 && (
            <div className="d-flex justify-content-between">
              <p style={{ color: "green" }} className="sub-details ">
                Discount/Voucher
              </p>
              <p style={{ color: "green" }} className="sub-details ml-5">
                NOK{" "}
                {orderDetail1 &&
                  orderDetail1.order &&
                  orderDetail1 &&
                  orderDetail1.order.discount}
              </p>
            </div>
          )}

        {orderDetail1 &&
          orderDetail1.order &&
          orderDetail1 &&
          orderDetail1.order.coupon_discount_value && (
            <div className="d-flex justify-content-between">
              <p style={{ color: "green" }} className="sub-details ">
                Discount/Voucher
              </p>
              <p style={{ color: "green" }} className="sub-details ml-5">
                NOK{" "}
                {orderDetail1 &&
                  orderDetail1.order &&
                  orderDetail1 &&
                  orderDetail1.order.coupon_discount_value}
              </p>
            </div>
          )}

        <div className="d-flex justify-content-between pt-6">
          <p className="sub-total">Total</p>
          <p className="sub-total">
            NOK{" "}
            {orderDetail1 &&
              orderDetail1.order &&
              orderDetail1.order.order_grossprice.toFixed(0)}
          </p>
        </div>
        <hr className="mt-0 mb-1"></hr>
        <div className="d-flex justify-content-between ">
          <p className="sub-total">Paid with</p>
          <p className="sub-details ">
            {orderDetail1 &&
              orderDetail1.order &&
              orderDetail1.order.payment_method}
          </p>
        </div>
        <div className="d-flex justify-content-between"></div>
      </div>
    );
  };

  render() {
    if (!sessionStorage.token) {
      return (
        <Redirect to={{ pathname: "/login", search: window.location.search }} />
      );
    }
    let { ordersData, orderDetail1 } = this.props;
    let allOrders: any = ordersData;
    let orderDetail: any = orderDetail1;
    return (
      <main
        className="main mobile-order-div"
        style={{ backgroundColor: "#f7f7f7" }}
      >
        <div className="container newOrder">
          <div className="back-btn">
            <div className="d-flex flex-row justify-content-start align-items-center mb-2">
              <i
                className="bi bi-arrow-left"
                style={{ fontSize: "20px", marginRight: "10px" }}
              ></i>
              <a href="/">
                {" "}
                <span className="back-menu-btn ms-3">Back to Menu</span>
              </a>
            </div>
          </div>

          <div className="main-section">
            <h1 className="heading-tp">My Orders</h1>
            <div className="order_card_warpper">
              {allOrders.length > 0 ? (
                allOrders.map((order: any) => (
                  <OrderCard
                    orderPlaced={""}
                    orderStatus={""}
                    key={order.order_id}
                    {...order}
                    handleModal={(e: any) => this.handleModal(e)}
                  />
                ))
              ) : (
                <div className="d-flex flex-column justify-content-center align-items-center mt-5">
                  <img
                    src={process.env.PUBLIC_URL + "/empty.png"}
                    width={150}
                    height={150}
                    className="ml-5"
                  />
                  <span className="empty_title mt-2">
                    You have no orders to display . Start ordering Now!
                  </span>
                  <a href="/" className="empty_button mt-2">
                    Start Ordering
                  </a>
                </div>
              )}
            </div>
          </div>
          {orderDetail && orderDetail.order && (
            <>
              <Modal
                onClose={this.CloseModal}
                open={this.state.modal}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
              >
                <Box
                  sx={{
                    ...style,
                    width: [357, 560, 560],
                    border: "none",
                    borderRadius: "10px",
                  }}
                >
                  <div className="">
                    <div className="d-flex flex-column detial-modal-padding">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex">
                          <p className="text-left">Order ID:</p>
                          <p className="text-right">
                            {orderDetail.order.order_id}
                          </p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-evenly flex-row text-place-right">
                        <div className="d-flex flex-row">
                          <p className="text-left">Order date:</p>
                          <p className="text-right">
                            {orderDetail.order.date_created}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="close-modal-detail"
                      onClick={this.CloseModal}
                    >
                      <i className="bi bi-x"></i>
                    </div>
                  </div>
                  {/* <hr className="py-2" /> */}
                  <div className="custom-modal-body ">
                    <div className="d-flex flex-column ">
                      <div className="d-flex flex-column">
                        {/* <div className="d-flex flex-row justify-content-between section_custom">
                      <span className="text-left">Status</span>
                      <span className="text-right special-text">Completed</span>
                    </div> */}
                        {/* <div className="d-flex flex-row justify-content-between section_custom">
                      <span className="text-left">
                        Total <sub>(inc VAT)</sub>
                      </span>
                      <span className="text-right ">
                        Kr {orderDetail.order.order_grossprice}
                      </span>
                    </div> */}
                      </div>
                      <hr className="hr-class" />
                      <div className="d-flex flex-column">
                        <span className="sec_title section_custom">
                          Order items
                        </span>
                        <div>
                          {orderDetail &&
                            orderDetail.order &&
                            orderDetail.order.order_items &&
                            orderDetail.order.order_items.map(
                              (item: any, index: any) => (
                                <div key={index}>
                                  <div className="d-flex flex-row justify-content-between section_custom">
                                    <span className="text-left sec_title">
                                      {`${item.order_item_quantity} x ${item.item_name}`}
                                    </span>
                                    <span className="text-right price">{`Kr ${item.order_item_grossprice}`}</span>
                                  </div>
                                  {item.modifiers && (
                                    <div
                                      className="d-flex flex-column"
                                      style={{ gap: "10px" }}
                                    >
                                      {item.modifiers.map(
                                        (extra: any, extraIndex: any) => (
                                          <div
                                            key={extraIndex}
                                            className="d-flex flex-row justify-content-between"
                                          >
                                            <span className="text-items-left">{`${extra.quantity}x ${extra.modifier_name}`}</span>
                                            <span className="text-items-right">{`kr ${extra.modifier_sale_price}`}</span>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      </div>
                      <hr className="hr-class" />
                      <div className="pt-2">
                        <span className="sec_title section_custom">
                          Order Details
                        </span>
                        <div className="d-flex flex-column">
                          <div className="d-flex flex-row justify-content-between mb-1">
                            {/* <span className="text-left">Instructions</span>
                        <span className="text-right">
                          {orderDetail.order.special_inst}
                        </span> */}
                          </div>
                          <div className="d-flex flex-row justify-content-between section_custom">
                            <span className="text-left">Order Mode</span>
                            <span className="text-right ">
                              {orderDetail.order.order_mode}
                            </span>
                          </div>
                          <div className="d-flex flex-row justify-content-between section_custom">
                            <span className="text-left">
                              {orderDetail.order.order_mode === "Dinein"
                                ? "Table No"
                                : "Pickup Time"}
                            </span>
                            {/* <span className="text-right ">
                              {orderDetail.order.order_mode === "Dinein"
                                ? orderDetail.order.table_number
                                : orderDetail.order.future_date.split(' ')[1]}
                            </span> */}
                            <span className="text-right">
                              {orderDetail.order.order_mode === "Dinein"
                                ? orderDetail.order.table_number
                                : orderDetail.order.future_date ===
                                  "Invalid date"
                                ? "As soon as possible"
                                : orderDetail.order.future_date.split(" ")[1]}
                            </span>
                          </div>
                        </div>
                      </div>
                      <hr className="hr-class" />
                      <div className="d-flex flex-column section_custom">
                        <span className="sec_title">Instructions</span>
                        <span className="mt-1 instuction_checkout">
                          {orderDetail.order.special_inst}
                        </span>
                      </div>
                      <hr className="hr-class" />
                      <div className="pt-2">
                        <span className="sec_title section_custom">Price</span>
                        <div className="d-flex flex-column">
                          {/* <div className="d-flex flex-row justify-content-between section_custom">
                        <span className="text-left">Item total</span>
                        <span className="text-right">Kr 113</span>
                      </div> */}
                          <div className="d-flex flex-row justify-content-between section_custom">
                            <span className="text-left">
                              VAT <sub>({orderDetail.order.tax_percent}%)</sub>
                            </span>
                            <span className="text-right ">
                              Kr {orderDetail.order.tax_amount}
                            </span>
                          </div>
                          <div className="d-flex flex-row justify-content-between section_custom">
                            <span className="text-left">Total</span>
                            <span className="text-right price">
                              Kr {orderDetail.order.order_grossprice}
                            </span>
                          </div>

                          {/* <div className="d-flex flex-row justify-content-between section_custom">
                        <span className="text-left">Payment</span>
                        <span className="text-right ">kr 160</span>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Modal>
            </>
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    ordersData: state.login.ordersData,
    orderDetail1: state.login.orderDetail1,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ordersList: function () {
      dispatch(ordersList());
    },
    orderDetail: function (id: any) {
      dispatch(orderDetail(id));
    },
  };
};

const OrderCard = (props: any) => {
  const [invoiceToggle, setInvoiceToggle] = useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value, setValue] = useState(
    `https:order.palacecafe.no/login?param=true`
  );
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(256);

  const handleInvoiceToggle = () => {
    setInvoiceToggle(!invoiceToggle);
  };

  const generatePDF = (pdfTemplate: any) => {
    const pdf = new jsPDF();
    pdf.text(pdfTemplate, 10, 10);
    pdf.save("slip.pdf");
  };

  const CustomPdfTemplate = () => {
    return (
      <div className="d-flex flex-column main-order">
        <div className="d-flex justify-content-between align-items-center header-section">
          <p className="right-text my-0 py-0 px-0">
            Order ID: <span className="left-text">{props.order_id}</span>
          </p>
        </div>
        <div className="d-flex flex-row mt-0 header-section mobile-order">
          <p className="right-text py-0 my-0 px-0">
            Order date:{" "}
            <span className="left-text">
              {moment(props.date_created).format("YYYY-MM-DD HH:mm")}
            </span>
          </p>
          {/* <p className="right-text py-0 my-0 ">
          Order placed: <span className="left-text">{props.date_created}</span>
        </p> */}
        </div>
        <hr className="" />
        <div className="d-flex flex-column ms-0">
          <div className="d-flex justify-content-between section_padding">
            <p className="py-0 my-0 px-0 right-text">Order Mode</p>
            <p className="py-0 my-0 px-0 left-text">{props.delivery_status}</p>
          </div>
          <div className="d-flex justify-content-between section_padding">
            <p className="py-0 my-0 px-0 right-text">
              Total <sub>(inc VAT)</sub>
            </p>
            <p className="py-0 my-0 px-0 left-text">{props.order_grossprice}</p>
          </div>
        </div>
        <hr className="" />
      </div>
    );
  };

  const pdfContent = ReactDOMServer.renderToString(<CustomPdfTemplate />);

  return (
    <>
      <div className="d-flex flex-column main-order">
        <div className="d-flex justify-content-between align-items-center header-section">
          <p className="right-text my-0 py-0 px-0">
            Order ID: <span className="left-text">{props.order_id}</span>
          </p>
          <div className="">
            {/* <i className="bi bi-journals"></i>
          <span>Invoice</span>
          <i className="bi bi-chevron-down" onClick={handleInvoiceToggle}></i>
          {invoiceToggle && (
            <div className="invoice-dropdown d-flex flex-column">
              <div
                className="d-flex align-items-center text-box"
                style={{ cursor: "pointer" }}
              >
                <MailIcon style={{ fontSize: "20px", color: "black" }} />
                <span className="font-dropdown">Sent to Email</span>
              </div>
              <div
                className="d-flex align-items-center text-box"
                style={{ cursor: "pointer" }}
                onClick={()=> generatePDF(pdfContent)}
              >
                <PictureAsPdfIcon
                  style={{ fontSize: "20px", color: "black" }}
                />
                <span className="font-dropdown">Download</span>
              </div>
            </div>
          )} */}
          </div>
        </div>
        <div className="d-flex flex-row mt-0 header-section mobile-order">
          <p className="right-text py-0 my-0 px-0">
            Order date:{" "}
            <span className="left-text">
              {moment(props.date_created).format("YYYY-MM-DD HH:mm")}
            </span>
          </p>
          {/* <p className="right-text py-0 my-0 ">
          Order placed: <span className="left-text">{props.date_created}</span>
        </p> */}
        </div>
        <hr className="" />
        <div className="d-flex flex-column ms-0">
          <div className="d-flex justify-content-between section_padding">
            <p className="py-0 my-0 px-0 right-text">Order Mode</p>
            <p className="py-0 my-0 px-0 left-text">{props.delivery_status}</p>
          </div>
          {/* <div className="d-flex justify-content-between section_padding">
          <p className="py-0 my-0 px-0 right-text">Order Category</p>
          <p className="py-0 my-0 px-0 left-text">{props.orderCategory}</p>
        </div> */}
          <div className="d-flex justify-content-between section_padding">
            <p className="py-0 my-0 px-0 right-text">
              Total <sub>(inc VAT)</sub>
            </p>
            <p className="py-0 my-0 px-0 left-text">{props.order_grossprice}</p>
          </div>
        </div>
        <hr className="" />
        <div className="d-flex justify-content-around mt-md-1 mt-1">
          <button
            className="detail-btn"
            onClick={() => props.handleModal(props.order_id)}
          >
            View Details
          </button>
          <button className="order-btn" onClick={handleOpen}>
            Track Order
          </button>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column ">
            <div className="d-flex mb-2">
              <span className="modal-heading">Download Application</span>
              <div className="close-btn-order" onClick={handleClose}>
                <i className="bi bi-x"></i>
              </div>
            </div>
            <hr className="my-2" />
            <div className="d-flex flex-column justify-content-center align-items-center mt-2 qr-image-sec">
              {value && (
                <QRCode
                  value={value}
                  bgColor={back}
                  fgColor={fore}
                  size={size}
                />
              )}
              <span className="qr-code-text mt-1">Scan QR Code</span>
            </div>
            <div className="d-md-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-none">
              <a href="https://apps.apple.com/no/app/palace-cafe/id6471288539">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/apple.png"}
                  alt="qr code"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=no.palacecafeapp.etcnor&pcampaignid=web_share">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/play.png"}
                  alt="qr code"
                />
              </a>
            </div>
            <div className="d-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-md-none">
              <a href="https://apps.apple.com/no/app/palace-cafe/id6471288539">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/apple.png"}
                  alt="qr code"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=no.palacecafeapp.etcnor&pcampaignid=web_share">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/play.png"}
                  alt="qr code"
                />
              </a>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
