import React from 'react'
import NewHeader from '../components/Header'
import Footer from '../components/Footer'
// import Orders from '../components/orders'
// import Orders from '../components/Orders'
import OrderComponent from "../components/orders/index";

function NewMenu(props:any) {
  return (
    <>
    <NewHeader history={props.history} />
    <OrderComponent showDetail={undefined}/>
    <Footer/>
    </>
  )
}

export default NewMenu  