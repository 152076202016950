import React, { Component } from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './bannerModal.css'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { connect } from 'react-redux';

toast.configure();

class BannerModal extends Component<{onCloseBanner?: any;openBanner?: any}, {}> {
  constructor(props: any) {
    super(props);
    this.state = {
    
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
  }
  


  UNSAFE_componentWillReceiveProps(nextProps: any) {
    
  }

  closeIcon = () => {
    return <span style={{position:"fixed","height":"40px","width":"40px"}}> <img src={process.env.PUBLIC_URL + "/assets/images/crosa.svg"} style={{ width: '100%' }} /></span>
  }
  CloseComponent = () => {

    this.props.onCloseBanner()

  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.openBanner}
          onClose={this.CloseComponent}
          focusTrapped={false}
          closeOnOverlayClick={false}
          closeIcon={this.closeIcon()}
          closeOnEsc={false}
          classNames={{ modal: "modalSettingBanner" }}
          >
          
          <section className='inner-container' style={{fontFamily:"Playfair Display"}}>
          <div className="row">
            <div className="col-12">
            <div className="row">
            <div className="col-lg-12 col-md-5 col-sm-2">
            <div className="menu-selection-right">
              <div className="right-text">
                <p>Download App</p>
                <div className="download-app">
                {/* <a href="app link on google play"  target="_blank" title="" > */}
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/PlayStore.svg"
                    }
                    style={{ cursor: "pointer" }}
                  />
                  {/* </a> */}
                  {/* <a href="app link on apple store"  target="_blank" title="" > */}
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/AppStore.svg"}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                  {/* </a> */}
                </div>
              </div>
              {/* <div className="left-image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/handholdingImg.svg"
                  }
                  height={245}
                />
              </div> */}
            </div>
            </div>
            </div>
            </div>
            <div className="product-modal-more">
              <div className="product-modal-body">
              </div>
            </div>
            </div>
          </section>
          
        </Modal>
      </div >
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(BannerModal);

