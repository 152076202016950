import React, { Component } from "react";
import { Link } from "react-router-dom";
import { MdOutlineShoppingCart } from "react-icons/md";
import { connect } from "react-redux";
import { PaymentProps } from "../Interface/loginInterface";
import "./checkOut.css";
import _ from "lodash";
import { API_URL, priceunit } from "../../ApiManager/client-config";

import {
  applyCoupon,
  getCart,
  getTimezone,
  resetCoupon,
  SaveDiscountValue,
  saveOrder,
  savePaymentId,
  savePaymentMethod,
  SaveTaxValue,
} from "../../Redux/Actions/index";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Helmet } from "react-helmet";
import axios from "axios";
import { RedirectCheckout } from "@bambora/checkout-sdk-web";
import Swal from "sweetalert2";
import moment from "moment";

class Payment extends Component<
  PaymentProps,
  {
    open: any;
    netWork: any;
    cardResponse: any;
    lat: any;
    lng: any;
    // cardUniqueOrderId: any;
    coupon_code: any;
    discountFlag: boolean;
    tableNumberStorage: any;
    isChecked: boolean;
    setTime: any;
    closeTime: any;
    timeOver: any;
    orderTime: any;
  }
> {
  item: any;
  price: any;
  obj: any;
  appliedCoupon: boolean;
  codeExeCount: number;
  session: any = "";
  checkout: RedirectCheckout | any;
  constructor(props: any) {
    super(props);
    this.state = {
      open: false,
      netWork: "",
      cardResponse: "",
      lat: "",
      lng: "",
      // cardUniqueOrderId: "",
      coupon_code: "",
      discountFlag: false,
      tableNumberStorage: 0,
      isChecked: false,
      setTime: "",
      closeTime: false,
      timeOver: false,
      orderTime: "",
    };
    this.appliedCoupon = false;
    this.codeExeCount = 0;
  }
  AlfalahScript: any;

  calcItemTotal = (carts: any) => {
    let sum = 0;
    carts.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
    });
    return sum;
  };

  checkboxHandler = () => {
    this.setState({ isChecked: true });
  };

  calcTotalTax = () => {
    let totalTaxAmount: number = 0; // Use number type for better precision
    let orderType = this.props.orderType;

    if (orderType) {
      let data: any = sessionStorage.getItem("cart");
      let cart: any = JSON.parse(data);
      let tax_rate: number = parseFloat(this.props.taxRate); // Ensure tax_rate is a number

      for (let index = 0; index < cart.length; index++) {
        const element = cart[index].item;
        let taxAmount: any = 0;
        // let net_price: number = element.price / (1 + tax_rate);
        let net_price: any =
          (Number(cart[index].quantity) * parseFloat(element.price)) /
          (1 + Number(tax_rate));
        let net_p: any = parseFloat(net_price).toFixed(2);
        taxAmount = Number(cart[index].quantity) * element.price - net_p;
        taxAmount = parseFloat(taxAmount).toFixed(2);
        // taxAmount = element.price - net_price;

        // totalTaxAmount += parseFloat(cart[index].quantity) * taxAmount;
        totalTaxAmount += Number(taxAmount);

        if (cart[index].item.modifiers) {
          if (typeof cart[index].item.modifiers == "object") {
            Object.keys(cart[index].item.modifiers).map((key) => {
              cart[index].item.modifiers[key].map((item: any, _index: any) => {
                if (item.selected == true) {
                  // let mod_net_price: number =
                  //   item.modifier_sale_price / (1 + tax_rate);
                  let modTaxAmount: number = 0;
                  // item.modifier_sale_price - mod_net_price;
                  let mod_net_price: any =
                    (Number(cart[index].quantity) *
                      parseFloat(item.modifier_sale_price)) /
                    (1 + Number(tax_rate));
                  let mod_net_p: any = parseFloat(mod_net_price).toFixed(2);
                  modTaxAmount =
                    Number(cart[index].quantity) * item.modifier_sale_price -
                    Number(mod_net_p);
                  totalTaxAmount += Number(modTaxAmount);
                }
              });
            });
          }
        }
      }
    }

    // Round totalTaxAmount to two decimal places
    totalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

    this.props.SaveTaxValue(totalTaxAmount);

    return totalTaxAmount;
  };

  componentDidMount() {
    console.log("here is value of order", this.props.orderTime);
    window.scrollTo(0, 0);
    document.addEventListener("cancelEvent", (evt: any) => {
      if (this.state.netWork == true || this.state.cardResponse !== "cancel") {
        this.setState({ cardResponse: "cancel" });
        toast.error("Payment is Cancelled", {
          hideProgressBar: false,
          autoClose: 3000,
        });
        $("body").addClass("modal-custom-style");
      }
    });
    document.addEventListener("errorEvent", (event) => {
      this.setState({ cardResponse: "error" });
      toast.error("Payment is Unsuccessful", {
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
    document.addEventListener("errorEvent", (event) => {
      this.setState({ cardResponse: "error" });
      toast.error("Payment is Unsuccessful", {
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
    document.addEventListener("completeEvent", (event) => {
      if (this.state.cardResponse != "complete") {
        toast.success("Payment Successfully Completed", {
          hideProgressBar: true,
          autoClose: 3000,
        });
        this.setState({ cardResponse: "complete" });

        this.confirmOrder();
      }
    });
  }

  calcTotal = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0;
    let sum: any = 0;
    let sum2: any = 0;
    data.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
      sum2 += parseInt(item.totalItemPrice);
    });
    let discountValue: any = 0;
    if (_.isEmpty(coupon)) {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    if (this.props.orderType == "Delivery") {
      if (!_.isEmpty(this.props.storeSelected)) {
        sum =
          this.props.pos_fee > 0
            ? sum -
              this.couponDiscount +
              deliveryfee +
              parseInt(this.props.pos_fee)
            : sum - this.couponDiscount + deliveryfee;
      } else {
        sum =
          this.props.pos_fee > 0
            ? sum - this.couponDiscount + parseInt(this.props.pos_fee)
            : sum - this.couponDiscount;
      }
    } else if (
      this.props.orderType == "Pickup" ||
      this.props.orderType == "Qr-Pickup"
    ) {
      sum =
        this.props.pos_fee > 0
          ? sum - this.couponDiscount + parseInt(this.props.pos_fee)
          : sum - this.couponDiscount;
    }
    this.props.SaveDiscountValue(this.couponDiscount);
    this.cartTotal = Math.round(sum);
    if (this.props.taxDataCash.tax_type == 1) {
      sum = parseInt(sum) + this.totalTax;
    } else {
      sum = parseInt(sum);
    }
    let finalSum = _.isNaN(sum) ? sum2 : sum;
    return Math.round(finalSum);
  };
  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item.combo) {
        if (item.combo.discount_price) {
          discount +=
            item.quantity *
            Math.round(item.combo.combo_mrp_price - item.combo.discount_price);
        }
      } else {
        if (item.selectedsize.discount_price) {
          discount +=
            item.quantity *
            Math.round(
              item.selectedsize.mrp - item.selectedsize.discount_price
            );
        }
      }
    });
    return discount;
  };
  calcTotalDiscount = (discount: any) => {
    return discount;
  };

  handleChange = (event: any) => {
    this.setState({ coupon_code: event.target.value });
  };
  tableHandler = (e: any) => {
    sessionStorage.setItem("table_number", e.target.value);
  };
  handleConformTableClick = () => {
    Swal.fire({
      title: "Great!",
      text: "Your table is confirmed.",
      icon: "success",
    });
  };
  getModifierPrice = (modifiers: any, carts: any, quantity: number): any => {
    let value = Object.keys(modifiers).reduce((count, key) => {
      return (
        count +
        carts.item.modifiers[key].reduce((innerCount: number, item: any) => {
          return (
            innerCount + (item.selected ? Number(item.modifier_sale_price) : 0)
          );
        }, 0)
      );
    }, 0);
    return quantity * value;
  };
  cartDetail = (data: any) => {
    let tax_rate: any = sessionStorage.getItem("tax_rate");
    if (!_.isEmpty(data)) {
      let cartData: any[] = JSON.parse(data);
      let area: any;

      if (cartData.length > 0) {
        return (
          <div
            className="paddingMobileCustom"
            style={{
              boxShadow: "1px 1px 3px 1px rgba(222,222,222,0.75)",
              // padding: "40px 40px",
              backgroundColor: "white",
            }}
          >
            <div>
              <div className="d-flex justify-content-between w-100 align-items-center">
                <h2 className="order-detail">Your Order</h2>
                <div className="button_wrapper_box">
                  <button
                    type="button"
                    className={`btn w-100 mb-2 text-center btn-lg btn-block cart-btn place-order-btn  ${
                      this.props.closeTime || this.props.timeOver
                        ? "cart_button_disbale_"
                        : ""
                    }`}
                    onClick={() => this.openCardModal(this.calcTotal(cartData))}
                  >
                    Continue to payment
                  </button>
                </div>
              </div>

              {cartData.map((carts, indexes) => (
                <>
                  <div className="d-flex justify-content-between">
                    <p
                      className="location-cart-product-name"
                      style={{ fontFamily: "Playfair Display", width: "70%" }}
                    >
                      {carts.quantity}
                      {" x "}
                      {!_.isEmpty(carts.item) && carts.item.item_name}{" "}
                    </p>
                    <p className="location-cart-product-name">
                      {carts.selectedsize ? (
                        carts.selectedsize.discount_price &&
                        carts.selectedsize.order_channel == "online" ? (
                          <>
                            {`${
                              Math.round(carts.selectedsize.discount_price) *
                              carts.quantity
                            } ${" NOK"}`}
                          </>
                        ) : (
                          <>
                            {carts.selectedsize.orderModePrice[0].mrp *
                              carts.quantity +
                              this.getModifierPrice(
                                carts.item.modifiers,
                                carts,
                                carts.quantity
                              ) +
                              " NOK"}
                          </>
                        )
                      ) : (
                        Math.round(carts.price) * carts.quantity + " NOK"
                      )}
                    </p>
                  </div>
                  {!_.isEmpty(carts.item) &&
                    !_.isEmpty(carts.item.modifiers) && (
                      <p className="location-ndsub-details">
                        {Object.keys(carts.item.modifiers).map(
                          (key: any, index) => {
                            return carts.item.modifiers[key].map(
                              (item: any, index: any) => {
                                return (
                                  item.selected === true && (
                                    <div className="d-flex justify-content-between ">
                                      <li>
                                        {" "}
                                        {item.modifier_name +
                                          " x " +
                                          item.quantity}
                                      </li>
                                      <p>
                                        {item.modifier_sale_price *
                                          item.quantity +
                                          " NOK"}
                                      </p>
                                    </div>
                                  )
                                );
                              }
                            );
                          }
                        )}
                      </p>
                    )}

                  {<></>}
                </>
              ))}
              <hr className=""></hr>
              {this.props.special_ins.length > 0 && (
                <div className="d-flex flex-column instruction-container">
                  <span className="insturction-special-heading">
                    Instructions
                  </span>
                  <span className="instruction-para">
                    {this.props.special_ins}
                  </span>
                </div>
              )}
              <hr className=""></hr>
            </div>
            {this.props.orderType && (
              <div
                className="d-flex justify-content-between"
                // style={{ marginBottom: "-15px", marginTop: "-45px" }}
              >
                <div className="location-sub-total-left">Order Mode</div>
                <div className="location-sub-total">{this.props.orderType}</div>
              </div>
            )}
            {this.props.orderType && (
              <div className="d-flex justify-content-between">
                <div className="location-sub-total-left">
                  {this.props.orderType === "Dinein" && this.props.tabNumber
                    ? "Table Number"
                    : "Time"}
                </div>
                <div className="location-sub-total">
                  {this.props.orderType === "Dinein" && this.props.tabNumber ? (
                    <span>{this.props.tabNumber}</span>
                  ) : this.props.orderType === "Pickup" ? (
                    <>
                      {this.props.preOrLater === "orderNow" ? (
                        <span>{this.props.setTime}</span>
                      ) : (
                        <>
                          {/* {this.props.futureDate && (
                            <span>{`${moment(this.props.futureDate).format(
                              "Do MMM"
                            )} / `}</span>
                          )} */}
                          {this.props.futureTime && (
                            <span>{this.props.setTime}</span>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between ">
              <div className="location-sub-total-left">Subtotal</div>
              <div className="location-sub-total">
                {this.calcItemTotal(cartData) - this.calcTotalTax() + " NOK"}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              {this.props.orderType == "Pickup" ? (
                <div className="location-sub-total-left">
                  VAT: {"(" + 0.15 * 100 + "%)"}
                </div>
              ) : (
                <div className=" location-sub-total-left">
                  VAT: {"(" + tax_rate * 100 + "%)"}
                </div>
              )}
              <div className=" location-sub-total">
                {_.isNaN(this.calcTotalTax())
                  ? 0 + " NOK"
                  : this.calcTotalTax() + " NOK"}
              </div>
            </div>

            <hr className="mt-2 mb-2"></hr>

            <div className="d-flex justify-content-between pt-6">
              <p
                className="location-total"
                style={{ fontFamily: "Playfair Display" }}
              >
                Total
              </p>
              <p className="location-total" style={{ marginTop: "8px" }}>
                {this.calcItemTotal(cartData) + " NOK"}
              </p>
            </div>
            <h1 style={{ fontSize: "12px", marginTop: "-15px" }}>(ink. MVA)</h1>
            {
              !_.isEmpty(this.props.coupon) && (
                <div className="d-flex justify-content-between">
                  <p
                    style={{ color: "#d60000", fontWeight: "bold" }}
                    className="sub-details ml-5"
                  >
                    Discount/Voucher ({this.props.coupon.coupon_code})
                  </p>
                  <p
                    style={{ color: "#d60000", fontWeight: "bold" }}
                    className="sub-details ml-5"
                  >
                    {this.props.coupon.coupon_value !== 0
                      ? `-${
                          priceunit +
                          Math.round(
                            this.calcTotalDiscount(this.props.discountAmount)
                          )
                        }`
                      : `-${
                          priceunit +
                          Math.round(
                            this.calcTotalDiscount(this.props.discountAmount)
                          )
                        }`}
                  </p>
                </div>
              )
              // )
            }
            {this.calculateOrderDiscount(cartData) > 0 && (
              <div className="d-flex justify-content-between">
                <p
                  style={{ color: "#d60000", fontWeight: "bold" }}
                  className="sub-details ml-5"
                >
                  Your Discount
                </p>
                <p
                  style={{ color: "#d60000", fontWeight: "bold" }}
                  className="sub-details ml-5"
                >
                  {priceunit + this.calculateOrderDiscount(cartData)}
                </p>
              </div>
            )}
            <hr className=""></hr>
          </div>
        );
      } else {
        <div>Empty Cart</div>;
      }
    } else {
      <div>Empty Cart</div>;
    }
  };
  openCardModal = (order_grossprice: any) => {
    this.paymentJourney(order_grossprice);
  };
  placeOrderBtn = (data: any) => {
    if (!_.isEmpty(data)) {
      let cartData: any[] = JSON.parse(data);
      if (cartData.length > 0) {
        return (
          <div className="">
            <div>
              <div className="d-flex mt-2 align-items-center">
                <input
                  type="checkbox"
                  className=""
                  checked={this.state.isChecked}
                  onChange={this.checkboxHandler}
                  // onClick={checkboxHandler}
                  required
                />
                <p className="ml-2 m-0">
                  I accept the &nbsp;
                  <span>
                    <Link
                      className="privacypolicy "
                      to={{
                        pathname: "/conditions",
                        search: window.location.search,
                      }}
                    >
                      terms and conditions
                    </Link>
                  </span>
                </p>
              </div>
              {this.state.netWork == false ? (
                <button
                  type="button"
                  className={`btn  btn-lg btn-block cart-btn place-order-btn mt-2 ${
                    this.state.isChecked ? "" : "button-cart-disable"
                  } ${
                    this.props.closeTime || this.props.timeOver
                      ? "cart_button_disbale_"
                      : ""
                  }`}
                  onClick={() =>
                    this.props.paymentMethod == "Card"
                      ? this.openCardModal(this.calcTotal(cartData))
                      : this.confirmOrder()
                  }
                >
                  <span
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="cart-quantity">
                      <span>{cartData.length}</span>
                    </div>
                    <span className="cart-checkout-text">
                      <span style={{ fontSize: "23px", marginRight: "5px" }}>
                        <MdOutlineShoppingCart />
                      </span>
                      Place Order
                    </span>
                    <span className="cart-final-price">
                      {priceunit + this.calcTotal(cartData)}
                    </span>
                  </span>
                </button>
              ) : (
                <button
                  id=""
                  disabled={this.state.netWork}
                  className="btn  btn-lg btn-block cart-btn place-order-btn"
                >
                  <i className="fa fa-spinner fa-spin"></i>Loading
                </button>
              )}
            </div>
          </div>
        );
      }
    }
  };

  paymentJourney = async (order_grossprice: any) => {
    this.setState({ netWork: true });
    // let uniqueOrderIdd = `${new Date().getTime() + order_grossprice}`;
    // this.setState({ cardUniqueOrderId: uniqueOrderIdd });
    let data: any = sessionStorage.getItem("cart");
    let cart: any = JSON.parse(data);
    let cartItems: any = [];
    let tax_rate: number = this.props.taxRate;
    let totalTaxAmount: number = 0;
    for (let index = 0; index < cart.length; index++) {
      const element = cart[index].item;
      let taxAmount: any = 0;
      let unit_price: any = parseFloat(element.price) / (1 + Number(tax_rate));
      unit_price = parseFloat(unit_price).toFixed(2);
      let net_price: any =
        (Number(cart[index].quantity) * parseFloat(element.price)) /
        (1 + Number(tax_rate));
      let net_p: any = parseFloat(net_price).toFixed(2);
      taxAmount = Number(cart[index].quantity) * element.price - net_p;
      taxAmount = parseFloat(taxAmount).toFixed(2);
      let gross: any = Number(net_p) + Number(taxAmount);
      gross = parseFloat(gross).toFixed(2);
      let obj: any = {
        reference: element.menu_item_id,
        name: element.item_name,
        quantity: cart[index].quantity,
        unit: "kg",
        unitPrice: parseInt((Number(unit_price) * 100).toFixed(0)),
        // unitPrice: Number(unit_price) * 100,
        taxRate: tax_rate * 100,
        // taxAmount: Number(taxAmount) * 100,
        taxAmount: parseInt((Number(taxAmount) * 100).toFixed(0)),
        grossTotalAmount: parseInt((Number(gross) * 100).toFixed(0)),
        // grossTotalAmount: Number(gross) * 100,
        netTotalAmount: parseInt((Number(net_p) * 100).toFixed(0)),
        // netTotalAmount: Number(net_p) * 100,
      };
      totalTaxAmount += Number(taxAmount);
      console.log(obj);
      cartItems.push(obj);
      if (cart[index].item.modifiers) {
        //To find the selected modifiers
        if (typeof cart[index].item.modifiers == "object") {
          //modifiers from the direct cart
          Object.keys(cart[index].item.modifiers).map((key) => {
            cart[index].item.modifiers[key].map((item: any, _index: any) => {
              if (item.selected == true) {
                let modTaxAmount: any = 0;
                let mod_unit_price: any =
                  parseFloat(item.modifier_sale_price) / (1 + Number(tax_rate));
                mod_unit_price = parseFloat(mod_unit_price).toFixed(2);
                let mod_net_price: any =
                  (Number(cart[index].quantity) *
                    parseFloat(item.modifier_sale_price)) /
                  (1 + Number(tax_rate));
                let mod_net_p: any = parseFloat(mod_net_price).toFixed(2);
                modTaxAmount =
                  Number(cart[index].quantity) * item.modifier_sale_price -
                  Number(mod_net_p);
                modTaxAmount = parseFloat(modTaxAmount).toFixed(2);
                console.log("modTaxAmount", modTaxAmount);
                let mod_gross: any = Number(mod_net_p) + Number(modTaxAmount);
                mod_gross = parseFloat(mod_gross).toFixed(2);
                let modifier_obj = {
                  reference: item.modifier_id,
                  name: item.mod_group_name,
                  quantity: parseInt(cart[index].quantity),
                  unit: "kg",
                  unitPrice: parseInt(
                    (Number(mod_unit_price) * 100).toFixed(0)
                  ),
                  taxRate: tax_rate * 100,
                  taxAmount: parseInt((Number(modTaxAmount) * 100).toFixed(0)),
                  grossTotalAmount: parseInt(
                    (Number(mod_gross) * 100).toFixed(0)
                  ),
                  netTotalAmount: parseInt(
                    (Number(mod_net_p) * 100).toFixed(0)
                  ),
                };
                console.log("modifier_obj", modifier_obj);
                totalTaxAmount += Number(modTaxAmount);
                cartItems.push(modifier_obj);
              }
            });
          });
        }
      }
    }
    console.log("totalTaxAmount", totalTaxAmount.toFixed(2));
    let data1: any = await this.confirmOrder().then(async (order_id: any) => {
      if (order_id) {
        if (cartItems.length > 0) {
          let data: any = {
            order: {
              items: cartItems,
              amount: parseInt(order_grossprice) * 100,
              currency: "NOK",
              reference: order_id.toString(),
            },
            checkout: {
              url: "",
              integrationType: "hostedpaymentpage",
              returnUrl: "https://order.palacecafe.no/accept",
              cancelUrl: "",
              consumer: "",
              termsUrl: "https://order.palacecafe.no/conditions",
              merchantTermsUrl: "",
              shippingCountries: "",
              shipping: "",
              consumerType: {
                default: "",
                supportedTypes: "",
              },
              charge: true,
              publicDevice: true,
              merchantHandlesConsumerData: true,
              appearance: {
                displayOptions: {
                  showMerchantName: true,
                  showOrderSummary: true,
                },
                textOptions: {
                  completePaymentButtonText: "string",
                },
              },
              countryCode: "",
            },
            merchantNumber: "100057868",
            notifications: {
              webHooks: "",
            },
            subscription: "",
            unscheduledSubscription: "",
            paymentMethodsConfiguration: [
              {
                name: "MasterCard",
                enabled: true,
              },
              {
                name: "Visa",
                enabled: true,
              },
              {
                name: "Vipps",
                enabled: true,
              },
            ],
            paymentMethods: [
              {
                name: "CARD",
                fee: "",
              },
              // {
              //   name: "WALLET",
              //   fee: "",
              // },
            ],
            myReference: order_id.toString(),
          };
          // Payment gateway
          await axios
            .post(`${API_URL}/customer/netsPayment`, data, {
              headers: { Authorization: "Bearer " + sessionStorage.token },
            })
            .then(async (res) => {
              if (res.data.successResponse.paymentId) {
                if (
                  res &&
                  res.data &&
                  res.data.successResponse &&
                  res.data.successResponse.hostedPaymentPageUrl
                ) {
                  let obj = {
                    order_id: order_id,
                  };
                  await this.props.savePaymentId(
                    res.data.successResponse.paymentId,
                    obj
                  );
                  window.location.href =
                    res.data.successResponse.hostedPaymentPageUrl;
                }
              } else {
                console.log("Expected a paymentId"); // No paymentId provided,
                // window.location = 'cart.html';         // go back to cart.html
              }
            });
        }
      }
    });

    return order_grossprice;
  };

  confirmOrder = async () => {
    let area = sessionStorage.getItem("area_details");
    let sessionStore: any = sessionStorage.getItem("storeSelected");
    let orderType = "Pickup";
    let parsedStore: any = sessionStore ? JSON.parse(sessionStore) : null;
    let uniqueOrderId = sessionStorage.getItem("uniqueOrderId");
    this.setState({ netWork: true });
    setTimeout(() => {
      this.setState({ netWork: false });
    }, 1500);
    let { taxDataCash } = this.props;
    let data: any = sessionStorage.getItem("cart");
    // let data: any = this.props.cart;
    let tax: any = 0;
    tax = this.props.taxRate * 100;
    let cart: any = JSON.parse(data);
    let discount = 0;
    this.quantity = 0;
    cart.forEach((item: any) => {
      item.tax_percent = tax;
      item.check_number = "";
      if (item) {
        this.quantity += item.quantity;
        let sizearray = JSON.parse(item.item.item_size);
        if (item.selectedsize.discount_price) {
          discount +=
            item.quantity *
            Math.round(
              item.selectedsize.mrp - item.selectedsize.discount_price
            );
        }
      }
    });

    if (tax) {
      cart.forEach((item: any) => {
        if (item) {
          item.tax_amount =
            taxDataCash.tax_type == 1
              ? (item.totalItemPrice * tax) / 100
              : item.totalItemPrice * (parseInt(tax) / (100 + parseInt(tax)));
          if (item.item.modifiers) {
            Object.keys(item.item.modifiers).map((key: any) => {
              item.item.modifiers[key].map((item: any, index: any) => {
                if (item.selected == true) {
                  item.tax_percent = tax;
                  item.tax_amount = true
                    ? (item.modifier_sale_price * tax) / 100
                    : (
                        item.modifier_sale_price *
                        (parseInt(tax) / (100 + parseInt(tax)))
                      ).toFixed(2);
                  item.tax_type = taxDataCash.tax_type;
                }
              });
            });
          }
        }
      });
    }
    this.discount = discount;
    const token = await sessionStorage.getItem("token");
    if (orderType !== "" && !_.isEmpty(this.props.storeSelected)) {
      if (token) {
        let obj: any = {
          order_status_code: 1,
          order_grossprice: this.cartTotal,
          discount: discount,
          order_channel: sessionStorage.getItem("store_id_qr") ? "qr" : "web",
          store_id: parsedStore && parsedStore.store_id,
          branch_code: parsedStore && parsedStore.branch_code,
          trade_zone: this.props.tradeZoneValue && this.props.tradeZoneValue,
          trade_area: this.props.tradeAreaValue && this.props.tradeAreaValue,
          delivery_status: this.props.orderType,
          special_inst: this.props.special_ins,
          payment_method: this.props.paymentMethod,
          uniqueOrderId: uniqueOrderId,
          cartItems: cart,
          check_number: "",
          tax_percent: tax,
          quantity: this.quantity,
          lat: this.state.lat,
          lng: this.state.lng,
          pos_service_fee: this.props.pos_fee,
          "house/street": this.props.houseDetail,
          table_number: sessionStorage.getItem("table_number"),
        };
        const futureDate = sessionStorage.getItem("futureDate");
        if (futureDate) {
          obj.future_status = 1;
          obj.futureDate = JSON.stringify({ futureDate });
        }
        if (!_.isEmpty(this.props.coupon)) {
          obj.coupon = this.props.coupon;
          obj.coupon_redeem = 1;
          obj.coupon_discount_value = this.props.discountAmount;
        }
        // if (this.props.paymentMethod === "Card") {
        //   obj.cardOrderId = this.state.cardUniqueOrderId;
        // }
        if (orderType == "Dinein" || this.props.orderType == "Dinein") {
          let split_area = area && area.split("-");
          obj.delivery_address =
            split_area && split_area[1] ? split_area[1] : area;
          // Save Address Without Maps

          obj.order_netprice = this.cartTotal / (1 + Number(tax) / 100);
          obj.tax_amount = this.calcTotalTax();
          obj.tax_type = taxDataCash.tax_type;
          obj.order_grosspricewd = true
            ? obj.order_netprice + this.props.pos_fee + obj.tax_amount
            : obj.order_netprice + this.props.pos_fee;
          obj.delivery_fee = this.props.deliveryfee;
        } else if (
          orderType == "Pickup" ||
          this.props.orderType == "Qr-Pickup"
        ) {
          obj.tax_amount = this.calcTotalTax();
          obj.tax_type = taxDataCash.tax_type;
          obj.order_grosspricewd = true
            ? obj.order_netprice + this.props.pos_fee + obj.tax_amount
            : obj.order_netprice + this.props.pos_fee;
        }
        if (this.props.address_save !== "") {
          obj.delivery_address =
            this.props.houseDetail !== ""
              ? this.props.houseDetail + " /" + this.props.address_save
              : this.props.address_save;
        }
        obj.delivery_address = "";
        obj.order_netprice = obj.order_grossprice - obj.tax_amount;
        let data: any = await this.compareOpenClosingTime(
          parsedStore,
          obj
        ).then((order_id: any) => {
          obj.order_id = order_id;
          sessionStorage.setItem("order", JSON.stringify(obj));
          return order_id;
        });
        return data;
      }
    }
  };
  HoursArray(hourDiff: any, starttime: any) {
    if (String(hourDiff).indexOf("-") !== -1) {
      hourDiff = hourDiff + 24;
    }
    var hours: any = [];
    let hour = starttime;
    for (let i = 0; i <= hourDiff; i++) {
      hour = starttime + i;
      let AmOrPm = "";
      hour >= 12 && hour < 24 ? (AmOrPm = "pm") : (AmOrPm = "am");
      hour = hour % 12 || 12;
      hours.push(hour + AmOrPm);
    }
    var string = hours.join(":");
    return string;
  }
  compareOpenClosingTime = async (storeSelected: any, obj: any) => {
    let responseso = await this.props
      .saveOrder(obj, this.props.storeSelected, this.props.taxData)
      .then((data: any) => {
        return data;
      });
    return responseso;
  };
  compareTimingbyEachDay = (storeSelected: any, obj: any) => {
    this.props.saveOrder(obj, this.props.storeSelected, this.props.taxData);
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };
  onOpenModal = () => {
    this.setState({ open: true });
  };
  GotoHome = () => {
    // this.setState({ GotoHomeFlag: true })
    let data: any = this.props.cart;
    let cart: any = JSON.parse(data);
    let windowObj = window as any;
    let products: any = [];
    let MapObj: any = {};

    cart.forEach((cartItem: any) => {
      if (cartItem.item) {
        MapObj = {
          quantity: cartItem.quantity,
          price: cartItem.totalItemPrice,
          id: cartItem.item.menu_item_id,
          name: cartItem.item.item_name,
          variant: cartItem.selectedsize.size,
        };
      }
      products.push(MapObj);
    });
    windowObj.dataLayer.push({ ecommerce: null });
    localStorage.setItem("orderId", this.props.order_id);

    setTimeout(() => {
      window.location.href = "/trackorder";
    }, 2000);
  };
  savePaymentCard = (option: any) => {
    this.props.savePaymentMethod(option);
  };
  cartTotal: any;
  totalTax: any;
  couponDiscount: any;
  discount: any;
  quantity: any;
  render() {
    const token = sessionStorage.getItem("token");
    let { cart, orderType, open, order_id, storeSelected, setTime } =
      this.props;

    let data: any = sessionStorage.getItem("cart");
    let Cart: any = data;
    let dataCart = JSON.parse(Cart);
    if (!dataCart || !dataCart.length) {
      this.props.history.push("/");
      // window.location.href = '/menu'
    }
    return (
      <main className="main top_margin_main">
        <div
          className={`container pymnt position-relative ${
            this.props.closeTime || this.props.timeOver
              ? "margin_custom_top_"
              : ""
          }`}
        >
          <div className="mt-1 d-flex align-items-center icon_back_menu">
            <Link to={`/${window.location.search}`}>
              {/* <button className="back-arrow-circle">
                <img
                  className="back-arrow"
                  src={process.env.PUBLIC_URL + "/assets/images/Arrow Left.png"}
                  alt="Back Arrow"
                />
              </button> */}
              <i className="bi bi-arrow-left arrowBacktoMenu"></i>
            </Link>
            <h2 className="subtitle backmanutext" style={{ margin: "0px" }}>
              Back to Menu
            </h2>
          </div>
          <div className="row pb-3 justify-content-center align-items-center">
            <div className="col-lg-6 d-none">
              <div className="d-flex justify-content-center ps-main-flex-div">
                <div>
                  <h4
                    className="text-center, font_size_custom"
                    style={{
                      fontFamily: "Playfair Display",
                      fontSize: "24px",
                      color: "#364D49",
                    }}
                  >
                    Payment Method
                  </h4>
                  <div
                    className=""
                    onClick={() => this.savePaymentCard("Card")}
                  >
                    <div className="d-flex atm-card" style={{}}>
                      <input
                        className=""
                        style={{ marginTop: "12px" }}
                        type="radio"
                        {...(this.props.paymentMethod == "Card"
                          ? {
                              checked: true,
                            }
                          : { checked: false })}
                        aria-label="..."
                      />

                      <span className="mt-1 ml-4">Cards</span>
                      <span>
                        <img
                          className="credit-icon"
                          style={{ maxWidth: "none" }}
                          height={30}
                          width={198}
                          src={
                            process.env.PUBLIC_URL + "/assets/images/Visa.png"
                          }
                          alt="Card Logo"
                        />
                      </span>
                    </div>

                    {this.placeOrderBtn(Cart)}
                  </div>
                </div>
              </div>

              <Modal
                open={open}
                showCloseIcon={false}
                onClose={this.onCloseModal}
                center
              ></Modal>
            </div>
            {!this.props.cartToggle && (
              <div className="col-lg-6 col-md-8 col-12 ord-detail mt-md-7 mt-6 mb-10">
                {this.cartDetail(Cart)}
              </div>
            )}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  const tabNumberFromStorage = parseInt(
    localStorage.getItem("tableNumber") || "0"
  );

  const timePickup = localStorage.getItem("setTime") || "As soon as Possible";
  const specialInstruction = localStorage.getItem("special inc");

  return {
    cart: state.login.cartData,
    setTime: localStorage.getItem("setTime") || "As soon as Possible",
    orderType: state.login.orderType,
    futureDate: state.login.orderDate || timePickup,
    futureTime: state.login.orderTime,
    preOrLater: state.login.preOrLater,
    tabNumber: state.login.tabNumber || tabNumberFromStorage,
    paymentMethod: state.login.paymentMethod,
    deliveryfee: state.login.deliveryfee,
    houseDetail: state.login.houseDetail,
    storeSelected: state.login.storeSelected,
    taxData: state.login.taxData,
    guestInfo: state.login.guestInfo,
    taxDataCash: state.login.taxDataCash,
    taxDataCard: state.login.taxDataCard,
    taxAmount: state.login.taxAmount,
    discountAmount: state.login.discountAmount,
    cities: state.login.cities,
    tradezones: state.login.tradeZones,
    tradeareas: state.login.tradeAreas,
    store_web: state.login.qrStore,
    cityName: state.login.cityName,
    tradeZoneName: state.login.tradeZoneName,
    tradeAreaName: state.login.tradeAreaName,
    tradeZoneValue: state.login.tradeZoneValue,
    tradeAreaValue: state.login.tradeAreaValue,
    pos_fee: state.login.pos_fee,
    coupanCode: state.login.coupanCode,
    coupon: state.login.coupon,
    special_ins: state.login.special_instructions || specialInstruction,
    address_save: state.login.address_save,
    CustomerId: state.login.CustomerId,
    open: state.login.successFlag,
    order_id: state.login.order_id,
    cartToggle: state.login.cartToggle,
    taxRate: state.login.taxRate,
    closeTime: state.search.CloseTime,
    timeOver: state.search.timerOver,
    orderTime: state.search.orderTime,
    // timeOver:state.seach.timerOver,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    SaveTaxValue: function (taxAmount: any) {
      dispatch(SaveTaxValue(taxAmount));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    getTimezone: function (id: number) {
      return dispatch(getTimezone(id));
    },
    saveOrder: function (data: any, store: any, tax: any) {
      return dispatch(saveOrder(data, store, tax));
    },
    savePaymentMethod: function (payment_method: any) {
      dispatch(savePaymentMethod(payment_method));
    },
    savePaymentId: function (id: any, data: any) {
      dispatch(savePaymentId(id, data));
    },
    getCart: function () {
      dispatch(getCart());
    },
    applyCoupon: (data: any) => {
      dispatch(applyCoupon(data));
    },
    resetCoupon: (obj: any) => {
      dispatch(resetCoupon(obj));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Payment);
