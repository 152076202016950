import {
  LOGOUT_CUSTOMER,
  SIGN_UP,
  STORES_LIST,
  PICKUP_STORES_LIST,
  MENU_COMBO_LIST,
  MENU_GROUPS_LIST,
  MENU_ITEMS,
  ORDER_LIST,
  GET_CART,
  SAVE_CART,
  STORE_NAME,
  GET_TAX,
  STORE_ID,
  STORE_TYPE,
  ORDER_ID,
  COMBO_DETAIL,
  COMBO_DETAIL_ID,
  ITEM_DETAIL,
  ADDRESS_GUEST,
  ORDER_TYPE,
  ORDER_PERIOD,
  SAVE_HOUSEDETAIL,
  SAVE_STREETDETAIL,
  SAVE_ADDRESSTYPE,
  SELECT_STORE_ID,
  SELECT_STORE,
  ADD_GUEST,
  PAYMENT,
  CUSTOMER_ID,
  FRANCHISE_APPLICATION,
  SIGNUP_k2g,
  TOP_LIST,
  HERO_ITEMS,
  FORGOT,
  ADDRESS_LIST,
  FAV_MENU_ITEM,
  OFFERS_LIST,
  COUPON_DATA,
  CHECKOUT_TAB_NAME,
  RESTUARANTS,
  ORDER_DATA,
  GET_PROFILE,
  HANDLE_PROFILE_INPUT,
  TOP_SELLING_ITEMS,
  ORDER_DETAIL,
  SAVE_ADDRESS_USER,
  SAVE_STORE_DETAILS,
  ADD_CONTACT,
  ADD_EMAIL,
  PAYMENT_METHOD,
  SUCCESS_FLAG,
  ORDER,
  URL_MATCH_METHOD,
  UPDATE_LSM,
  LSM_ITEMS,
  GET_TAX_CARD,
  GET_TAX_CASH,
  SAVE_TAX,
  SAVE_DISCOUNT,
  NETWORK_STATUS,
  NEW_CUSTOMER_PHONE_URL,
  TOP_DEALS_HOME,
  SWITCH_TAB,
  VALIDATE_OTP,
  SPECIFIC_STORE_ITEMS,
  MENU_GROUPS_LIST_HOME,
  HANDLE_SPECIAL_INPUT,
  MENU_STORE_ID,
  GET_STORE,
  CITIES_WEB,
  TRADE_ZONES_WEB,
  TRADE_AREAS_WEB,
  SET_CITY_NAME,
  SET_TRADE_AREA_NAME,
  SET_TRADE_ZONE_NAME,
  ADD_NEW_ADDRESS_WITHOUT_MAP,
  CART_TOGGLE,
  FEATURED_PRODUCTS,
  FILTERED_ITEMS,
  STORE_TIME_CHECK,
  PICKUP_OPEN,
  TABLE_NUMBER,
  ORDER_DATE_TIME,
  SAVE_TAX_RATE,
  SAVE_AGREEMENT_CHECK,
  SAVE_COOKIEMODAL_CHECK,
} from "../Actions/types";

const initialState: any = {
  isLoggedIn: "",
  isOpen: "",
  area_details: null,
  firstname: "",
  lastname: "",
  email: "",
  dob: "",
  phone: "",
  gender: "",
  emailExit: "",
  storesList: [] || "",
  pickupStoreList: [] || "",
  storeStatus: 0,
  deliveryfee: "",
  menuCombosData: [] || "",
  storeSelected: {},
  groupList: [] || "",
  groupsHome: [] || "",
  groupsData: [] || "",
  menuItemsData: [] || "",
  lsmItemsData: [] || "",
  specificStoreItems: [] || "",
  ordersData: [] || "",
  cartData: [] || "",
  success: "",
  taxData: "",
  taxDataCash: [] || "",
  taxDataCard: [] || "",
  storeIdSelect: "",
  Store_ID: "",
  Types: "",
  orderType: "Pickup",
  paymentMethod: "Card",
  lookupURL: "",
  orderPeriod: "now",
  isChecked: false,
  orderDate: sessionStorage?.getItem("futureDate") || new Date(),
  orderTime: sessionStorage?.getItem("futureTime") || "",
  preOrLater: "orderNow",
  addressType: "",
  streetDetail: "",
  houseDetail: "",
  orderId: "",
  comboData: {},
  combo_ID: "",
  groupName: "",
  itemData: {},
  address_save: "",
  selectStoreId: undefined,
  menuStoreId: "",
  guestInfo: {},
  successStatus: "",
  paymentSuccess: "",
  CustomerId: "",
  isFilled: "",
  successStatusRegister: "",
  taxAmount: 0,
  discountAmount: 0,
  topList: "",
  topDealsHome: "",
  topItemsList: "",
  heroItem: [] || "",
  isReset: "",
  addressData: [] || "",
  favItemData: [] || "",
  offers: [] || "",
  coupon: {},
  tabName: "location",
  tabNumber: 0,
  switchTab: "1",
  isValidOtp: "",
  restuarants: [] || "",
  storeData: {},
  orderDetail: [],
  saveAddressUser: "Home",
  couponSuccess: "",
  storeDetails: {},
  successFlag: false,
  respMessage: "",
  OtprespMessage: "",
  order_id: "",
  lsmStatus: false,
  emptyLsmItemsFlag: false,
  netWork: false,
  oldCustomerCode: "",
  oldCustomerSuccess: true,
  oldCustomerMessage: "",
  oldCustomerName: "",
  special_instructions: "",
  loader: true,
  qrStore: {},
  cities: [],
  tradeZones: [],
  tradeAreas: [],
  cityName: "",
  tradeZoneName: "",
  tradeAreaName: "",
  tradeZoneValue: null,
  tradeAreaValue: null,
  addNewAddressWithoutMap: "",
  pos_fee: 0,
  cartToggle: false,
  featuredProducts: [] || "",
  filteredItems: [] || "",
  store_time_check: false,
  pickupOpen: "",
  taxRate: sessionStorage?.getItem("tax_rate") || 0.15,
  cookieModal_check: true,
  login_password: "",
  // emailSignup:'',
  // passwordSignup:''
};

const loginReducer = (state = initialState, action: any) => {
  //
  switch (action.type) {
    case LOGOUT_CUSTOMER:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    case SAVE_COOKIEMODAL_CHECK:
      return {
        ...state,
        cookieModal_check: action.payload,
      };
    case HANDLE_PROFILE_INPUT:
      return {
        ...state,
        [action.input.name]: action.input.value,
      };
    case HANDLE_SPECIAL_INPUT:
      return {
        ...state,
        [action.input.name]: action.input.value,
      };
    case GET_PROFILE:
      return {
        ...state,
        firstname: action.payload.first_name,
        lastname: action.payload.last_name,
        email: action.payload.email_address,
        dob: action.payload.customer_birthday,
        phone: action.payload.phone_number,
        gender: action.payload.gender,
      };
    case SIGNUP_k2g:
      return {
        ...state,
        isOpen: action.isOpen,
        emailExit: action.emailExit,
        successStatusRegister: action.successStatusRegister,
        login_password: action.login_password,
        // emailSignup:action.email,
        // passwordSignup:action.password
      };
    case SIGN_UP:
      return {
        ...state,
        isOpen: action.isOpen,
        emailExit: action.emailExit,
        // emailSignup:action.email,
        // passwordSignup:action.password
      };

    case STORES_LIST:
      return {
        ...state,
        deliveryfee: action.deliveryfee,
        storesList: action.payload,
        selectStoreId: action.selectStoreId,
        storeStatus: action.storeStatus,
        pos_fee: action.pos_fee,
      };
    case PICKUP_STORES_LIST:
      return {
        ...state,
        pickupStoreList: action.payload,
      };
    case MENU_STORE_ID:
      return {
        ...state,
        menuStoreId: action.payload,
      };
    case MENU_COMBO_LIST:
      return {
        ...state,
        menuCombosData: action.payload,
      };
    case TOP_LIST:
      return {
        ...state,
        topList: action.payload,
      };
    case TOP_DEALS_HOME:
      return {
        ...state,
        topDealsHome: action.payload,
      };
    case TOP_SELLING_ITEMS:
      return {
        ...state,
        topItemsList: action.payload,
      };
    case MENU_GROUPS_LIST:
      return {
        ...state,
        groupList: action.payload,
        groupsData: action.groupsData,
        loader: action.loaderFlag,
      };
    case MENU_GROUPS_LIST_HOME:
      return {
        ...state,
        groupsHome: action.payload,
      };
    case MENU_ITEMS:
      return {
        ...state,
        menuItemsData: action.payload,
        GroupName: action.groupName,
      };
    case LSM_ITEMS:
      return {
        ...state,
        lsmItemsData: action.payload,
        emptyLsmItemsFlag: action.emptyLsmItems,
      };
    case SPECIFIC_STORE_ITEMS:
      return {
        ...state,
        specificStoreItems: action.payload,
      };
    case ORDER_LIST:
      return {
        ...state,
        ordersData: action.payload,
      };
    case GET_CART:
      return {
        ...state,
        cartData: action.payload,
      };
    case SAVE_CART:
      return {
        ...state,
        success: action.success,
      };
    case STORE_NAME:
      return {
        ...state,
        storeIdSelect: action.StoreID,
      };
    case STORE_TIME_CHECK:
      return {
        ...state,
        store_time_check: action.payload,
      };
    case GET_TAX:
      return {
        ...state,
        taxData: action.payload,
      };
    case GET_TAX_CASH:
      return {
        ...state,
        taxDataCash: action.payload,
        deliveryfee: action.deliveryfee,
      };
    case GET_TAX_CARD:
      return {
        ...state,
        taxDataCard: action.payload,
        deliveryfee: action.deliveryfee,
      };
    case SAVE_TAX_RATE:
      return {
        ...state,
        taxRate: action.taxRate,
      };
    case SAVE_AGREEMENT_CHECK:
      return {
        ...state,
        isChecked: action.isChecked,
      };
    case STORE_ID:
      return {
        ...state,
        Store_ID: action.Store_ID,
      };
    case STORE_TYPE:
      return {
        ...state,
        Types: action.Types,
      };
    case ORDER_DATE_TIME:
      return {
        ...state,
        orderDate: action.orderDate,
        orderTime: action.orderTime,
        preOrLater: action.orderNow,
      };
    case ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };
    case COMBO_DETAIL:
      return {
        ...state,
        comboData: action.payload,
      };
    case COMBO_DETAIL_ID:
      return {
        ...state,
        comboID: action.payload,
      };
    case ITEM_DETAIL:
      return {
        ...state,
        itemData: action.payload,
      };
    case ADDRESS_GUEST:
      return {
        ...state,
        address_save: action.Address,
      };
    case ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
      };
    case SAVE_ADDRESS_USER:
      return {
        ...state,
        saveAddressUser: action.saveAddressUser,
      };
    case ORDER_PERIOD:
      return {
        ...state,
        orderPeriod: action.orderPeriod,
      };
    case SAVE_HOUSEDETAIL:
      return {
        ...state,
        houseDetail: action.houseDetail,
      };
    case SAVE_STREETDETAIL:
      return {
        ...state,
        streetDetail: action.streetDetail,
      };
    case SAVE_ADDRESSTYPE:
      return {
        ...state,
        addressType: action.addressType,
      };
    case SELECT_STORE_ID:
      return {
        ...state,
        selectStoreId: action.selectStoreId,
        pos_fee: action.pos_fee,
      };
    case SELECT_STORE:
      return {
        ...state,
        storeSelected: action.storeSelected,
      };
    case ADD_GUEST:
      return {
        ...state,
        guestInfo: action.payload,
        successStatus: action.SuccessStatus,
      };
    case PAYMENT:
      return {
        ...state,
        paymentSuccess: action.PaymentSuccess,
        successFlag: action.successFlag,
        futuresuccessFlag: action.futuresuccessFlag,
      };
    case CUSTOMER_ID:
      return {
        ...state,
        CustomerId: action.CustomerId,
      };
    case FRANCHISE_APPLICATION:
      return {
        ...state,
        isFilled: action.isFilled,
      };
    case HERO_ITEMS:
      return {
        ...state,
        heroItem: action.payload,
      };
    case FORGOT:
      return {
        ...state,
        isReset: action.isReset,
        respMessage: action.respMessage,
        switchTab: action.switchTab,
      };
    case VALIDATE_OTP:
      return {
        ...state,
        isValidOtp: action.isValidOtp,
        OtprespMessage: action.OtprespMessage,
        switchTab: action.switchTab,
      };
    case ADDRESS_LIST:
      return {
        ...state,
        addressData: action.payload,
      };
    case FAV_MENU_ITEM:
      return {
        ...state,
        favItemData: action.payload,
      };
    case OFFERS_LIST:
      return {
        ...state,
        offers: action.payload,
      };
    case COUPON_DATA:
      return {
        ...state,
        coupon: action.payload,
        couponSuccess: action.successResponse,
      };
    case CHECKOUT_TAB_NAME:
      return {
        ...state,
        tabName: action.tabName,
      };
    case ORDER_DATA:
      return {
        ...state,
        storeData: action.storeData,
      };
    case TABLE_NUMBER:
      return {
        ...state,
        tabNumber: action.tabNumber,
      };
    case RESTUARANTS:
      return {
        ...state,
        restuarants: action.stores,
      };
    case ORDER_DETAIL:
      return {
        ...state,
        orderDetail1: action.payload,
      };
    case PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
      };
    case URL_MATCH_METHOD:
      return {
        ...state,
        lookupURL: action.url,
      };
    case ORDER:
      return {
        ...state,
        order_id: action.order_id,
      };
    case UPDATE_LSM:
      return {
        ...state,
        lsmStatus: action.statusInfo,
      };
    case SAVE_TAX:
      return {
        ...state,
        taxAmount: action.taxAmount,
      };
    case SAVE_DISCOUNT:
      return {
        ...state,
        discountAmount: action.discountValue,
      };
    case NETWORK_STATUS:
      return {
        ...state,
        netWork: action.payload,
      };
    case NEW_CUSTOMER_PHONE_URL:
      return {
        ...state,
        oldCustomerCode: action.code,
        oldCustomerMessage: action.response,
        oldCustomerSuccess: action.success,
        oldCustomerName: action.name,
      };
    case SWITCH_TAB:
      return {
        ...state,
        switchTab: action.switchTab,
      };

    case GET_STORE:
      return {
        ...state,
        qrStore: action.payload,
      };

    case CITIES_WEB:
      return {
        ...state,
        cities: action.payload,
      };

    case TRADE_ZONES_WEB:
      return {
        ...state,
        tradeZones: action.payload,
      };

    case TRADE_AREAS_WEB:
      return {
        ...state,
        tradeAreas: action.payload,
      };

    case SET_CITY_NAME:
      return {
        ...state,
        cityName: action.payload,
      };

    case SET_TRADE_ZONE_NAME:
      return {
        ...state,
        tradeZoneName: action.payload,
        tradeZoneValue: action.value,
      };

    case SET_TRADE_AREA_NAME:
      return {
        ...state,
        tradeAreaName: action.payload,
        tradeAreaValue: action.value,
      };

    case ADD_NEW_ADDRESS_WITHOUT_MAP:
      return {
        ...state,
        addNewAddressWithoutMap: action.payload,
      };
    case CART_TOGGLE:
      return {
        ...state,
        cartToggle: action.payload,
      };
    case FEATURED_PRODUCTS:
      return {
        ...state,
        featuredProducts: action.payload,
      };
    case FILTERED_ITEMS:
      return {
        ...state,
        filteredItems: action.payload,
      };
    case "AREA_DETAILS":
      return {
        ...state,
        area_details: action.payload,
      };
    case PICKUP_OPEN:
      return {
        ...state,
        pickupOpen: action.pickupOpen,
      };
    default:
      return state;
  }
};
export default loginReducer;
