import React from 'react'
import './close.css'
import { useSelector } from 'react-redux'
import { RootState } from '../../Redux/store';



const CloseHeader = () => {

  const storeStarttime = useSelector((state:RootState)=>state.search.storeStart)

  return (
    <div className='header-close d-flex justify-content-center align-items-center'>
<span>Palace cafe is currently closed you can place your order Tomorrow. Thank you!</span>
    </div>
  )
}

export default CloseHeader