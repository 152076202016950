import React, { useEffect, useState } from "react";
import "./PrivacyModal.css";
import Swal from "sweetalert2";

interface PrivacyModalProps {
  showModal: boolean;
  onClose: () => void;
  onSave: (isChecked: boolean) => void;
}

const PrivacyModal: React.FC<PrivacyModalProps> = ({
  showModal,
  onClose,
  onSave,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  const handleSave = () => {
    if (!isChecked) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please agree to the Terms & Services, Privacy Policy, and Content Policy by checking the checkbox.",
        toast: true,
        position: "top-right",
        iconColor: "red",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
      });
      return;
    }
    onSave(isChecked);
    onClose();
  };

  return (
    <>
      {showModal && (
        <div className="modal" style={{marginTop: "8rem"}}>
          <div className="modal-content">
            <div className="close-button" onClick={onClose}>
              <i className="fas fa-times mr-4" />
            </div>
            <div>
              <p>
                <strong>PROVISIONS FOR PRIVACY</strong>
              </p>
              <p>
                <strong>Etcetera Norway AS dedication to privacy</strong>
              </p>
              <p>
                As the owner of order.palacecafe.no, Etcetera Norway AS must
                safeguard the confidentiality of your personal information. As a
                result, we conduct our business in compliance with all relevant
                privacy and data security legislation. You can learn more about
                the information Etcetera Norway AS gathers, how it uses it, and
                who it shares it with by reading the terms outlined below.
              </p>
              <p>
                <strong>Personal information</strong>
              </p>
              <p>
                No personal information about you, including your name, address,
                phone number, and email address, will be collected by Etcetera
                Norway AS through our websites unless you voluntarily provide it
                to us (for example, during registration or a survey), unless you
                give us permission to do so, or unless doing so is otherwise
                permitted by the laws and regulations that govern the protection
                of your personal data.
              </p>
              <p>
                <strong>Terms of Use</strong>
              </p>
              <p>
                Exclusively for the purposes disclosed to you, Etcetera Norway
                AS will collect, use, and share personal information about you
                that is obtained online: • is the use of personal information
                for other purposes that are closely connected to the reason it
                was obtained, • are required to draught, discuss, and finalize
                an agreement with you, • is required by law, or by officials who
                have been designated by the government or the judiciary, • is
                required to initiate or sustain a legal claim or defence; • is
                required to stop fraud and other unlawful actions including
                targeted attacks on the information technology systems of
                Etcetera Norway AS.
              </p>
              <p>
                <strong>Limitations on the use of personal information</strong>
              </p>
              <p>
                Exclusively for the purposes disclosed to you, Etcetera Norway
                AS will collect, use, and share personal information about you
                that is obtained online: • is the use of personal information
                for additional purposes that are closely related to the reason
                it was collected, • are required to draught, negotiate, and
                finalize a contract with you, • is required by law, or by
                officials who have been designated by the government or the
                judiciary, • is required to initiate or sustain a legal claim or
                defence; • is required to stop fraud and other unlawful actions
                including targeted attacks on the information technology systems
                of Etcetera Norway AS.
              </p>
              <p>
                <strong>Log Data</strong>
              </p>
              <p>
                Your communication information, such as your IP address, or
                usage data, such as details about when you first connected to
                the Internet, when you last used it, how long you spent there,
                and which telecommunications services you used, are technically
                generated when you use telecommunications services to access our
                website and are likely to be linked to your personal data. It is
                possible that information about your communications and use will
                be collected and handled if it is absolutely necessary, and it
                will be done so in line with the relevant privacy laws.
              </p>
              <p>
                <strong>Non-personal data gathered automatically</strong>
              </p>
              <p>
                We automatically (i.e., without requiring registration) gather
                non-personal information when you visit our websites (e.g. the
                operating system and browser you use, the domain name of the
                website you came from, the number of visits, the average time
                spent on the page, and the sites you have visited). To gauge the
                appeal of our websites and enhance their functionality or
                content, we may utilize this data and share it with our global
                operations
              </p>
              <p>
                <strong>Monitoring web pages</strong>
              </p>
              <p>
                For marketing and optimization purposes, we utilize goods from
                outside vendors. These products gather and exploit your personal
                information to build user profiles for you using a fictitious
                name. To the greatest extent possible and as necessary, these
                user profiles are fully anonymous. On this website, we as well
                as third parties employ web beacons (invisible tracking images)
                and/or cookies. We transmit the data we gather about you,
                including personal information. It is legal for all of our
                third-party partners to make anonymous user profiles using the
                data that you provide when visiting our websites. Without the
                explicit consent of the party in question, the information
                collected may not be linked to personal data about the person
                using the pseudonym, nor may it be used to personally identify
                visitors to these websites. All registered IP addresses are
                immediately made anonymous by eliminating the final set of
                digits. The right to later acquire and keep information can
                always be revoked.
              </p>
              <p>
                <strong>Cookies</strong>
              </p>
              <p>
                We may use "cookies" to keep specific information about your
                visit to one of our websites on your computer so that the next
                time you visit the page; it will automatically recognize your
                PC. Cookies can be helpful in a variety of situations, such
                allowing us to customize a website to better fit your interests
                or saving your password so you don't have to input it each time.
                You can set your browser to erase all cookies from your
                computer, block all cookies, or alert you before a cookie is
                stored if you do not want to receive them.
              </p>
              <p>
                <strong>Children’s Privacy</strong>
              </p>
              <p>
                When required by law, Etcetera Norway AS will not intentionally
                gather personal information about children without first
                requesting parental consent from those children. To the extent
                permissible by law, when obtaining guardians' approval in line
                with local laws and regulations, or where necessary to protect a
                child, we will only use and disclose personal information
                concerning minors. The term "kid" should have the same meaning
                as given in all applicable legislation and in accordance with
                national and cultural customs.
              </p>
              <p>
                <strong>Safety</strong>
              </p>
              <p>
                Your personal information is safeguarded by Etcetera Norway AS
                using organizational and technical security measures to prevent
                accidental or unlawful destruction, loss, or alteration as well
                as unauthorized sharing or access. other websites' links The
                websites of The Etcetera Norway AS have links to other websites.
                Etcetera Norway AS disclaims all liability for the privacy
                practices or the content of such websites.
              </p>
              <p>
                <strong>Questions and reviews</strong>
              </p>
              <p>
                Any reasonable requests to see your personal information and
                review, modify, or delete inaccurate information will be
                complied with by Etcetera Norway AS. Send us your request if you
                have any inquiries or feedback regarding the privacy policy of
                Etcetera Norway AS. Our privacy laws will change to reflect
                changes on the Internet. If our privacy policy changes, a notice
                will be issued on this page. To stay current, please return
                frequently to this website.
              </p>
            </div>
            <div className="form-check d-flex align-items-center">
              <input
                type="checkbox"
                className="form-check-input checkbox"
                onChange={handleCheckboxChange}
                checked={isChecked}
                required
              />
              <label className="form-check-label label mt-1 ml-5">
                I agree to the Terms & Services, Privacy Policy, and Content
                Policy
              </label>
            </div>
            <div className="button-container">
              <button className="accept-button" onClick={handleSave}>
                Accept
              </button>
              <button className="decline-button" onClick={onClose}>
                Decline
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PrivacyModal;
