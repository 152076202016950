import React, { useEffect, useState } from "react";
import "./Nav.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  featuredProducts,
  getCart,
  searchItems,
  logoutCustomer,
  emptyFilteredItems,
  menuGroupsListHome,
  saveOrderType,
} from "../../../Redux/Actions/index";
import NavLinkNew from "./NavLink new";
import { RootState } from "../../../Redux/store";
import { API_URL } from "../../../ApiManager/client-config";

function Nav(props: any) {
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [search, setSearch] = useState("");

  const timeOver = useSelector((state:RootState)=>state.search.timerOver);

  
  const closeTime = useSelector((state:RootState)=> state.search.CloseTime);

  useEffect(() => {
    setSearch("");
    props.emptyFilteredItems();
    dispatch(menuGroupsListHome(null));
  }, [dispatch]);

  useEffect(() => {
    if (props.groupsHome && props.groupsHome.length > 0) {
    }
    
  }, [props.groupsHome]);

  const sortedCategories = props.groupsHome
  .filter((group: { group_id: number; }) => group.group_id !== 68) // Remove the Uncategorized category if needed
  .sort((a: { priority: number; }, b: { priority: number; }) => a.priority - b.priority);

 
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
    if (e.target.value == "") {
      props.emptyFilteredItems();
    } else {
      props.searchItems({
        text: e.target.value,
        store_id: null,
        data: props.groupsData,
      });
    }
  };
  const clearSeach = () => {
    setSearch("");
    props.emptyFilteredItems();
  };
  const setModeOpenMenu = (mode: any) => {
    sessionStorage.setItem("orderType", mode);
    props.saveOrderType(mode);
    setOpenMenu(true);
  };
  const moveLeft = () => {
    let obj = document as any;
    obj.getElementById("moveCons").scrollLeft -= 100;
  };
  const moveRight = () => {
    let obj = document as any;
    obj.getElementById("moveCons").scrollLeft += 100;
  };
  const toggleSidebar = (condition: any) => {
    setOpenMenu(true);
  };
  return (
    <>
      <nav
        className="container"
        style={{ height: "0px", maxHeight: "0px", padding: "0px", top: "20px" }}
      >
        <div className={`${timeOver || closeTime ? 'sticky-subheader-outer-over':'sticky-subheader-outer'}`}>
          <div className="row">
            <nav className="col-12">
              {/* <section style={{"height":"80px","backgroundColor":"antiquewhite"}}> */}
              <div onClick={moveLeft} className="category-left-arrow left-next">
                {" "}
                <i
                  className="fa fa-angle-left"
                  aria-hidden="true"
                  style={{ color: "white" }}
                ></i>
          
                {" "}
              </div>
              <div
                className={`outer-category`}
                style={{ display: "flex" }}
                id="moveCons"
              >
                <div
                  className="custom-mobile"
                  style={{
                    display: "flex",
                  }}
                >
                  <nav className="nb-nav-cat" style={{ display: "contents" }}>
                    {props &&
                      sortedCategories.map(
                        (group: any, idx: any) =>
                          group.group_id !== 68 && (
                            <NavLinkNew
                              key={idx}
                              id={idx}
                              navLinkId={group.group_name}
                              scrollToId={group.group_id}
                            />
                          )
                      )}
                  </nav>
                </div>
              </div>
              {/* </section>   */}

              <div
                onClick={moveRight}
                className="category-right-arrow category-right-next"
                id="moveCons"
              >
                {" "}
                <i
                  className="fa fa-angle-right"
                  aria-hidden="true"
                  style={{ backgroundColor: "none" }}
                ></i>{" "}
              
              
              </div>
            </nav>
          </div>
        </div>
      </nav>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    groupsHome: state.login.groupsHome,
    groupList: state.login.groupList,
    GroupName: state.login.GroupName,
    groupsData: state.login.groupsData,
    orderType: state.login.orderType,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutCustomer: function () {
      dispatch(logoutCustomer());
    },
    getCart: function (history?: any) {
      dispatch(getCart(history));
    },
    saveOrderType: function (mode: any) {
      dispatch(saveOrderType(mode));
    },
    menuGroupsListHome: function (store_id: any) {
      dispatch(menuGroupsListHome(store_id));
    },

    featuredProducts: (store_id: any) => {
      dispatch(featuredProducts(store_id));
    },
    searchItems: (data: any) => {
      dispatch(searchItems(data));
    },
    emptyFilteredItems: () => {
      dispatch(emptyFilteredItems());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
