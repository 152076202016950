import React, { Component } from "react";
import PhoneInput from "react-phone-input-2";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { LocationProps } from "../Interface/loginInterface";
import "./checkOut.css";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import {
  addGuest,
  getCart,
  saveCart,
  SaveDiscountValue,
  saveOrderType,
  SaveTaxValue,
  addressesListk,
  findLocalStoresWithoutMap,
  saveHouseDetail,
  customerSignIn,
} from "../../Redux/Actions/index";
import axios from "axios";
import PasswordStrengthBar from "react-password-strength-bar";
import moment from "moment";
import Login, { LoginRightPart } from "../Login";

class Location extends Component<
  LocationProps,
  {
    [x: number]: any;
    first_name: string;
    last_name: string;
    phone_number: any;
    email_address: string;
    password: string;
    taxRate: any;
    confirmPassword: string;
    isValidEmail: any;
    signUp: any;
    removeableInvalidItemsArr: any;
    showValidteItemModal: any;
    address: any;
    width: any;
    removeableLsmItemsArr: any;
    showHide: any;
    clickNext: any;
    setTime: any;
    special_ins: any;
    closeTime:any;
    timeOver:any;
  }
> {
  clicked: any;
  constructor(props: any) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      phone_number: "",
      email_address: "",
      password: "",
      confirmPassword: "",
      isValidEmail: null,
      signUp: true,
      removeableInvalidItemsArr: [],
      removeableLsmItemsArr: [],
      showValidteItemModal: false,
      address: "",
      width: window.innerWidth,
      showHide: null,
      clickNext: false,
      taxRate: 0,
      setTime: "",
      special_ins: "",
      closeTime:false,
      timeOver:false,
    };
    this.clicked = false;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let orderType = sessionStorage.getItem("orderType");
    if (orderType) {
      this.props.saveOrderType(orderType);
    }
    this.validateCart();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  validateCart = () => {
    let data: any = sessionStorage.getItem("cart");
    let store = sessionStorage.getItem("storeSelected");
    let token = sessionStorage.getItem("token");
    let parsedStore: any = store ? JSON.parse(store) : null;
    let cart: any = JSON.parse(data);
    let obj = {
      cart: cart,
      mode:
        sessionStorage.getItem("orderType") == "Qr-Pickup" ? "qr" : "online",
    };
    axios
      .post(`${BASE_URL}menu/validateCart`, obj)
      .then((res: any) => {
        let invalidCart = res.data.menuData;
        let InvalidItemRemove: any = [];
        for (let i = 0; i < cart.length; i++) {
          for (let j = 0; j < invalidCart.length; j++) {
            if (cart[i].item && invalidCart[j].item) {
              if (cart[i].selectedsize.id === invalidCart[j].item.variant_id) {
                InvalidItemRemove.push(cart[i]);
                this.setState({ removeableInvalidItemsArr: InvalidItemRemove });
              }
            } else if (cart[i].combo && invalidCart[j].combo) {
              if (cart[i].combo.combo_id === invalidCart[j].combo.combo_id) {
                InvalidItemRemove.push(cart[i]);
                this.setState({ removeableInvalidItemsArr: InvalidItemRemove });
              }
            }
          }
        }
        this.setState({ showValidteItemModal: true });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ showValidteItemModal: false });
      });
  };

  handleSignUp = () => {
    this.setState({ signUp: !this.state.signUp });
    if (this.state.signUp == false) {
      this.setState({ password: "", confirmPassword: "" });
    }
  };

  handlePassword = (e: any) => {
    this.setState({ password: e.target.value });
  };
  handleConfirmPassword = (e: any) => {
    this.setState({ confirmPassword: e.target.value });
  };
  handlefirstName = (event: any) => {
    this.setState({
      first_name: event.target.value,
    });
  };

  handlelastName = (event: any) => {
    this.setState({
      last_name: event.target.value,
    });
  };

  handlePhoneChange = (value: any) => {
    this.setState({ phone_number: value });
  };

  validateEmail = (event: { target: { name: any; value: any } }) => {
    let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
    if (event.target.value != "") {
      if (reg.test(event.target.value)) {
        this.setState({
          email_address: event.target.value,
          isValidEmail: true,
        });
      } else {
        this.setState({
          email_address: event.target.value,
          isValidEmail: false,
        });
      }
    } else {
      this.setState({ email_address: event.target.value, isValidEmail: true });
    }
  };

  calcItemTotal = (carts: any) => {
    let sum = 0;
    carts.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
    });
    return sum;
  };
  calcTotalTax = () => {
    let totalTaxAmount: number = 0; // Use number type for better precision
    let orderType = this.props.orderType;

    if (orderType) {
      let data: any = sessionStorage.getItem("cart");
      let cart: any = JSON.parse(data);
      let tax_rate: number = parseFloat(this.props.taxRate); // Ensure tax_rate is a number

      for (let index = 0; index < cart.length; index++) {
        const element = cart[index].item;
        let taxAmount: any = 0;
        // let net_price: number = element.price / (1 + tax_rate);
        let net_price: any =
          (Number(cart[index].quantity) * parseFloat(element.price)) /
          (1 + Number(tax_rate));
        let net_p: any = parseFloat(net_price).toFixed(2);
        taxAmount = Number(cart[index].quantity) * element.price - net_p;
        taxAmount = parseFloat(taxAmount).toFixed(2);
        // taxAmount = element.price - net_price;

        // totalTaxAmount += parseFloat(cart[index].quantity) * taxAmount;
        totalTaxAmount += Number(taxAmount);

        if (cart[index].item.modifiers) {
          if (typeof cart[index].item.modifiers == "object") {
            Object.keys(cart[index].item.modifiers).map((key) => {
              cart[index].item.modifiers[key].map((item: any, _index: any) => {
                if (item.selected == true) {
                  // let mod_net_price: number =
                  //   item.modifier_sale_price / (1 + tax_rate);
                  let modTaxAmount: number = 0;
                  // item.modifier_sale_price - mod_net_price;
                  let mod_net_price: any =
                    (Number(cart[index].quantity) *
                      parseFloat(item.modifier_sale_price)) /
                    (1 + Number(tax_rate));
                  let mod_net_p: any = parseFloat(mod_net_price).toFixed(2);
                  modTaxAmount =
                    Number(cart[index].quantity) * item.modifier_sale_price -
                    Number(mod_net_p);
                  totalTaxAmount += Number(modTaxAmount);
                }
              });
            });
          }
        }
      }
    }

    // Round totalTaxAmount to two decimal places
    totalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

    this.props.SaveTaxValue(totalTaxAmount);

    return totalTaxAmount;
  };

  calcTotal = (data: any) => {
    let { deliveryfee, coupon } = this.props;
    this.couponDiscount = 0;
    this.discount = 0;
    let sum: any = 0;
    let sum2: any = 0;
    data.forEach((item: any) => {
      sum += parseInt(item.totalItemPrice);
      sum2 += parseInt(item.totalItemPrice);
    });
    let discountValue: any = 0;
    if (_.isEmpty(coupon)) {
      this.discount = 0;
      this.couponDiscount = 0;
      this.cartTotal = 0;
    }
    this.cartTotal = Math.round(sum);
    if (this.props.taxDataCash.tax_type == 1) {
      sum = parseInt(sum) + this.totalTax;
    } else {
      sum = parseInt(sum);
    }
    // sum = parseInt(sum) + this.totalTax;
    let finalSum = _.isNaN(sum) ? sum2 : sum;
    return Math.round(finalSum);
  };
  calculateOrderDiscount = (data: any) => {
    let discount = 0;
    data.forEach((item: any) => {
      if (item) {
        if (item.selectedsize.discount_price) {
          discount +=
            item.quantity *
            Math.round(
              item.selectedsize.mrp - item.selectedsize.discount_price
            );
        }
      }
    });
    return discount;
  };
  calcTotalDiscount = (discount: any) => {
    return discount;
  };
  handleNext = () => {
    this.setState({ clickNext: true });
    let token = sessionStorage.getItem("token");
    let data: any = sessionStorage.getItem("cart");
    let store = sessionStorage.getItem("storeSelected");
    let parsedStore: any = store ? JSON.parse(store) : null;
    let cart: any = JSON.parse(data);
    if (token) {
      this.setState({ showHide: null, clickNext: null });
      this.props.history.push({
        pathname: "/payment",
        search: window.location.search,
      });
    } else {
      let {
        first_name,
        last_name,
        email_address,
        phone_number,
        password,
        confirmPassword,
        signUp,
        address,
      } = this.state;
      let obj: any = {
        first_name: first_name,
        last_name: last_name,
        login_name: first_name + " " + last_name,
        phone_number: phone_number,
        email_address: email_address,
        is_active: 1,
        history: this.props.history,
        is_guest: password != "" ? 0 : 1,
        login_password: signUp ? password : null,
        address: address,
      };
      if (signUp) {
        Object.assign(obj, { login_password: password });
      }
      this.setState({ showHide: null, clickNext: null });
      this.props.addGuest(obj);
    }
  };
  getModifierPrice = (modifiers: any, carts: any, quantity: number): any => {
    let value = Object.keys(modifiers).reduce((count, key) => {
      return (
        count +
        carts.item.modifiers[key].reduce((innerCount: number, item: any) => {
          return (
            innerCount + (item.selected ? Number(item.modifier_sale_price) : 0)
          );
        }, 0)
      );
    }, 0);
    return quantity * value;
  };
  cartDetail = (data: any) => {
    let tax_rate: any = sessionStorage.getItem("tax_rate");
    if (!_.isEmpty(data)) {
      let cartData: any[] = JSON.parse(data);
      if (cartData.length > 0) {
        return (
          <div className="location-cart-container">
            <div>
              <h2 className="location-order-detail mt-2">Your Order</h2>
              <hr className="mt-3 mb-3  "></hr>
              {cartData.map((carts, indexes) => (
                <>
                  <div className="d-flex justify-content-between">
                    <p
                      className="location-cart-product-name"
                      style={{ fontFamily: "Playfair Display" }}
                    >
                      {carts.quantity}
                      {" x "}
                      {!_.isEmpty(carts.item) && carts.item.item_name}{" "}
                    </p>
                    <p className="location-cart-product-name">
                      {carts.selectedsize ? (
                        carts.selectedsize.discount_price &&
                        carts.selectedsize.order_channel == "online" ? (
                          <>
                            {`${
                              Math.round(carts.selectedsize.discount_price) *
                              carts.quantity
                            } ${" NOK"}`}
                          </>
                        ) : (
                          <>
                            {carts.selectedsize.orderModePrice[0].mrp *
                              carts.quantity +
                              this.getModifierPrice(
                                carts.item.modifiers,
                                carts,
                                carts.quantity
                              ) +
                              " NOK"}
                          </>
                        )
                      ) : (
                        Math.round(carts.price) * carts.quantity + " NOK"
                      )}
                    </p>
                  </div>
                  {!_.isEmpty(carts.item) &&
                    !_.isEmpty(carts.item.modifiers) && (
                      <p className="location-ndsub-details">
                        {Object.keys(carts.item.modifiers).map(
                          (key: any, index) => {
                            return carts.item.modifiers[key].map(
                              (item: any, index: any) => {
                                return (
                                  item.selected === true && (
                                    <div className="d-flex justify-content-between ">
                                      <li>
                                        {" "}
                                        {item.modifier_name +
                                          " x " +
                                          item.quantity}
                                      </li>
                                      <p>
                                        {item.modifier_sale_price *
                                          item.quantity +
                                          " NOK"}
                                      </p>
                                    </div>
                                  )
                                );
                              }
                            );
                          }
                        )}
                      </p>
                    )}

                  {<></>}
                </>
              ))}
              <hr className="mt-1 mb-2"></hr>
          { this.props.special_ins.length >0 &&
                <div className="d-flex flex-column instruction-container">
                <span className="insturction-special-heading">
                  Instructions
                </span>
                <span className="instruction-para">
                  {this.props.special_ins}
                </span>
              </div>
          }
              <hr className="mt-1 mb-2"></hr>
            </div>
            {this.props.orderType && (
              <div
                className="d-flex justify-content-between"
                // style={{ marginBottom: "-15px", marginTop: "-45px" }}
              >
                <div className="location-sub-total-left">Order Mode</div>
                <div className="location-sub-total">{this.props.orderType}</div>
              </div>
            )}
            {this.props.orderType && (
              <div className="d-flex justify-content-between">
                <div className="location-sub-total-left">
                  {this.props.orderType === "Dinein" && this.props.tabNumber
                    ? "Table Number"
                    : "Time"}
                </div>
                <div className="location-sub-total">
                  {this.props.orderType === "Dinein" && this.props.tabNumber ? (
                    <span>{this.props.tabNumber}</span>
                  ) : this.props.orderType === "Pickup" ? (
                    <>
                      {this.props.preOrLater === "orderNow" ? (
                        <span>{this.props.setTime}</span>
                      ) : (
                        <>
                          {/* {this.props.futureDate && (
                            <span>{`${moment(this.props.futureDate).format(
                              "Do MMM"
                            )} / `}</span>
                          )} */}
                          {this.props.futureTime && (
                            <span>{this.props.futureTime}</span>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between ">
              <div className="location-sub-total-left">Subtotal</div>
              <div className="location-sub-total">
                {this.calcItemTotal(cartData) - this.calcTotalTax() + " NOK"}
              </div>
            </div>

            <div className="d-flex justify-content-between">
              {this.props.orderType == "Pickup" ? (
                <div className="location-sub-total-left">
                  VAT: {"(" + 0.15 * 100 + "%)"}
                </div>
              ) : (
                <div className=" location-sub-total-left">
                  VAT: {"(" + tax_rate * 100 + "%)"}
                </div>
              )}
              <div className=" location-sub-total">
                {_.isNaN(this.calcTotalTax())
                  ? 0 + " NOK"
                  : this.calcTotalTax() + " NOK"}
              </div>
            </div>

            <hr className="mt-2 mb-2"></hr>

            <div className="d-flex justify-content-between pt-6">
              <p
                className="location-total"
                style={{ fontFamily: "Playfiar Display" }}
              >
                Total
              </p>
              <p className="location-total" style={{ marginTop: "8px" }}>
                {this.calcItemTotal(cartData) + " NOK"}
              </p>
            </div>
            <h1 style={{ fontSize: "12px", marginTop: "-15px" }}>(ink. MVA)</h1>
            {
              !_.isEmpty(this.props.coupon) && (
                <div className="d-flex justify-content-between">
                  <p
                    style={{ color: "#d60000", fontWeight: "bold" }}
                    className="sub-details ml-5"
                  >
                    Discount/Voucher ({this.props.coupon.coupon_code})
                  </p>
                  <p
                    style={{ color: "#d60000", fontWeight: "bold" }}
                    className="sub-details ml-5"
                  >
                    {this.props.coupon.coupon_value !== 0
                      ? `-${
                          priceunit +
                          Math.round(
                            this.calcTotalDiscount(this.props.discountAmount)
                          )
                        }`
                      : `-${
                          priceunit +
                          Math.round(
                            this.calcTotalDiscount(this.props.discountAmount)
                          )
                        }`}
                  </p>
                </div>
              )
              // )
            }
            {this.calculateOrderDiscount(cartData) > 0 && (
              <div className="d-flex justify-content-between">
                <p
                  style={{ color: "#d60000", fontWeight: "bold" }}
                  className="sub-details ml-5"
                >
                  Your Discount
                </p>
                <p
                  style={{ color: "#d60000", fontWeight: "bold" }}
                  className="sub-details ml-5"
                >
                  {priceunit + this.calculateOrderDiscount(cartData)}
                </p>
              </div>
            )}
            <hr className="mt-2 mb-0"></hr>
          </div>
        );
      } else {
        <div>Empty Cart</div>;
      }
    } else {
      <div>Empty Cart</div>;
    }
  };
  cartTotal: any;
  totalTax: any;
  couponDiscount: any;
  discount: any;

  handleClose = () => {
    this.clicked = false;
    this.setState({
      showHide: false,
      clickNext: null,
      removeableLsmItemsArr: [],
    });
  };

  adddressHandler = (e: any) => {
    this.setState({ address: e.target.value });
  };
  showStrength = (strength: any) => {};
  render() {
    const token = sessionStorage.getItem("token");
    let store = sessionStorage.getItem("storeSelected");
    let parsedStore: any = store ? JSON.parse(store) : null;
    let { coupon, cities, addressData } = this.props;
    let { cart, storeSelected } = this.props;
    if (_.isEmpty(storeSelected)) {
      this.props.history.push("/");
      // window.location.href = '/menu'
    }
    let area_details: any = sessionStorage.getItem("area_details");
    let area: any;
    if (area_details) {
      area = area_details;
    }
    return (
      <main className="main co-main-container">
        <div className="container">
          <div className="mt-1 d-flex align-items-center menu-checkoutscreen">
            <Link to={`/${window.location.search}`}>
              <button className="back-arrow-circle">
                <img
                  className="back-arrow"
                  src={process.env.PUBLIC_URL + "/assets/images/Arrow Left.png"}
                  alt="Back Arrow"
                />
              </button>
            </Link>
            <h2 className="subtitle " style={{ margin: "0px" }}>
              Menu
            </h2>
          </div>
          <div className={`row ${this.props.closeTime || this.props.timeOver ? 'margin_custom_top_' : ''}`}>
            <div className=" col-lg-6 col-12 mt-8 login-sidepart">
              {/* <Login /> */}
              <LoginRightPart customerSignIn={this.props.customerSignIn} />
            </div>
            {/* {!this.props.cartToggle && ( */}
            <div className="col-lg-6 col-12 d-flex justify-content-center mt-5">
              <div className="location-cart-container-1">
                {this.cartDetail(cart)}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: any) => {
  const tabNumberFromStorage = parseInt(
    localStorage.getItem("tableNumber") || "0"
  );
  const timePickup = localStorage.getItem("setTime") || "ASAP 25 mins";

  const specialInstruction = localStorage.getItem("special inc");

  return {
    cart: state.login.cartData,
    orderType: state.login.orderType,
    futureDate: state.login.orderDate,
    futureTime: state.login.orderTime,
    preOrLater: state.login.preOrLater,
    setTime: state.login.setTime || timePickup,
    tabNumber: state.login.tabNumber || tabNumberFromStorage,
    paymentMethod: state.login.paymentMethod,
    deliveryfee: state.login.deliveryfee,
    houseDetail: state.login.houseDetail,
    storeSelected: state.login.storeSelected,
    taxData: state.login.taxData,
    guestInfo: state.login.guestInfo,
    taxDataCash: state.login.taxDataCash,
    taxDataCard: state.login.taxDataCard,
    taxAmount: state.login.taxAmount,
    discountAmount: state.login.discountAmount,
    cities: state.login.cities,
    tradezones: state.login.tradeZones,
    tradeareas: state.login.tradeAreas,
    store_web: state.login.qrStore,
    cityName: state.login.cityName,
    tradeZoneName: state.login.tradeZoneName,
    tradeAreaName: state.login.tradeAreaName,
    tradeZoneValue: state.login.tradeZoneValue,
    tradeAreaValue: state.login.tradeAreaValue,
    pos_fee: state.login.pos_fee,
    coupanCode: state.login.coupanCode,
    coupon: state.login.coupon,
    addressData: state.login.addressData,
    cartToggle: state.login.cartToggle,
    taxRate: state.login.taxRate,
    special_ins: state.login.special_instructions || specialInstruction,
    closeTime:state.search.CloseTime,
    timeOver:state.search.timerOver,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    SaveTaxValue: function (taxAmount: any) {
      dispatch(SaveTaxValue(taxAmount));
    },
    SaveDiscountValue: function (discountAmount: any) {
      dispatch(SaveDiscountValue(discountAmount));
    },
    addGuest: (data: any) => {
      dispatch(addGuest(data));
    },
    getCart: function () {
      dispatch(getCart());
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    saveOrderType: function (type: any) {
      dispatch(saveOrderType(type));
    },
    addressesListk: () => {
      dispatch(addressesListk());
    },
    findLocalStoresWithoutMap: (store_id: any) => {
      dispatch(findLocalStoresWithoutMap(store_id));
    },
    saveHouseDetail: (address: any) => {
      dispatch(saveHouseDetail(address));
    },
    customerSignIn: function (data: any, history: any) {
      dispatch(customerSignIn(data, history));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Location);
