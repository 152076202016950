import React,{useState} from 'react';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "@emotion/styled";
import Backdrop from '@mui/material/Backdrop';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { setOpenTimerModal } from '../../Redux/Reducers/searchReducer';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const StyledBackdrop = styled(Backdrop)({
  pointerEvents: 'none',
});

const TimeupModal = () => {

    const [cookiesModal, setCookiesModal] = useState(false);
    const TimerModal = useSelector((state:RootState)=>state.search.OpenTimerModal);
    const dispatch = useDispatch();

    const CookiesClose = () => {
         dispatch(setOpenTimerModal(false))
        setCookiesModal(false);
        window.location.reload();
        window.location.href = '/';
      };

      const CookiesOpen = () => {
        setCookiesModal(true);
      };
    const handleOverlayClick = (event:any) => {
        event.stopPropagation();
      };

  return (
    <Modal
    open={TimerModal}
    onClose={CookiesClose}
    BackdropComponent={StyledBackdrop}
    onBackdropClick={handleOverlayClick}
    aria-labelledby="child-modal-title"
    aria-describedby="child-modal-description"
  >
    <Box
      sx={{
        ...style,
        width: [312,400,400],
        border: "none",
        borderRadius: "10px",
        paddingBottom: "5px",
      }}
    >
      <div>
        {/* <h3 className="hour-title">Cookies</h3> */}
      
      </div>
     
      <p id="child-modal-description">
        <div className="d-flex flex-column ">
          <p className="cookeies-text mb-2">
          Your pickup time is really close to your current time would you please reconsider your pick up time.
          </p>
          <button className="cookeies-button" onClick={CookiesClose}>
        Ok {" "}
          </button>
        </div>
      </p>
    </Box>
  </Modal>
  )
}

export default TimeupModal