import React from "react";
import "./menu.css";

import { Link } from "react-router-dom";
class MenuSection extends React.Component<{history:any},{}> {
  render() {
  return (
    <section
    className="outer-menu-section"
      style={{
        background: "#F7F7F7",
        paddingTop: "3rem!important",
        paddingBottom: "3rem!important",
      }}
    >
      <div className="container">
        <div className="row">
         
        <div className="col-md-6 col-sm-12">
            <div className="menu-selection-left">
              <div className="right-text">
                <p>Our Menu</p>
                 <Link to="./">
                   {" "} 
                  <button className="menu-btn">View Full Menu</button>
                </Link>
              </div>
              <div style={{width:'60%'}}>
                <img className="menu-image"  src={process.env.PUBLIC_URL+"/assets/images/menu.png"} />
              </div>
            </div>
          </div>

          <div className="col-md-6 col-sm-12">
            <div className="menu-selection-right">
              <div className="right-text">
                <p>Download the App</p>
                <div className="download-app">
                {/* <a href="app link on google play"  target="_blank" title="" > */}
                                
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/PlayStore.svg"
                    }
                    style={{ cursor: "pointer" }}
                    
                  />
                  {/* </a> */}
                  {/* <a href="app link on apple store"  target="_blank" title="" > */}
                
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/AppStore.svg"}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  />
                  {/* </a> */}
                </div>
              </div>
              <div className="left-image">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/handholdingImg.svg"
                  }
                  height={245}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
                }
}

export default MenuSection;
