/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getStores, submitComplaint } from "../../Redux/Actions";
import {
  AddComplaintProps,
  AddComplaintStates,
} from "../Interface/loginInterface";
import "./contact.css";
import { withTranslation } from "react-i18next";
class Contact extends Component<AddComplaintProps, AddComplaintStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      fullname: "",
      email: "",
      feedbackType: "",
      receiptnumber: "",
      storeId: "",
      dateOfOrder: "",
      feedbackMessage: "",
      phone: "",
      netWork: false,
      isValidEmail: true,
      isValidPhone: true,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.validatePhone = this.validatePhone.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getStores();
  }
  handleSubmit = (event: any) => {
    const {
      fullname,
      email,
      phone,
      feedbackMessage,
      feedbackType,
      storeId,
      dateOfOrder,
      receiptnumber,
    } = this.state;
    this.setState({ netWork: true });
    const data: any = {
      name: fullname,
      email_address: email,
      phone_number: phone,
      receipt_number: receiptnumber,
      message: feedbackMessage,
      type: feedbackType,
      store_id: storeId,
      order_date: dateOfOrder,
    };
    setTimeout(() => {
      this.setState({ netWork: false });
    }, 3000);
    this.props.submitComplaint(data);
    // console.log(data)
  };
  // handleInputChange(property: any){
  //     return (e:any) => {
  //         this.setState({
  //           [property]: e.target.value
  //         });
  //       };
  // }
  handleInputChange(event: { target: { name: any; value: any } }) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  blockInvalidChar = (e: any) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  validatePhone(event: { target: { name: any; value: any } }) {
    let reg = /^[0-9\b]+$/;
    if (event.target.value === "" || reg.test(event.target.value)) {
      this.setState({ phone: event.target.value, isValidPhone: true });
    } else {
      event.target.value = event.target.value.slice(
        0,
        event.target.value.length - 1
      );
      this.setState({ phone: event.target.value, isValidPhone: false });
    }
  }
  validateEmail = (event: { target: { name: any; value: any } }) => {
    let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
    if (event.target.value != "") {
      if (reg.test(event.target.value)) {
        this.setState({ email: event.target.value, isValidEmail: true });
      } else {
        this.setState({ email: event.target.value, isValidEmail: false });
      }
    } else {
      this.setState({ email: event.target.value, isValidEmail: true });
    }
  };
  checkPhone = (phone: any) => {
    if (phone) {
      var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
      var mobileno = /^((\\+91-?)|0)?[0-9]{11}$/;
      if (phone.match(phoneno) || phone.match(mobileno)) {
        return true;
      } else {
        return false;
      }
    }
  };
  isUserReady = () => {
    let {
      fullname,
      email,
      phone,
      receiptnumber,
      feedbackType,
      feedbackMessage,
      storeId,
      dateOfOrder,
      isValidEmail,
    } = this.state;
    let validateSignal = false;
    if (
      fullname !== "" &&
      email !== "" &&
      feedbackMessage !== "" &&
      isValidEmail
    ) {
      validateSignal = true;
    }
    if (phone.length !== 11) {
      validateSignal = false;
    }
    return validateSignal;
  };

  render() {
    const { stores, t } = this.props;
    let todayDateTime = new Date();
    let today = todayDateTime.toISOString().split("T")[0];
    return (
      <main className="main mobile-contact-div" style={{ backgroundColor: "#f7f7f7" }}>
        <div className="about-section">
          <div className="container contact-us-contr">
            <h2 className="subtitle">{t("contactUs.title")}</h2>

            <div className="row p-3">
              <div
                className="col-12 p-5"
                style={{
                  margin: "auto",
                  backgroundColor: "white",
                  borderRadius: "24px",
                  boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div className="panel panel-default">
                  <div className="panel-body">
                    <div className="col-md-12">
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          {" "}
                          <br />
                        </div>
                        <div className="form-group col-md-6">
                          <h5 id="txt">
                            {t("contactUs.form.fullName")}
                            <sup style={{ color: "red", fontSize: "12px" }}>
                              *
                            </sup>
                          </h5>
                          <input
                            name="fullname"
                            placeholder={t("contactUs.form.fullName")}
                            style={{ fontSize: "15px" }}
                            type="text"
                            onChange={this.handleInputChange}
                            className="form-control"
                            id="round"
                            required
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <h5 id="txt">
                            {t("contactUs.form.phone")}{" "}
                            <sup style={{ color: "red", fontSize: "12px" }}>
                              *
                            </sup>
                          </h5>
                          <input
                            name="phone"
                            placeholder={t("contactUs.form.phone")}
                            style={{ fontSize: "15px" }}
                            type="tel"
                            maxLength={11}
                            onChange={this.validatePhone}
                            className="form-control"
                            id="round"
                            required
                          />
                          {!this.checkPhone(this.state.phone) &&
                            this.state.phone !== "" && (
                              <small className="form-text text-danger">
                                {t("signup.form.invalidPhone")}
                              </small>
                            )}
                        </div>

                        <div className="form-group col-md-6">
                          <h5 id="txt">
                            {t("contactUs.form.email")}{" "}
                            <sup style={{ color: "red", fontSize: "12px" }}>
                              *
                            </sup>
                          </h5>
                          <input
                            name="email"
                            placeholder={t("contactUs.form.email")}
                            style={{ fontSize: "15px" }}
                            type="email"
                            onChange={this.validateEmail}
                            className="form-control"
                            id="round"
                            required
                          />
                          {this.state.isValidEmail == false &&
                            this.state.email !== "" && (
                              <small className="form-text text-danger">
                                {t("login.form.invalidEmail")}
                              </small>
                            )}
                        </div>
                        {/* <div className="form-group col-md-6">
                                                    <h5 id="txt">Select Ordering Store <sup style={{ color: "red", fontSize: "12px" }}>*</sup></h5>

                                                    <select onChange={this.handleInputChange} style={{ paddingLeft: '2rem' }} className="custom-select custom-select-lg mb-3 w-100" id="round1" name="storeId">
                                                        <option value="">Select Store</option>

                                                        {stores &&
                                                            stores.map((store, index) => (
                                                                <option key={index} value={store.store_id}>{store.store_name}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </div> */}

                        {/* <div className="form-group col-md-6">
                                                        <h5 id="txt">Order Date <sup style={{ color: "red", fontSize: "12px" }}>*</sup> </h5>
                                                        <input type="date" style={{ fontSize: "15px" }} max={today} name="dateOfOrder" required data-msg="Please Enter Order Date" className="form-control laterDateInput" onChange={this.handleInputChange} id="round" />
                                                </div> */}
                        <div className="form-group col-md-6">
                          <h5 id="txt">
                            {t("contactUs.form.orderNo")}{" "}
                            <sup style={{ color: "red", fontSize: "12px" }}>
                              *
                            </sup>
                          </h5>
                          <input
                            name="receiptnumber"
                            style={{ fontSize: "15px" }}
                            type="number"
                            min={1}
                            placeholder={t("contactUs.form.orderNo")}
                            onKeyDown={this.blockInvalidChar}
                            onChange={this.handleInputChange}
                            className="form-control"
                            id="round"
                          />
                        </div>
                        {/* <div className="form-group col-md-12">
                                                    <h5 id="txt">  Feedback Type <sup style={{ color: "red", fontSize: "12px" }}>*</sup></h5>
                                                    <select onChange={this.handleInputChange} style={{ paddingLeft: '2rem' }} className="custom-select custom-select-lg mb-3 w-100" id="round1" name="feedbackType">
                                                        <option value="">Select Feedback Type</option>
                                                        <option value="Comments">Comments</option>
                                                        <option value="Suggestions">Suggestions</option>
                                                        <option value="Questions">Questions</option>
                                                    </select>
                                                </div> */}
                        <div className="form-group col-md-12">
                          <h5 id="txt">
                            {t("contactUs.form.feedBack")}{" "}
                            <sup style={{ color: "red", fontSize: "12px" }}>
                              *
                            </sup>
                          </h5>
                          <textarea
                            name="feedbackMessage"
                            id="round2"
                            style={{ fontSize: "15px" }}
                            rows={8}
                            cols={120}
                            onChange={this.handleInputChange}
                          ></textarea>
                        </div>
                        <div className="form-group col-md-4">
                          {this.state.netWork == false ? (
                            <button
                              disabled={!this.isUserReady()}
                              onClick={this.handleSubmit}
                              id="h"
                              style={{
                                borderRadius: "6px",
                                color: "white",
                                background: '#364d49',
                                padding: '10px 30px'
                              }}
                              type="submit"
                              className="btn btn-light"
                            >
                              {t("contactUs.btns.submit")}
                            </button>
                          ) : (
                            <button
                              disabled={this.state.netWork}
                              id="h"
                              className="btn cart-button"
                            >
                              <i className="fa fa-spinner fa-spin"></i>{" "}
                              {t("contactUs.form.loading")}
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    stores: state.login.restuarants,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getStores: function (data: any) {
      dispatch(getStores(data));
    },
    submitComplaint: function (data: any) {
      dispatch(submitComplaint(data));
    },
    // signupmodalClose: function () {
    //   dispatch(signupmodalClose());
    // },
  };
};

export default  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Contact));
