import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import FAQComp from "../components/Faq/index";
// import Sidebar from "../components/Header/mobileSidebar";

class Faq extends Component<{history:any},{}> {
  componentDidMount(){
    document.title = "Faq";
  }
  render() {
    return (
      <div className="page-wrapper">
        {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"root"} /> */}
        <div id="page-wrap">
          <Header history={this.props.history} />
          <FAQComp />
          <Footer />
        </div>

      </div>
    );
  }
}

export default Faq;
