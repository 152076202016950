import React from "react";
import "./NewFooter.css";
import { Link } from "react-router-dom";
import { GrLinkedinOption } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import NewFooter from "../NewFooter";
function Footer() {
  const { i18n, t } = useTranslation();
  const d = new Date();
  const changeLang = (lang: any) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("locale", lang);
  };
  return (
    <>
      <div className="web-footer d-md-none d-lg-block" style={{ marginTop: "auto" }}  >
        <footer className="footer" style={{ fontFamily: "Playfair Display" }}>
          <div className="footer-content">
            <div className="container">
              <div className="row footer-row">
                <div className="col-lg-2 col-sm-6">
                  <div className="widget widget-newsletter">
                    <div className="row w-100 d-flex align-items-center justify-content-left"></div>
                    <div className="palacecafe-logo">
                      {/* <img
                                                    className="header-logo-styling"
                                                    src={process.env.PUBLIC_URL + "/logo192.webp"}
                                                    alt="PalaceCafe Logo"
                                                    style={{ cursor: "pointer" }}
                                                /> */}
                      <div className="social-icons web-social-links">
                        <a
                          href="https://www.instagram.com/palacecafe.no/"
                          className="social-icon social-instagram icon-instagram"
                          target="_blank"
                          title="Instagram"
                        />
                        <a
                          href="https://www.facebook.com/palacecafe.no"
                          className="social-icon social-facebook icon-facebook"
                          target="_blank"
                          title="Facebook"
                        />
                      </div>
                    </div>
                  </div>
                  {/* End .widget */}
                </div>
                {/* End .col-lg-3 */}
                <div className="col-lg-4 col-sm-6">
                  <div className="widget ">
                    {/* <h4 className="widget-title menu-title">Menu</h4> */}
                    <ul className="contact-info">
                      <ul className="links">
                      <div className="row justify-content-center">
                        <li style={{ color: "whitesmoke", fontSize: "16px" }}>
                          <i className="fa fa-home" />
                          <a className="ml-2">
                            Glommengata 69, 2211 Kongsvinger
                          </a>
                        </li>
                        </div>
                        {/* <li><Link to={{ pathname: "/restaurantLocator", search: window.location.search }}>Restaurant</Link></li> */}
                        {/* <li><Link to={{ pathname: "/aboutUs", search: window.location.search }}>About</Link></li> */}
                        {/* <li><Link to={{ pathname: "/contact", search: window.location.search }}>Contact</Link></li> */}
                      </ul>
                    </ul>
                    {/* End .social-icons */}
                  </div>
                  {/* End .widget */}
                </div>
                {/* End .col-lg-3 */}
                <div className="col-lg-3 col-sm-6">
                  <div className="widget">
                    <ul className="contact-info">
                      <ul className="links">
                        <div className="row justify-content-center">
                          <li style={{ color: "whitesmoke", fontSize: "16px" }}>
                            <i className="far fa-envelope-open" />
                            <a className="ml-2">bestilling@palacecafe.no</a>
                          </li>
                        </div>
                      </ul>
                    </ul>
                  </div>
                </div>
                {/* End .col-lg-3 */}
                <div className="col-lg-3 col-sm-6" >
                  <div className="widget d-flex justify-content-center">
                    <ul className="links">
                      <li style={{ color: "whitesmoke", fontSize: "16px" }}>
                        <i className="fas fa-mobile-alt" />
                        <a className="ml-2 hover_link" href="tel:021111116787">
                          +47 414 22 274
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* End .widget */}
                </div>
                {/* End .col-lg-3 */}
              </div>
              {/* End .row */}
            </div>
            {/* End .container */}
          </div>
          {/* End .footer-middle */}
          <div className="container">
            <div className="footer-bottom d-flex justify-content-between align-items-center flex-wrap col-xs-4">
              <p
                style={{
                  color: "whitesmoke",
                  position: "relative",
                  left: "50px",
                }}
                className="footer-copyright py-3 pr-4 mb-0"
              >
                {d.getFullYear()} Palace Cafe. {t("footer.rightsReserved")}
              </p>
              <ul style={{ display: "contents" }}>
                <li
                  style={{
                    color: "whitesmoke",
                    position: "relative",
                    left: "40px",
                    
                  }}
                >
                  <Link
                  className="hover_link"
                    to={{
                      pathname: "/PrivacyPolicy",
                      search: window.location.search,
                      
                    }}
                  >
                    {t("footer.privacy")}
                  </Link>
                </li>
                <li
                  style={{
                    color: "whitesmoke",
                    position: "relative",
                    left: "-70px",
                  }}
                >
                  <Link
                   className="hover_link"
                    to={{
                      pathname: "/conditions",
                      search: window.location.search,
                    }}
                  >
                    {t("footer.tnc")}
                  </Link>
                </li>
              </ul>
              <div
                className="powered-by"
                style={{ position: "relative", left: "-70px" }}
              >
                <p className="text-h-footer mb-0  col-sm-12">
                  <div className="row mt-2">
                    {t("footer.pwrdBy")}{" "}
                    <a href="https://etcnor.no/" target="/blank">
                      <h6
                        className="ml-3"
                        style={{ color: "#fff", fontSize: "18px" }}
                      >
                        RIZQ
                      </h6>
                    </a>
                  </div>
                </p>
              </div>
            </div>
            {/* End .footer-bottom */}
          </div>
          {/* End .container */}
        </footer>
        {/* End .footer */}
      </div>
      <NewFooter />
      <div className="mobile-footer">
        <div className='footer_logo d-flex flex-row justify-content-between align-items-center padding_custom_footer'>
          <img src={process.env.PUBLIC_URL + "/logo192.png"} alt='website_logo' width={200} height={200} />
          <div className='social_media_links d-flex flex-row'>
   <a href="https://www.facebook.com/palacecafe.no" target="_blank" rel="noreferrer">
    <i className="bi bi-facebook transparent-white-icon"></i></a>
            {/* <i className="bi bi-linkedin transparent-white-icon"></i> */}
     <a href="https://www.instagram.com/palacecafe.no/" target="_blank" rel="noreferrer">
     <i className="bi bi-instagram transparent-white-icon"></i>
     </a>
          </div>
        </div>
        <div className='footer_address d-flex flex-column padding_custom_footer'>
          <span><i className="bi bi-geo-alt-fill mx-3"></i>Glommengata 69, 2211 Kongsvinger</span>
          <span><i className="bi bi-telephone-fill mx-3 hover_link"></i>+47 414 22 274</span>
          <span><i className="bi bi-envelope-fill mx-3"></i>bestilling@palacecafe.no</span>
        </div>
        <hr className="w-100 text-white my-5" />
        <div className="link_footer d-flex flex-row justify-content-evenly padding_custom_footer">
          <a href="" className="mx-3 hover_link">Terms and Conditons</a>
          <a href="" className="mx-3 hover_link">Privacy Policy</a>
          <a href="" className="mx-3 hover_link">Cookies</a>
        </div>
        <p className="my-3 text-white padding_custom_footer" style={{ fontFamily: 'Glacial Indifference' }}>@ 2024 PalaceCafe. All Rights Reserved.</p>
        <div className="d-flex flex-row text-center padding_custom_footer">
          <span className="text-white mx-3" style={{ fontFamily: 'Glacial Indifference' }}>Powered By</span>
          <img src={process.env.PUBLIC_URL + "/footer_side_logo.png"} />
        </div>
      </div>
    </>
  );
}

export default Footer;
