import React from "react";
import { Home, About, Contact } from ".";
import {
  getCart,
  logoutCustomer,
  menuGroupsList,
  menuItemsListByGroupsID,
  saveCart,
} from "../../../Redux";
import {
  cartToggle,
  emptyFilteredItems,
  featuredProducts,
  findStoreforLSM,
  getStoreById,
  menuGroupsListHome,
  saveOrderType,
  saveSelectStoreId,
  saveStore,
  saveurlMethod,
  searchItems,
  setTabName,
  setTradeZoneName,
  TopDealsHome,
  TopItemsList,
  TopList,
  updateLSM,
  UpdateMenuItems,
} from "../../../Redux/Actions";
import { connect } from "react-redux";
function Main(props: any) {
  return (
    <main>
      <Home />
      {/*<About />
			<Contact /> */}
    </main>
  );
}
const mapStateToProps = (state: any) => {
  return {
    groupList: state.login.groupList,
    cart: state.login.cartData,
    GroupName: state.login.GroupName,
    groupsData: state.login.groupsData,
    storeSelected: state.login.storeSelected,
    cartToggle: state.login.cartToggle,
    orderType: state.login.orderType,
    cityName: state.login.cityName,
    tradeZoneName: state.login.tradeZoneName,
    tradeAreaName: state.login.tradeAreaName,
    addressData: state.login.addressData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutCustomer: function () {
      dispatch(logoutCustomer());
    },
    // findLocalStores: function (lat: any, long: any) {
    //   dispatch(findLocalStores(lat, long));
    // },
    // menuCombosList: function (data: any) {
    //   dispatch(menuCombosList(data));
    // },
    getCart: function (history?: any) {
      dispatch(getCart(history));
    },
    menuGroupsList: function (store_id: any) {
      dispatch(menuGroupsList(store_id));
    },
    menuItemsListByGroupsID: function (data: any) {
      dispatch(menuItemsListByGroupsID(data));
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    updateLSM: function (status: any) {
      dispatch(updateLSM(status));
    },
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    saveurlMethod: function (url: any) {
      dispatch(saveurlMethod(url));
    },
    findStoreforLSM: function (lat: any, long: any) {
      dispatch(findStoreforLSM(lat, long));
    },
    UpdateMenuItems: function (data: any) {
      dispatch(UpdateMenuItems(data));
    },
    TopDealsHome: function (store_id: any) {
      dispatch(TopDealsHome(store_id));
    },
    TopList: function (store_id: any) {
      dispatch(TopList(store_id));
    },
    menuGroupsListHome: function (store_id: any) {
      dispatch(menuGroupsListHome(store_id));
    },
    TopItemsList: function (store_id: any) {
      dispatch(TopItemsList(store_id));
    },
    getStoreById: function (store_id: any, mode: any) {
      dispatch(getStoreById(store_id, mode));
    },
    saveOrderType: function (type: any) {
      dispatch(saveOrderType(type));
    },
    cartToggleAction: (toggle: any) => {
      dispatch(cartToggle(toggle));
    },
    featuredProducts: (store_id: any) => {
      dispatch(featuredProducts(store_id));
    },
    saveStore: (store: any) => {
      dispatch(saveStore(store));
    },
    saveSelectStoreId: (store: any) => {
      dispatch(saveSelectStoreId(store));
    },
    setTradeZoneName: (name: any, value: any) => {
      dispatch(setTradeZoneName(name, value));
    },
    searchItems: (data: any) => {
      dispatch(searchItems(data));
    },
    emptyFilteredItems: () => {
      dispatch(emptyFilteredItems());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
