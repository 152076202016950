import React, { useState, useEffect } from "react";
import "./confirmorder.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import QRCode from "react-qr-code";

interface ProductDetail {
  title: string;
  price: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: [357, 560, 560],
  bgcolor: "background.paper",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

const ConfirmOrder = (props: ProductDetail) => {
  const dummyDataproduct: ProductDetail[] = [
    {
      title: "1 x Grillet Ost & Skinke",
      price: "kr164",
    },
    {
      title: "1 x Grillet Ost & Skinke",
      price: "kr164",
    },
  ];

  const dummyExtraData: ProductDetail[] = [
    {
      title: "1x Extra Sauce",
      price: "10kr",
    },
    {
      title: "1x Extra Sauce",
      price: "10kr",
    },
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [value, setValue] = useState(
    `http://192.168.100.40:5001/login?param=true`
  );
  const [back, setBack] = useState("#FFFFFF");
  const [fore, setFore] = useState("#000000");
  const [size, setSize] = useState(256);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const param1 = urlParams.get("param");
    if (param1) {
      setOpen(true);
    }
  }, [value]);

  return (
    <div className="main-container-order">
      <div className="header-order d-flex justify-content-center align-items-center">
        <span>Download Palace Cafe app to track your order</span>
        <button onClick={handleOpen}>Download</button>
      </div>
      <div className="container d-flex justify-content-center">
        <div className="container-outer">
          <div style={{ marginBottom: "15px" }}>
            <a href="/">
              <i
                className="bi bi-arrow-left mr-3"
                style={{ fontSize: "18px", color: "#364D49" }}
              ></i>
              <span className="back-menu-btn">Back to menu</span>
            </a>
          </div>
          <div className="order-box">
            <span className="order-heading">Your Order is Confirmed</span>
            {dummyDataproduct.map((item) => (
              <div className="d-flex flex-column">
                <div className="section_detail_order d-flex justify-content-between align-items-center">
                  <span className="product_name">{item.title}</span>
                  <span className="product_price">{item.price}</span>
                </div>
                {dummyExtraData.map((extra) => (
                  <div className="d-flex justify-content-between flex-column">
                    <div className="d-flex justify-content-between extra_detail_card">
                      <span className="product_extra_title">{extra.title}</span>
                      <span className="product_extra-price">{extra.price}</span>
                    </div>
                    <div></div>
                  </div>
                ))}
              </div>
            ))}
            <hr className="custom-line-hr" />
            <div className="d-flex flex-column">
              <h5 className="order-detail-heading">Order details</h5>
              <div className="d-flex justify-content-between custom_detail_card">
                <span className="order_detail_left">Order No</span>
                <span className="order_detail_right">123456789</span>
              </div>
              <div className="d-flex justify-content-between custom_detail_card">
                <span className="order_detail_left">Order mode</span>
                <span className="order_detail_right">Pickup</span>
              </div>
              <div className="d-flex justify-content-between custom_detail_card">
                <span className="order_detail_left">Order time</span>
                <span className="order_detail_right">ASAP(20 min)</span>
              </div>
              <div className="d-flex justify-content-between custom_detail_card flex-column">
                <span className="order_detail_left">Instructions</span>
                <span className="order_detail_right mt-1">Nil</span>
              </div>
            </div>

            <hr className="my-2" />
            <div className="d-flex flex-column">
              <h5 className="order-detail-heading">Price</h5>
              <div className="d-flex justify-content-between custom_detail_card">
                <span className="order_detail_left">Items total</span>
                <span className="order_detail_right">kr 133</span>
              </div>
              <div className="d-flex justify-content-between custom_detail_card">
                <span className="order_detail_left">
                  VAT <sub className="font_subelement">(15%)</sub>
                </span>
                <span className="order_detail_right">kr 133</span>
              </div>
            </div>
            <div className="d-flex flex-row align-items-center justify-content-between custom_detail_card">
              <span className="price-title-heading">Total</span>
              <span className="price-total_right">kr 160</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column ">
            <div className="d-flex mb-2">
              <span className="modal-heading">Download Application</span>
              <div className="close-btn-order" onClick={handleClose}>
                <i className="bi bi-x"></i>
              </div>
            </div>
            <hr className="my-2" />
            <div className="d-flex flex-column justify-content-center align-items-center mt-2 qr-image-sec">
              {value && (
                <QRCode
                  value={value}
                  bgColor={back}
                  fgColor={fore}
                  size={size}
                />
              )}
              <span className="qr-code-text mt-1">Scan QR Code</span>
            </div>
            <div className="d-md-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-none">
              <img
                className="qr-code-image"
                src={process.env.PUBLIC_URL + "/apple.png"}
                alt="qr code"
              />
              <img
                className="qr-code-image"
                src={process.env.PUBLIC_URL + "/play.png"}
                alt="qr code"
              />
            </div>
            <div className="d-flex flex-md-row flex-column justify-content-md-around sec-button-modal mt-2 d-md-none">
              <a href="">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/apple.png"}
                  alt="qr code"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=no.palacecafeapp.etcnor&hl=en&gl=US">
                <img
                  className="qr-code-image"
                  src={process.env.PUBLIC_URL + "/play.png"}
                  alt="qr code"
                />
              </a>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ConfirmOrder;
