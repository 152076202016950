import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";

class index extends Component {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <main className="main" style={{ backgroundColor: '#f7f7f7' }} >

                <div className="about-section"  >
                    <div className="container">
                        <h2 className="subtitle">Palace Cafe &nbsp; FAQs</h2>
                        <div className="row p-3">
                            <div className="col-lg-12 col-12 p-5"
                                style={{
                                    margin: 'auto', backgroundColor: 'white',
                                    borderRadius: '24px',
                                    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.08)'
                                }}>
                                <div className='about-details'>
                                <div className="col-lg-12">
                                <p><strong></strong></p>
                                <p></p>
                                <p><strong></strong></p>
                                <p></p>
                                <p><strong></strong></p>
                                <p></p>
                                <p><strong></strong></p>
                                <p></p>
                                <p><strong></strong></p>
                                <p></p>
                                <p><strong></strong></p>
                                <p></p>
                                <p><strong></strong></p>
                                <p></p>
                                <p><strong></strong></p>
                                <p></p>
                                
                            </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            
        );
    }
}

export default index;
