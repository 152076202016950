import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import './condition.css'

class Conditions extends Component {
    constructor(props: any) {
        super(props);
    } 
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <main className="main" style={{ backgroundColor: '#f7f7f7' }} >
                <div className="about-section condition-box-custom"  >
                    <div className="container">
                        <h2 className="subtitle condition-title">Vilkår og betingelser</h2>
                        <div className="row p-3 custom-width-box">
                            <div className="col-lg-12 col-12 p-5"
                                style={{
                                    margin: 'auto', backgroundColor: 'white',
                                    borderRadius: '24px',
                                    boxShadow: '0px 16px 24px rgba(0, 0, 0, 0.08)'
                                }}>
                                <div className='about-details'>
                                <div className="col-lg-12">
                                {/* <p className="text-center"><strong>PROVISIONS FOR PRIVACY</strong></p> */}
                                
                                <p className="heading-condition"><strong><span className="number-condition">1.</span> Firma</strong></p>
                                <p className="para-condition">Palace Cafe Nettbestilling <a href="https://order.palacecafe.no/">https://order.palacecafe.no/</a> er levert av Etcetera Norway AS, organisasjonsnummer 927 043 912. Informasjon og bilder på
                                <br/>nettstedet er eid av selskapet eller partner og kan ikke kopieres uten tillatelse.
                                <br/>Kontakt informasjon
                                <br/>Telefon nummer: 414 22 274
                                <br/>Epost adresse: Tehseen@palacecafe.no<br/>
                                Juridisk adresse: Lensmann Jahnsens veg 6B, 2211 Kongsvinger</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">2.</span> Priser</strong></p>
                                <p className="para-condition">Alle priser vises i Norske kroner og er inkludert MVA. I handlekurven ser du totalprisen inkludert alle avgifter.</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">3.1</span> Betaling</strong></p>
                                <p className="para-condition">Trekk av kort gjøres umiddelbart med kjøp</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">3.2.1</span> Faktura & delbetaling</strong></p>
                                <p className="para-condition">I samarbeid med Collector Bank tilbyr vi en trygg og enkel faktura- og delbetalingsløsning. Tjenesten tilbyr deg en engangskreditt som betaling for dine
                                kjøp. Engangskreditten innebærer at kreditten er bundet til det enkelte kjøp og at du som forbruker ikke har noe ytterligere utestående kredittforhold med
                                Collector Bank. Mer informasjon og generelle kredittvilkår for betalingsløsningen finner du på bill.collectorpayments.no
                                </p>
                                <p className="para-condition">Ved valg av faktura er forfallstiden 30 dager. Faktura sendes til den e-postadressen du har oppgitt eller til din folkeregistrerte adresse avhengig av ditt valg
                                av distribusjonsmåte på kjøpstidspunktet. Ved valg av faktura kan du velge å betale hele beløpet med en gang eller alternativt logge inn på
                                bill.collectorpayments.no og søke om en delbetalingsavtale.
                                </p>
                                <p className="para-condition">
                                For å kunne benytte tjenesten må du på kjøpstidspunktet ha en permanent og offisiell norsk bostedsadresse og være minst 18 år gammel. Betalingsmåten er
                                bare tilgjengelig for privatpersoner, og forutsetter at du har tatt vare på dine økonomiske forpliktelser og har en god betalingshistorikk.
                                </p>
                                <p className="para-condition">
                                En kredittsjekk gjennomføres i forbindelse med kjøpet. Kredittvurderingen utføres av Collector Bank Payment Services AS, og informasjonen vil ikke bli
                                lagret hos nettbutikken hvor kjøpet blir utført. Din personlige informasjon vil kun bli benyttet til å beslutte om kreditt skal gis eller ikke. Ved for sen eller
                                uteblitt betaling tilkommer purregebyr og forsinkelsesrente og eventuelle omkostninger ved inkasso.
                                </p>
                                <p className="para-condition">For fullstendige vilkår for Collector Bank's faktura- og delbetalingstjeneste, se lenkene nedenfor:</p>
                                <p className="para-condition">Collector Bank's generelle kredittvilkår for faktura og delbetaling</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition"
                                >3.2.2</span> Spørsmål knyttet til din faktura eller delbetaling</strong></p>
                                <p className="para-condition">Collector Bank's kundeservice hjelper deg med alle spørsmål angående din faktura eller delbetaling. Kontakt Collector Bank's kundeservice via e-post på
                                bill@collectorpayments.no eller via telefon 21 89 63 20, mandag – fredag kl. 08.00 – 17.00</p>
                                <p className="para-condition">Se også FAQ på bill.collectorpayments.no</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">3.3.3</span> Tilbakebetaling ved faktura- og delbetalingskjøp</strong></p>
                                <p className="para-condition">Dersom du har betalt din faktura eller delbetaling, men senere angrer på ditt kjøp har du et tilgodehavende hos Collector Bank. Collector Bank
                                tilbakebetaler den summen du har betalt etter at vi har fått opplysninger fra forhandler om godkjent retur. Tilbakebetalingen vil skje til din konto og du må
                                derfor informere oss om ditt bankkontonummer og fakturaopplysninger.</p>
                                <p className="para-condition">Kontakt Collector Bank's kundeservice via e-post bill@collectorpayments.no eller via telefon 21 89 63 20, mandag – fredag kl. 08.00 – 17.00.</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">3.3.4</span> Pris</strong></p>
                                <p className="para-condition">En fakturaavgift kan tilkomme på faktura med 30 dagers forfall. For delbetaling påløper et månedlig gebyr på NOK 39 og en rente på 21 %. Normalt tilbys
                                delbetaling over 3, 6, 12, 24 eller 36 måneder spesifisert i henhold til kjøpesum.
                                </p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">4.</span> Frakt</strong></p>
                                <p className="para-condition">Vi har ikke frakt</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">4.1</span>Leveringsmetoder</strong></p>
                                <p className="para-condition">Vi leverer ikke varer!</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">4.2</span> Leveranser</strong></p>
                                <p className="para-condition">Ordrer plassert før 14.00 behandles på samme dag og leveres vanligvis i løpet av 1-3 dager. Leveransen skjer direkte til den adressen du har angitt vedbestilling</p>                                
                                
                                <p className="heading-condition"><strong><span className="number-condition">4.3</span> Skadede varer og reklamasjonsrett</strong></p>
                                <p className="para-condition">Når du mottar din ordre, er det viktig å sjekke varene for skader eller feil. Skulle varen være skadet eller ødelagt, kan du kontakte oss via
                                <a href="https://palacecafe.no/kontakt-oss/">https://palacecafe.no/kontakt-oss/</a> for videre instruksjoner. Du har to års reklamasjonsrett i henhold til norsk lov.</p>
                                
                                <p className="heading-condition"><strong><span className="number-condition">5.</span> Angrerett</strong></p>
                                <p className="para-condition">Når bestillingen er betalt fra din side, kan den ikke endres eller avbestilles. Fersk mat er unntatt fra loven om angrerett.
                                </p>
                                <p className="heading-condition"><strong><span className="number-condition">6.</span> Personvern, sikkerhet og personopplysninger</strong></p>
                                <p className="para-condition">I forbindelse med bestillingen, godtar du av vi lagrer og bruker informasjonen din. Vi gjør dette for å oppfylle våre forpliktelser overfor deg. Vi
                                dokumenterer også all kommunikasjon vi har med deg via e-post, for å kunne tilby tjenesten som du forventer av oss. Vi vil ikke dele dine personlige
                                informasjon til tredjepart.
                                </p>
                                <p className="heading-condition"><strong><span className="number-condition">7.</span> Kontakt & Support</strong></p>
                                <p className="para-condition">Kontakt og support vil du finne HER</p>
                                <p className="heading-condition"><strong><span className="number-condition">8.</span> Cookies </strong></p>
                                <p className="para-condition">En cookie er en tekstfil som sendes fra et nettsted til datamaskinen der den er lagret enten i minnet (session cookies) eller som en tekstfil (tekstbaserte
                                informasjonskapsler). Cookies brukes til å lagre logg-inn eller handlekurven mens du surfer rundt på ulike nettsteder. Hvis nettleseren din er satt til å ikke
                                akseptere cookies, vil du ikke være i stand til å gjøre noen ordre på nettstedet vårt. Du kan enkelt endre dette i innstillingene til nettleseren din. Vær
                                oppmerksom på at vi ikke bruker cookies for å lagre personlig informasjon om deg</p>
                                <p className="heading-condition"><strong><span className="number-condition">9.1</span> Force majeure</strong></p>
                                <p className="para-condition">Særlige omstendigheter som ikke er under menneskelig kontroll slik som krig, omfattende arbeidskonflikt, blokade, brann, miljøkatastrofe, alvorlig
                                infeksjon som hindrer partene å oppfylle sine forpliktelser kan frigjøre en part fra forpliktelsene. Slikt unntak gjelder under forutsetning av at operasjonen
                                ikke kan utføres under disse betingelsene. Den andre parten skal straks varsles om omstendighetene ved bruk av denne bestemmelsen</p>
                                <p className="heading-condition"><strong><span className="number-condition">9.2</span> Endring av kontrakts</strong></p>
                                <p className="para-condition">Etter at du som kunde har gjort et kjøp har vi har ikke rett til å endre vilkårene i det aktuelle kjøpet med mindre annet er avtalt</p>
                                <p className="heading-condition"><strong><span className="number-condition">9.3</span> Klager</strong></p>
                                <p className="para-condition">Ved klage, vennligst kontakt oss på <a href="https://palacecafe.no/kontakt-oss/">https://palacecafe.no/kontakt-oss/</a></p>
                                <p className="heading-condition"><strong><span className="number-condition">9.4</span> Tvister og lover</strong></p>
                                <p className="para-condition">Norsk lov skal gjelde for alle kjøp gjort under disse vilkårene. Tvister om kjøp under disse vilkår skal utelukkende behandles av en Norsk domstol</p>
                            </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
         
        );
    }
}

export default Conditions;
