
import { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import Contact from "./screens/Contact";
import About from "./screens/Aboutus";
import Profile from "./screens/UserProfile";
import Login from "./screens/Login";
import Signup from "./screens/Signup";
import Location from "./screens/Checkout";
import Payment from "./screens/Payment";
import Home from "./screens/Home";
import NotFound from "./screens/404";
import Forgot from "./screens/Forget";
import Orders from "./screens/Orders";
import Faq from "./screens/Faq";
import Privacy from "./screens/Privacy";
import TrackOrder from "./components/TrackOrder";
import ConditionOfSale from "./screens/ConditionofSale";
import Ordertrack from './screens/Ordertrack';
import AcceptOrder from "./screens/Accept";
import Language from "./screens/Language";
import Confirm from "./screens/Confirm";
import TestQr from "./screens/TestQr";
const token = sessionStorage.getItem("token");



function App() {
  // const dispatch = useDispatch();
  // const elementRef = useRef<HTMLDivElement>(null);
  // const [elementWidth, setElementWidth] = useState(0);
  // const [elementHeight, setElementHeight] = useState(0);
  // useLayoutEffect(() => {
  //   function handleResize() {
  //     if (elementRef.current) {
  //       const width = elementRef.current?.offsetWidth;
  //       const height = elementRef.current?.offsetHeight;
  //       setElementWidth(width);
  //       setElementHeight(height);
  //       dispatch(widthState(width));
  //       dispatch(heightState(height));
  //     }
  //   }
  //   handleResize(); // initial call to get width and height of the element
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [elementRef]);

  const [isOpen, setIsOpen] = useState(true);
  let location = useLocation();
  const toggleHtmlOverflow = (hidden: any) => {
    document.documentElement.style.overflow = hidden ? "hidden" : "auto";
  };
  useEffect(() => {
    toggleHtmlOverflow(isOpen);
  }, [isOpen]);

  useEffect(() => {}, [location]);
  return (
    <Provider store={store}>
      {/* {isOpen && <CookieModal setIsOpen={setIsOpen} isOpen={isOpen} />} */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/order" component={Orders} />
        <Route path="/aboutus" component={About} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/reset-password" component={Forgot} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/checkout" component={Location} />
        <Route exact path="/ordertrack" component={Ordertrack} />
        <Route exact path="/payment" component={Payment} />
        {/* <Route exact path="/language" component={Language} /> */}
        <Route exact path="/trackorder" component={TrackOrder} />
        <Route exact  path="/confirmorder" component={Confirm} />
        <Route exact path="/Faqs" component={Faq} />
        <Route exact path="/PrivacyPolicy" component={Privacy} />
        <Route exact path="/conditions" component={ConditionOfSale} />
        <Route path="/accept" component={AcceptOrder} />
        <Route path="/contact" component={Contact} />
        <Route path="/test" component={TestQr} />
        <Route path="*" exact component={NotFound} />
        

        {/* <Route path="*" exact component={NotFound} /> */}
      </Switch>
    </Provider>
  );
}

export default App;
