import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import TestQrMode from '../components/testQr'

const TestQr = (props:any) => {
    let history = props.history;
  return (
    <div className="page-wrapper">
    <Header history={history} />
   <TestQrMode />
    <Footer />
  </div>
  )
}

export default TestQr