import React, { Component } from "react";
import Header from '../components/Header'
import Footer from '../components/Footer'
import ForgotCompponent from "../components/ForgotPassword";
// import Sidebar from "../components/Header/mobileSidebar";

class Forgot extends Component<{history:any},{}> {
    componentDidMount() {
        document.title = "Forgot Password";
    }
    render() {
        return (
            <div>
                {/* <Sidebar pageWrapId={"page-wrap"} outerContainerId={"root"} /> */}
                <div id="page-wrap">
                    <Header history={this.props.history} />
                    <ForgotCompponent />
                    <Footer />
                </div>

            </div>
        );
    }
}

export default Forgot;