import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SearchTerm {
  cartModal: boolean;
  isSearching: boolean;
  width: number;
  height: number;
  cartQuantity: number;
  SideBar: boolean;
  tableNumberCart: number;
  timerOver: boolean;
  isTime: any;
  storeStart:any,
  storeClose:any,
  isOpen:boolean,
  CloseBefore:boolean,
  CloseTime:boolean,
  Category:any,
  OpenTimerModal:boolean;
  orderTime:any;
  categoryId:any;
  productModal:boolean;
}

const isNorwayDayTime = () => {
  const now = new Date();
  const norwayTime = now.toLocaleString("en-US", { timeZone: "Europe/Oslo" });
  const hours = new Date(norwayTime).getHours();
  return hours >= 8 && hours < 11;
};

const initialState: SearchTerm = {
  width: 0,
  height: 0,
  isSearching: false,
  cartModal: false,
  cartQuantity: 0,
  SideBar: false,
  tableNumberCart: 0,
  timerOver: false,
  isTime: "",
  storeStart: "",
  storeClose: "",
  isOpen:false,
  CloseBefore:false,
  CloseTime:false,
  Category:"",
  OpenTimerModal:false,
  orderTime:"",
  categoryId:"",
  productModal:false
};

const SearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    searchState: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload;
    },
    widthState: (state, action: PayloadAction<number>) => {
      state.width = action.payload;
    },
    heightState: (state, action: PayloadAction<number>) => {
      state.height = action.payload;
    },
    cartOpenModal: (state, action: PayloadAction<boolean>) => {
      state.cartModal = action.payload;
    },
    isCartQuantity: (state, action: PayloadAction<number>) => {
      state.cartQuantity = action.payload;
    },
    isSideBar: (state, action: PayloadAction<boolean>) => {
      state.SideBar = action.payload;
    },
    setTableNumber: (state, action: PayloadAction<number>) => {
      state.tableNumberCart = action.payload;
    },
    setTimeOver: (state, action: PayloadAction<boolean>) => {
      state.timerOver = action.payload;
    },
    setTimeOrder: (state, action: PayloadAction<any>) => {
      state.isTime = action.payload;
    },
    setStoreStart: (state, action: PayloadAction<any>) => {
      state.storeStart = action.payload;
    },
    setStoreClose: (state, action: PayloadAction<any>) => {
      state.storeClose = action.payload;
    },
    setIsOpen:(state,action:PayloadAction<boolean>)=>{
      state.isOpen = action.payload
    },
    setCloseBefore:(state, action:PayloadAction<boolean>)=>{
      state.CloseBefore = Boolean(action.payload)
    },
    setCloseTime:(state, action: PayloadAction<boolean>) => {
      state.CloseTime = action.payload;
    },
    setCategory:(state, action:PayloadAction<any>)=>{
      state.Category = action.payload
    },
    setOpenTimerModal:(state,action)=>{
   state.OpenTimerModal = action.payload;
    },
    setOrderTime:(state,action)=>{
      state.orderTime = action.payload;
    },
    setCategoryId:(state,action)=>{
      state.categoryId = action.payload;
    },
    setProductModal:(state,action:PayloadAction<boolean>)=>{
      state.productModal = action.payload;
    }
  },
});

export const {
  searchState,
  widthState,
  heightState,
  cartOpenModal,
  isCartQuantity,
  isSideBar,
  setTableNumber,
  setTimeOver,
  setTimeOrder,
  setStoreStart,
  setStoreClose,
  setIsOpen,
  setCloseBefore,
  setCloseTime,
  setCategory,
  setOpenTimerModal,
  setOrderTime,
  setCategoryId,
  setProductModal,
} = SearchSlice.actions;
export default SearchSlice.reducer;
